import React from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  selectBudgetEntriesData,
  selectEditedEntry,
  selectEditedFoodItem,
  selectEditedTag,
  selectEditedTask,
  selectEditedWant,
  selectFoodItemsData,
  selectTagsData,
  selectTasksData,
  selectWantsData,
} from "../../store/general/general_slice_selectors";
import AddBudgetEntryForm from "../budget/AddBudgetEntryForm";
import {
  resetAddWantItemPostState,
  resetBudgetEntriesDeleteState,
  resetBudgetEntriesPatchState,
  resetBudgetEntriesPostState,
  resetFoodItemsDeleteState,
  resetFoodItemsPatchState,
  resetFoodItemsPostState,
  resetPlanPurchaseWantItemPostState,
  resetTagsDeleteState,
  resetTagsPatchState,
  resetTagsPostState,
  resetTasksDeleteState,
  resetTasksPatchState,
  resetTasksPostState,
  resetWantsDeleteState,
  resetWantsPatchState,
  resetWantsPostState,
  setEditedEntry,
  setEditedFoodItem,
  setEditedTag,
  setEditedTask,
  setEditedWant,
} from "../../store/general/general_slice";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import { useTranslation } from "react-i18next";
import TagForm from "../tags/TagForm";
import TaskForm from "../events/tasks/TaskForm";
import WantForm from "../pantry/wants/WantForm";
import FoodForm from "../pantry/foods/FoodForm";
import CustomSnackbar from "../misc/CustomSnackbar";

const FormDialogs: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const budgetEntries = useAppSelector((state) => selectBudgetEntriesData(state));
  const tagsData = useAppSelector((state) => selectTagsData(state));
  const tasksData = useAppSelector((state) => selectTasksData(state));
  const wantsData = useAppSelector((state) => selectWantsData(state));
  const foodData = useAppSelector((state) => selectFoodItemsData(state));
  const editedEntry = useAppSelector((state) => selectEditedEntry(state));
  const editedTag = useAppSelector((state) => selectEditedTag(state));
  const editedTask = useAppSelector((state) => selectEditedTask(state));
  const editedWant = useAppSelector((state) => selectEditedWant(state));
  const editedFoodItem = useAppSelector((state) => selectEditedFoodItem(state));

  const closeEditEntryHandler = () => {
    dispatch(setEditedEntry(null));
  };

  const closeSaveEntrySnackbarClickHandler = () => {
    dispatch(resetBudgetEntriesPatchState());
  };

  const closeDeleteEntrySnackbarClickHandler = () => {
    dispatch(resetBudgetEntriesDeleteState());
  };

  const closePostSnackbarClickHandler = () => {
    dispatch(resetBudgetEntriesPostState());
  };

  const closeSaveTagSnackbarClickHandler = () => {
    dispatch(resetTagsPatchState());
  };

  const closeCreateTagSnackbarClickHandler = () => {
    dispatch(resetTagsPostState());
  };

  const closeDeleteTagSnackbarClickHandler = () => {
    dispatch(resetTagsDeleteState());
  };

  const closeTagFormHandler = () => {
    dispatch(setEditedTag(null));
  };

  const closeTaskFormHandler = () => {
    dispatch(setEditedTask(null));
  };

  const closeWantFormHandler = () => {
    dispatch(setEditedWant({ data: null, tagGroups: [] }));
  };

  const closeEditedFoodFormHandler = () => {
    dispatch(setEditedFoodItem(null));
  };

  const closeSaveTaskSnackbarClickHandler = () => {
    dispatch(resetTasksPatchState());
  };

  const closeCreateTaskSnackbarClickHandler = () => {
    dispatch(resetTasksPostState());
  };

  const closeDeleteTaskSnackbarClickHandler = () => {
    dispatch(resetTasksDeleteState());
  };

  const closeSaveWantSnackbarClickHandler = () => {
    dispatch(resetWantsPatchState());
  };

  const closeCreateWantSnackbarClickHandler = () => {
    dispatch(resetWantsPostState());
  };

  const closeDeleteWantSnackbarClickHandler = () => {
    dispatch(resetWantsDeleteState());
  };

  const closeAddWantItemSnackbarClickHandler = () => {
    dispatch(resetAddWantItemPostState());
  };

  const closePlanPurchaseWantSnackbarClickHandler = () => {
    dispatch(resetPlanPurchaseWantItemPostState());
  };

  const closeSaveFoodItemSnackbarClickHandler = () => {
    dispatch(resetFoodItemsPatchState());
  };

  const closeCreateFoodItemSnackbarClickHandler = () => {
    dispatch(resetFoodItemsPostState());
  };

  const closeDeleteFoodItemSnackbarClickHandler = () => {
    dispatch(resetFoodItemsDeleteState());
  };

  return (
    <React.Fragment>
      {editedEntry != null && (
        <AddBudgetEntryForm isFormOpen={editedEntry != null} entry={editedEntry} onCloseEntry={closeEditEntryHandler} />
      )}
      {editedTag != null && <TagForm tag={editedTag} onClose={closeTagFormHandler} />}
      {editedTask != null && <TaskForm task={editedTask} onClose={closeTaskFormHandler} />}
      {editedWant.data != null && (
        <WantForm want={editedWant.data} tagGroups={editedWant.tagGroups} onClose={closeWantFormHandler} />
      )}
      {editedFoodItem != null && <FoodForm foodItem={editedFoodItem} onClose={closeEditedFoodFormHandler} />}

      <CustomSnackbar
        onClose={closeSaveEntrySnackbarClickHandler}
        open={budgetEntries.rest.patchStatus === HttpFetchStatus.Success}
        title={t("success-saving-budget-entry")}
      />

      <CustomSnackbar
        onClose={closeDeleteEntrySnackbarClickHandler}
        open={budgetEntries.rest.deleteStatus === HttpFetchStatus.Success}
        title={t("success-deleting-budget-entry")}
      />

      <CustomSnackbar
        onClose={closePostSnackbarClickHandler}
        open={budgetEntries.rest.postStatus === HttpFetchStatus.Success}
        title={t("success-creating-budget-entry")}
      />

      <CustomSnackbar
        title={t("success-saving-tag")}
        onClose={closeSaveTagSnackbarClickHandler}
        open={tagsData.rest.patchStatus === HttpFetchStatus.Success}
      />

      <CustomSnackbar
        onClose={closeCreateTagSnackbarClickHandler}
        open={tagsData.rest.postStatus === HttpFetchStatus.Success}
        title={t("success-creating-tag")}
      />

      <CustomSnackbar
        onClose={closeDeleteTagSnackbarClickHandler}
        open={tagsData.rest.deleteStatus === HttpFetchStatus.Success}
        title={t("success-deleting-tag")}
      />

      <CustomSnackbar
        onClose={closeSaveTaskSnackbarClickHandler}
        open={tasksData.rest.patchStatus === HttpFetchStatus.Success}
        title={t("success-saving-task")}
      />

      <CustomSnackbar
        onClose={closeCreateTaskSnackbarClickHandler}
        open={tasksData.rest.postStatus === HttpFetchStatus.Success}
        title={t("success-creating-task")}
      />

      <CustomSnackbar
        onClose={closeDeleteTaskSnackbarClickHandler}
        open={tasksData.rest.deleteStatus === HttpFetchStatus.Success}
        title={t("success-deleting-task")}
      />

      <CustomSnackbar
        onClose={closeSaveWantSnackbarClickHandler}
        open={wantsData.rest.patchStatus === HttpFetchStatus.Success}
        title={t("success-saving-want")}
      />

      <CustomSnackbar
        onClose={closeCreateWantSnackbarClickHandler}
        open={wantsData.rest.postStatus === HttpFetchStatus.Success}
        title={t("success-creating-want")}
      />

      <CustomSnackbar
        onClose={closeDeleteWantSnackbarClickHandler}
        open={wantsData.rest.deleteStatus === HttpFetchStatus.Success}
        title={t("success-deleting-want")}
      />

      <CustomSnackbar
        onClose={closeAddWantItemSnackbarClickHandler}
        open={wantsData.rest.addPostStatus === HttpFetchStatus.Success}
        title={t("success-adding-want")}
      />
      <CustomSnackbar
        onClose={closePlanPurchaseWantSnackbarClickHandler}
        open={wantsData.rest.planPurchasePostStatus === HttpFetchStatus.Success}
        title={t("success-adding-purchase-plan")}
      />

      <CustomSnackbar
        onClose={closeSaveFoodItemSnackbarClickHandler}
        open={foodData.rest.patchStatus === HttpFetchStatus.Success}
        title={t("success-saving-food-item")}
      />

      <CustomSnackbar
        onClose={closeCreateFoodItemSnackbarClickHandler}
        open={foodData.rest.postStatus === HttpFetchStatus.Success}
        title={t("success-creating-food-item")}
      />

      <CustomSnackbar
        onClose={closeDeleteFoodItemSnackbarClickHandler}
        open={foodData.rest.deleteStatus === HttpFetchStatus.Success}
        title={t("success-deleting-food-item")}
      />
    </React.Fragment>
  );
};

export default FormDialogs;
