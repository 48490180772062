import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectTagsData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { FilterIcon, TagsIcon } from "../../UI/icons";
import { getTags } from "../../../store/general/general_actions";
import Tag from "../../../models/general/tag";
import TagGroup from "../../../models/enums/entry/TagGroup";
import TagSelect from "../../misc/forms/TagSelect";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../UI/MainNavigationBar";
import { makeStyles } from "tss-react/mui";
import { TAGS_GROUPS_QUERY_SPLIT_STRING } from "../../tags/TagsPage";

const useStyles = makeStyles()((theme) => ({
  tagFilters: {
    maxWidth: "100%",
    overflow: "auto",
    marginBottom: theme.spacing(2),
    "&::-webkit-scrollbar": {
      height: theme.spacing(1.8),
    },
    "&::-webkit-scrollbar-track": {
      borderTop: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[100],
    },
    "&::-webkit-scrollbar-thumb": {
      borderTop: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const TagFilter: React.FC<{
  filteredTags: Tag[];
  tagGroups: TagGroup[];
  onFilteredTagsChange: (updatedTags: Tag[]) => void;
}> = ({ filteredTags, tagGroups, onFilteredTagsChange }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tagsData = useAppSelector((state) => selectTagsData(state));

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [dispatch, tagsData.rest.getStatus]);

  if (tagsData.data == null) {
    return null;
  }

  const tagChipClickHandler = (tag: Tag) => {
    if (filteredTags.some((t) => t.id === tag.id)) {
      onFilteredTagsChange(filteredTags.filter((t) => t.id !== tag.id));
    } else {
      onFilteredTagsChange([...filteredTags, tag]);
    }
  };

  const openFiltersDialogClickHandler = () => {
    setIsFilterDialogOpen(true);
  };

  const closeFiltersDialogClickHandler = () => {
    setIsFilterDialogOpen(false);
  };

  const filtersMultiselectChangeHandler = (event: SelectChangeEvent<string[]>) => {
    onFilteredTagsChange(tagsData.data!.filter((tag) => event.target.value.includes(tag.id)));
  };

  const tagsLinkClickHandler = () => {
    navigate({
      pathname: NavigationRoutes.Tags,
      search: createSearchParams({
        groups: tagGroups.join(TAGS_GROUPS_QUERY_SPLIT_STRING),
      }).toString(),
    });
  };

  return (
    <React.Fragment>
      <Box className={classes.tagFilters}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Tooltip title={t("tooltip-open-filters")}>
            <IconButton onClick={openFiltersDialogClickHandler}>
              <FilterIcon />
            </IconButton>
          </Tooltip>
          {tagsData.data
            .filter(
              (tag) => tagGroups.some((tg) => tag.tagGroups.includes(tg)) || filteredTags.some((ft) => ft.id === tag.id)
            )
            .map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                onClick={() => tagChipClickHandler(tag)}
                sx={{ backgroundColor: filteredTags.some((t) => t.id === tag.id) ? tag.color : undefined }}
              />
            ))}
        </Stack>
      </Box>
      <Dialog open={isFilterDialogOpen} onClose={closeFiltersDialogClickHandler} fullWidth maxWidth="sm">
        <DialogContent>
          <Stack spacing={2} alignItems="flex-start">
            <Button startIcon={<TagsIcon />} onClick={tagsLinkClickHandler}>
              {t("navigate-/tags")}
            </Button>
            <Box width="100%">
              <TagSelect
                tagIds={filteredTags.map((tag) => tag.id)}
                tagsValidationResult={{ error: null, isValid: true }}
                tagGroups={tagGroups}
                onTagsChange={filtersMultiselectChangeHandler}
              />
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default TagFilter;
