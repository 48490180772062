import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import BudgetingTopActionsBar from "./BudgetingTopActionsBar";
import BudgetingPieCharts from "./BudgetingPieCharts";
import BudgetingOverviewCard from "./BudgetingOverviewCard";
import { CashflowDirection } from "../../../models/enums/entry/CashflowDirection";
import BudgetingTransactions from "./BudgetingTransactions";

const BudgetOutlook: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1">{t("title-outlook")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <BudgetingTopActionsBar />
      </Grid>
      <BudgetingOverviewCard />
      <BudgetingPieCharts />
      <Grid item xs={12}>
        <BudgetingTransactions cashflowDirection={CashflowDirection.INCOME} />
      </Grid>
      <Grid item xs={12}>
        <BudgetingTransactions cashflowDirection={CashflowDirection.EXPENSE} />
      </Grid>
    </Grid>
  );
};

export default BudgetOutlook;
