import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationButton from "../misc/NavigationButton";
import { BudgetIcon, CalendarIcon, GoalsIcon, HomeIcon, PantryIcon, CloseIcon, OpenIcon, SettingsIcon } from "./icons";

const useStyles = makeStyles()((theme) => ({
  navContainer: {
    height: "100%",
    transition: "width 0.4s ease-in-out, padding-left 0.4s ease-in-out",
    backgroundColor: theme.palette.primary.main,
    overflowX: "hidden",
  },
  sideOpenBar: {
    height: "100%",
    width: theme.spacing(2),
    position: "relative",
    cursor: "pointer",
  },
  openIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
}));

export enum NavigationRoutes {
  Home = "/",
  Calendar = "/calendar",
  Budget = "/budget",
  Pantry = "/pantry",
  Goals = "/goals",
  Settings = "/settings",
  Transactions = "/budget/transactions",
  Tags = "/tags",
  Wishlist = "/wishlist",
}

const MainNavigationBar: React.FC<{}> = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const matchesMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [isNavOpen, setIsNavOpen] = useState(matchesMdUp);
  const [isOpenBarHovered, setIsOpenBarHovered] = useState(false);

  useEffect(() => {
    if (!matchesMdUp && isNavOpen) {
      setIsNavOpen(false);
    }
  }, [isNavOpen, matchesMdUp]);

  const openBarHoverHandler = () => {
    setIsOpenBarHovered(true);
  };

  const openBarAwayHandler = () => {
    setIsOpenBarHovered(false);
  };

  const toggleNavBarOpen = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  const mainNavButtons = (
    <Stack direction={matchesMdUp ? "column" : "row"} spacing={4} alignItems="start">
      <NavigationButton
        isNavOpen={isNavOpen}
        isOnBottom={!matchesMdUp}
        title={t(`navigate-${NavigationRoutes.Home}`)}
        Icon={HomeIcon}
        isCurrentPage={location.pathname === NavigationRoutes.Home}
        onClick={() => navigate(NavigationRoutes.Home)}
      />
      <NavigationButton
        isNavOpen={isNavOpen}
        isOnBottom={!matchesMdUp}
        title={t(`navigate-${NavigationRoutes.Calendar}`)}
        Icon={CalendarIcon}
        isCurrentPage={location.pathname === NavigationRoutes.Calendar}
        onClick={() => navigate(NavigationRoutes.Calendar)}
      />
      <NavigationButton
        isNavOpen={isNavOpen}
        isOnBottom={!matchesMdUp}
        title={t(`navigate-${NavigationRoutes.Budget}`)}
        Icon={BudgetIcon}
        isCurrentPage={location.pathname === NavigationRoutes.Budget}
        onClick={() => navigate(NavigationRoutes.Budget)}
      />
      <NavigationButton
        isNavOpen={isNavOpen}
        isOnBottom={!matchesMdUp}
        title={t(`navigate-${NavigationRoutes.Pantry}`)}
        Icon={PantryIcon}
        isCurrentPage={location.pathname === NavigationRoutes.Pantry}
        onClick={() => navigate(NavigationRoutes.Pantry)}
      />
      <NavigationButton
        isNavOpen={isNavOpen}
        isOnBottom={!matchesMdUp}
        title={t(`navigate-${NavigationRoutes.Goals}`)}
        Icon={GoalsIcon}
        isCurrentPage={location.pathname === NavigationRoutes.Goals}
        onClick={() => navigate(NavigationRoutes.Goals)}
      />
    </Stack>
  );

  return (
    <Stack direction="row">
      <Stack
        direction="column"
        justifyContent="space-between"
        className={classes.navContainer}
        sx={(theme) => ({
          width: !matchesMdUp ? "100%" : isNavOpen ? "250px" : "72px",
          paddingLeft: !matchesMdUp ? 0 : isNavOpen ? "16px" : "8px",
          paddingTop: theme.spacing(matchesMdUp ? 4 : 1.5),
          paddingBottom: theme.spacing(matchesMdUp ? 4 : 1.5),
        })}
      >
        {mainNavButtons}
        {matchesMdUp && (
          <NavigationButton
            isNavOpen={isNavOpen}
            isOnBottom={!matchesMdUp}
            title={t(`navigate-${NavigationRoutes.Settings}`)}
            Icon={SettingsIcon}
            isCurrentPage={location.pathname === NavigationRoutes.Settings}
            onClick={() => navigate(NavigationRoutes.Settings)}
          />
        )}
      </Stack>
      {matchesMdUp && (
        <div
          className={classes.sideOpenBar}
          onMouseOver={openBarHoverHandler}
          onMouseLeave={openBarAwayHandler}
          onTouchStart={openBarHoverHandler}
          onTouchEnd={openBarAwayHandler}
          onClick={toggleNavBarOpen}
        >
          {isOpenBarHovered && (
            <IconButton className={classes.openIcon} style={{ backgroundColor: "transparent" }}>
              {isNavOpen ? <CloseIcon /> : <OpenIcon />}
            </IconButton>
          )}
        </div>
      )}
    </Stack>
  );
};

export default MainNavigationBar;
