import { useEffect, useRef, useState } from "react";

import ValidationResult from "../services/validation/common/validation-result";

const useValidatedInputEffect = <T>(
  defaultValue: T,
  validateValue: (valueToValidate: T) => ValidationResult,
  valueChangeEffectFunction: (changedValue: T) => void,
  serverValidationResult: ValidationResult | null,
  resetServerValidation?: (inputKey: string) => void
) => {
  const [effectHandledValue, setEffectHandledValue] = useState<T>(defaultValue);
  const [value, setValue] = useState<T>(defaultValue);

  let validationResult: ValidationResult = validateValue(value);

  if (serverValidationResult !== null && !serverValidationResult.isValid && serverValidationResult.error !== null) {
    validationResult.error = serverValidationResult.error;
    validationResult.isValid = false;
  }

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (validationResult.isValid && value !== effectHandledValue) {
        valueChangeEffectFunction(value);
        setEffectHandledValue(value);
      }
    } else {
      didMount.current = true;
    }
  }, [validationResult, effectHandledValue, value, valueChangeEffectFunction]);

  const valueChangeHandler = (event: React.ChangeEvent<HTMLInputElement> | T) => {
    if (event != null && typeof event === "object" && "target" in event) {
      if (typeof defaultValue === "number") {
        setValue(parseFloat(event.target.value) as unknown as T);
      } else if (typeof defaultValue === "string") {
        setValue(event.target.value as unknown as T);
      }
    } else {
      setValue(event);
    }

    if (resetServerValidation !== undefined && serverValidationResult?.inputKey !== undefined) {
      resetServerValidation(serverValidationResult.inputKey);
    }
  };

  return { value, validationResult, valueChangeHandler, setValue };
};

export default useValidatedInputEffect;
