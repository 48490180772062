import { DateTime } from "luxon";
import i18n from "../../../i18n";
import ValidationResult from "../common/validation-result";

export const validateWantTitle = (title: string): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-want-title"),
  };
};

export const validateWantDescription = (description: string): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-want-description"),
  };
};

export const validateWantTags = (tags: string[]): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-want-tags"),
  };
};

export const validateWantCost = (cost: number): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-want-cost"),
  };
};

export const validateWantQuantity = (quantity: number): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-want-quantity"),
  };
};

export const validatePlanPurchaseDescription = (description: string): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-plan-purchase-description"),
  };
};

export const validatePlanPurchaseAmountDue = (amountDue: number): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-plan-purchase-amount-due"),
  };
};

export const validatePlanPurchaseDateDue = (dateDue: DateTime): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-plan-purchase-date-due"),
  };
};
