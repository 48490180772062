import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { AddIcon } from "../../UI/icons";
import { setEditedFoodItem } from "../../../store/general/general_slice";
import TagFilter from "../../events/tasks/TagFilter";
import Tag from "../../../models/general/tag";
import { selectFoodItemsData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { getFoodItems } from "../../../store/general/general_actions";
import TagGroup from "../../../models/enums/entry/TagGroup";
import CustomSkeleton from "../../misc/CustomSkeleton";
import FoodCard from "./FoodCard";

const Foods: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const foodData = useAppSelector((state) => selectFoodItemsData(state));

  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (foodData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getFoodItems());
    }
  }, [dispatch, foodData.rest.getStatus]);

  const addFoodItemClickHandler = () => {
    dispatch(
      setEditedFoodItem({
        tags: filteredTags,
      })
    );
  };

  const tagFilterChangeHandler = (tags: Tag[]) => {
    setFilteredTags(tags);
  };

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h1">{t("title-pantry")}</Typography>
        <Stack direction="row" spacing={2}>
          <Button startIcon={<AddIcon />} onClick={addFoodItemClickHandler}>
            {t("button-add-new")}
          </Button>
        </Stack>
      </Stack>

      <TagFilter
        filteredTags={filteredTags}
        tagGroups={[TagGroup.Pantry]}
        onFilteredTagsChange={tagFilterChangeHandler}
      />

      {foodData.rest.getStatus === HttpFetchStatus.Idle || foodData.rest.getStatus === HttpFetchStatus.Loading ? (
        <Stack spacing={2}>
          {Array(10)
            .fill(0)
            .map((_, index) => (
              <Card key={index}>
                <CardContent>
                  <Stack spacing={1}>
                    <CustomSkeleton gridSize={{ xs: 12 }} height={3} width="40%" />
                    <CustomSkeleton gridSize={{ xs: 12 }} height={2} />
                  </Stack>
                </CardContent>
              </Card>
            ))}
        </Stack>
      ) : foodData.rest.getStatus === HttpFetchStatus.Failed || foodData.data == null ? (
        <Alert severity="error">{t("error-failed-to-load-pantry")}</Alert>
      ) : foodData.data.length === 0 ? (
        <Alert sx={{ width: "100%" }} severity="info">
          {t("info-no-pantry-items")}
        </Alert>
      ) : (
        <Grid container spacing={2}>
          {[...foodData.data]
            .filter((item) => item.quantity > 0)
            .sort((a, b) => (a.item > b.item ? 1 : -1))
            .map((item) => (
              <FoodCard key={item.id} item={item} />
            ))}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Foods;
