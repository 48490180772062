import i18n from "../../../i18n";
import ValidationResult from "../common/validation-result";

export const validateTagName = (tagName: string): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-tagName-required"),
  };
};

export const validateTagDescription = (description: string): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-tagDescription-required"),
  };
};

export const validateTagTagGroups = (groups: string[]): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-tagGroup"),
  };
};
