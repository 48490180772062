import React from "react";
import FoodItem from "../../../models/pantry/food-item";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { dateToStringFormat } from "../../../utils/date";
import { AddIcon, MinusIcon } from "../../UI/icons";

const FoodHistory: React.FC<{ foodItem: FoodItem; history: FoodItem[] }> = ({ foodItem, history }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography variant="caption" sx={(theme) => ({ minHeight: theme.spacing(1.75), display: "block" })}>
        {t("input-label-food-item-history")}
      </Typography>
      <List>
        {[...history]
          .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
          .map((item) => (
            <ListItem key={item.id} disableGutters>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ width: "100%" }}>
                {item.quantity > 0 ? <AddIcon color="success" /> : <MinusIcon color="error" />}
                <Stack direction="row" justifyContent="space-between" width="100%">
                  <Typography variant="body2">
                    {item.quantity.toString().replace("-", "")}
                    {t("food-unit-" + item.unit)}
                  </Typography>
                  <Typography variant="body2">
                    {item.dateCreated != null && dateToStringFormat(item.dateCreated)}
                  </Typography>
                </Stack>
              </Stack>
            </ListItem>
          ))}
      </List>
    </Stack>
  );
};

export default FoodHistory;
