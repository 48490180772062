import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PieChart, PieSeriesType, PieValueType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  scrollbar: {
    overflow: "auto",
    alignItems: "center",
    "&::-webkit-scrollbar": {
      width: theme.spacing(1.8),
    },
    "&::-webkit-scrollbar-track": {
      borderLeft: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[100],
    },
    "&::-webkit-scrollbar-thumb": {
      borderLeft: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const CustomPieChart: React.FC<{
  label: string;
  miniLabel: string;
  data: { value: number; tag: { name: string; color: string } | null }[];
  noneLabel: string;
}> = ({ label, data, miniLabel, noneLabel }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const totalAmount = data.reduce((acc, item) => acc + item.value, 0);

  const tagSums = data.reduce((acc, item) => {
    if (item.tag != null) {
      const { name, color } = item.tag;
      if (!acc[name]) {
        acc[name] = { sum: 0, color: color };
      }
      acc[name].sum += item.value;
    } else {
      if (!acc[t("unsorted-entry-tag")]) {
        acc[t("unsorted-entry-tag")] = { sum: 0, color: "grey" };
      }
      acc[t("unsorted-entry-tag")].sum += item.value;
    }
    return acc;
  }, {} as Record<string, { sum: number; color: string }>);

  const chartData: MakeOptional<PieSeriesType<MakeOptional<PieValueType, "id">>, "type">[] = [
    {
      data: Object.entries(tagSums).map(([name, { color, sum }], index) => ({
        id: index,
        color,
        value: sum,
        label: name,
      })),
      innerRadius: 64,
      outerRadius: 95,
      paddingAngle: 0,
      cornerRadius: 4,
      startAngle: 0,
      endAngle: 360,
      cx: 100,
      cy: 100,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">{label}</Typography>
        {data.length === 0 ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            {noneLabel}
          </Alert>
        ) : (
          <Stack direction="row" maxHeight={"200px"}>
            <Box sx={{ position: "relative" }}>
              <PieChart series={chartData} height={200} width={200} slotProps={{ legend: { hidden: true } }} />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  textAlign: "center",
                  transform: "translateX(-45%) translateY(-30%)",
                }}
              >
                <Typography variant="h1">{totalAmount.toFixed(2)}</Typography>
                <Typography variant="caption">{miniLabel}</Typography>
              </Box>
            </Box>
            <List className={classes.scrollbar}>
              {Object.entries(tagSums).map(([name, { color, sum }]) => (
                <ListItem key={name}>
                  <span
                    style={{
                      width: 14,
                      height: 14,
                      borderRadius: "50%",
                      backgroundColor: color,
                      marginRight: theme.spacing(1),
                    }}
                  ></span>
                  <Typography variant="body2">{`${name} - ${sum.toFixed(2)}`}</Typography>
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default CustomPieChart;
