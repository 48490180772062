import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Want from "../../../models/common/wants/want";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../UI/MainNavigationBar";
import { useAppDispatch } from "../../../store";
import { setEditedWant } from "../../../store/general/general_slice";
import TagGroup from "../../../models/enums/entry/TagGroup";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CompleteTaskIcon } from "../../UI/icons";
import { addWantItemById } from "../../../store/general/general_actions";
import { quantityAndUnitToString } from "../../../models/enums/pantry/foodUnit";
import { amountAndCurrencyToString } from "../../../models/enums/entry/CurrencyChoice";

const WantCard: React.FC<{ want: Want; tagGroups: TagGroup[]; addInProgress: boolean }> = ({
  want,
  tagGroups,
  addInProgress,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const navigateToWantClickHandler = () => {
    dispatch(setEditedWant({ data: null, tagGroups }));
    navigate(`${NavigationRoutes.Wishlist}/${want.id}`);
  };

  const addWantCompleteClickHandler = () => {
    dispatch(addWantItemById(want.id));
  };

  return (
    <Grid item xs={12}>
      <Card sx={{ position: "relative" }}>
        <CardActionArea onClick={navigateToWantClickHandler}>
          <CardContent>
            <Stack spacing={1}>
              <Stack direction="row">
                <Stack
                  spacing={1}
                  sx={(theme) => ({
                    overflow: "hidden",
                    maxWidth: `calc(100% - ${!want.closed ? theme.spacing(5) : "0px"})`,
                    flexGrow: 1,
                  })}
                >
                  <Typography variant="h2">{want.title}</Typography>
                  {want.description && (
                    <Typography
                      variant="body2"
                      width="100%"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                    >
                      {want.description}
                    </Typography>
                  )}
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body2">{quantityAndUnitToString(want.quantity, want.unit)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{amountAndCurrencyToString(want.cost, want.currency)}</Typography>
                    </Grid>
                  </Grid>
                </Stack>
                {!want.closed && (
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexGrow: 0,
                      width: theme.spacing(5),
                      height: theme.spacing(5),
                    })}
                  />
                )}
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
        {!want.closed && (
          <React.Fragment>
            {addInProgress && (
              <CircularProgress
                size={40}
                color="success"
                sx={(theme) => ({
                  position: "absolute",
                  right: theme.spacing(2),
                  top: theme.spacing(2),
                })}
              />
            )}
            <Tooltip title={t("button-complete-purchase")}>
              <Box sx={(theme) => ({ position: "absolute", right: theme.spacing(2), top: theme.spacing(2) })}>
                <IconButton color="success" onClick={addWantCompleteClickHandler} disabled={addInProgress}>
                  <CompleteTaskIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </React.Fragment>
        )}
      </Card>
    </Grid>
  );
};

export default WantCard;
