import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { AddIcon, CompletedTasksIcon, OpenTasksIcon } from "../../UI/icons";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectTasksData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { getTasks } from "../../../store/general/general_actions";
import { setEditedTask } from "../../../store/general/general_slice";
import TaskCard from "./TaskCard";
import CustomSkeleton from "../../misc/CustomSkeleton";
import TagFilter from "./TagFilter";
import Tag from "../../../models/general/tag";
import TagGroup from "../../../models/enums/entry/TagGroup";

const Tasks: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const tasksData = useAppSelector((state) => selectTasksData(state));

  const [isShowingCompletedTasks, setIsShowingCompletedTasks] = useState(false);
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (tasksData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTasks());
    }
  }, [dispatch, tasksData.rest.getStatus]);

  const addTaskClickHandler = () => {
    dispatch(
      setEditedTask({
        tags: filteredTags,
      })
    );
  };

  const toggleCompletedTasksClickHandler = () => {
    setIsShowingCompletedTasks((prevState) => !prevState);
  };

  const tagFilterChangeHandler = (updatedTags: Tag[]) => {
    setFilteredTags(updatedTags);
  };

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h1">{isShowingCompletedTasks ? t("title-completed-tasks") : t("title-tasks")}</Typography>
        <Stack direction="row" spacing={2}>
          <Tooltip title={isShowingCompletedTasks ? t("tooltip-show-open-tasks") : t("tooltip-show-completed-tasks")}>
            <IconButton onClick={toggleCompletedTasksClickHandler}>
              {isShowingCompletedTasks ? <OpenTasksIcon /> : <CompletedTasksIcon />}
            </IconButton>
          </Tooltip>
          <Button startIcon={<AddIcon />} onClick={addTaskClickHandler}>
            {t("button-add-new")}
          </Button>
        </Stack>
      </Stack>

      <TagFilter
        filteredTags={filteredTags}
        tagGroups={[TagGroup.Tasks]}
        onFilteredTagsChange={tagFilterChangeHandler}
      />

      {tasksData.rest.getStatus === HttpFetchStatus.Idle || tasksData.rest.getStatus === HttpFetchStatus.Loading ? (
        <Stack spacing={2}>
          {Array(10)
            .fill(0)
            .map((_, index) => (
              <Card key={index}>
                <CardContent>
                  <Stack spacing={1}>
                    <CustomSkeleton gridSize={{ xs: 12 }} height={3} width="40%" />
                    <CustomSkeleton gridSize={{ xs: 12 }} height={2} />
                  </Stack>
                </CardContent>
              </Card>
            ))}
        </Stack>
      ) : tasksData.rest.getStatus === HttpFetchStatus.Failed || tasksData.data == null ? (
        <Alert severity="error">{t("error-failed-to-load-tasks")}</Alert>
      ) : tasksData.data.filter(
          (task) =>
            (task.dateCompleted != null && isShowingCompletedTasks) ||
            (task.dateCompleted == null && !isShowingCompletedTasks)
        ).length === 0 ? (
        <Alert sx={{ width: "100%" }} severity="info">
          {isShowingCompletedTasks ? t("info-no-completed-tasks") : t("info-no-open-tasks")}
        </Alert>
      ) : (
        <Grid container spacing={2}>
          {tasksData.data
            .filter(
              (task) =>
                (filteredTags.length === 0 || task.tags.some((tag) => filteredTags.some((ft) => ft.id === tag.id))) &&
                ((task.dateCompleted != null && isShowingCompletedTasks) ||
                  (task.dateCompleted == null && !isShowingCompletedTasks))
            )
            .map((task) => (
              <TaskCard
                key={task.id}
                task={task}
                patchInProgress={
                  tasksData.rest.patchStatus === HttpFetchStatus.Loading && tasksData.rest.patchId === task.id
                }
              />
            ))}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Tasks;
