import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpError from "../../models/common/api/http-error";
import User, { CreateAccountDetails, LoginDetails } from "../../models/general/user";
import {
  returnAxiosOrGeneralFetchError,
  returnAxiosValidationOrGeneralFetchError,
} from "../../services/data/axios-base";
import generalDataService from "../../services/data/general-data-service";
import ValidationResult from "../../services/validation/common/validation-result";
import BudgetEntry, { convertBudgetEntryApiResponse } from "../../models/budget/budget-entry";
import { RootState } from "..";
import Tag from "../../models/general/tag";
import Task from "../../models/calendar/task";
import Want from "../../models/common/wants/want";
import FoodItem from "../../models/pantry/food-item";

export const login = createAsyncThunk<
  { token: string; user: User },
  LoginDetails,
  { rejectValue: HttpError | ValidationResult[] }
>("login", async (loginDetails: LoginDetails, { rejectWithValue }) => {
  try {
    return await generalDataService.login(loginDetails);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const createAccount = createAsyncThunk<
  { token: string; user: User },
  CreateAccountDetails,
  { rejectValue: HttpError | ValidationResult[] }
>("createAccount", async (createAccountDetails: CreateAccountDetails, { rejectWithValue }) => {
  try {
    return await generalDataService.createAccount(createAccountDetails);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const getUser = createAsyncThunk<{ user: User }, string, { rejectValue: HttpError }>(
  "getUser",
  async (token: string, { rejectWithValue }) => {
    try {
      return await generalDataService.getUser(token);
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const getBudgetEntries = createAsyncThunk<BudgetEntry[], string | undefined, { rejectValue: HttpError }>(
  "budget/entries",
  async (filters: string | undefined, { rejectWithValue }) => {
    try {
      const budgetEntries = await generalDataService.getBudgetEntries(filters ?? "");
      return convertBudgetEntryApiResponse(budgetEntries);
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const createBudgetEntry = createAsyncThunk<
  BudgetEntry,
  Partial<BudgetEntry>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("budget/createEntry", async (entry: Partial<BudgetEntry>, { getState, rejectWithValue }) => {
  try {
    const budgetEntry = await generalDataService.createBudgetEntry(entry);
    return convertBudgetEntryApiResponse([budgetEntry])[0];
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const updateBudgetEntryById = createAsyncThunk<
  BudgetEntry,
  Partial<BudgetEntry>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("budget/updateEntry", async (entry: Partial<BudgetEntry>, { getState, rejectWithValue }) => {
  try {
    const budgetEntry = await generalDataService.updateBudgetEntryById(entry);
    return convertBudgetEntryApiResponse([budgetEntry])[0];
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const deleteEntryById = createAsyncThunk<{ id: string }, string, { rejectValue: HttpError }>(
  "budget/deleteEntry",
  async (entryId: string, { rejectWithValue }) => {
    try {
      return await generalDataService.deleteBudgetEntryById(entryId);
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const getTags = createAsyncThunk<Tag[], string | undefined, { rejectValue: HttpError }>(
  "tags",
  async (filters: string | undefined, { rejectWithValue }) => {
    try {
      return await generalDataService.getTags(filters ?? "");
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const createTag = createAsyncThunk<
  Tag,
  Partial<Tag>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("tags/createTag", async (tag: Partial<Tag>, { getState, rejectWithValue }) => {
  try {
    return await generalDataService.createTag(tag);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const updateTagById = createAsyncThunk<
  Tag,
  Partial<Tag>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("tags/updateTag", async (entry: Partial<Tag>, { getState, rejectWithValue }) => {
  try {
    return await generalDataService.updateTagById(entry);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const deleteTagById = createAsyncThunk<{ id: string }, string, { rejectValue: HttpError }>(
  "tags/deleteTag",
  async (tagId: string, { rejectWithValue }) => {
    try {
      return await generalDataService.deleteTagById(tagId);
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const getTasks = createAsyncThunk<Task[], string | undefined, { rejectValue: HttpError }>(
  "tasks",
  async (filters: string | undefined, { rejectWithValue }) => {
    try {
      return await generalDataService.getTasks(filters ?? "");
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const createTask = createAsyncThunk<
  Task,
  Partial<Task>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("tasks/createTask", async (task: Partial<Task>, { rejectWithValue }) => {
  try {
    return await generalDataService.createTask(task);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const updateTaskById = createAsyncThunk<
  Task,
  { task: Partial<Task>; reopening?: boolean },
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("tasks/updateTask", async (data: { task: Partial<Task>; reopening?: boolean }, { rejectWithValue }) => {
  try {
    return await generalDataService.updateTaskById(data.task);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const updateSubTaskByTaskId = createAsyncThunk<
  Task,
  { taskId: string; subTask: Partial<Task> },
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("tasks/updateSubTask", async (data: { subTask: Partial<Task>; taskId: string }, { rejectWithValue }) => {
  try {
    return await generalDataService.updateSubTaskByTaskId(data.taskId, data.subTask);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const deleteTaskById = createAsyncThunk<
  { id: string },
  { deletedTaskId: string; parentTaskId?: string },
  { rejectValue: HttpError }
>("tasks/deleteTask", async (data: { deletedTaskId: string; parentTaskId?: string }, { rejectWithValue }) => {
  try {
    return await generalDataService.deleteTaskById(data.deletedTaskId);
  } catch (error) {
    return rejectWithValue(returnAxiosOrGeneralFetchError(error));
  }
});

export const getWants = createAsyncThunk<Want[], string | undefined, { rejectValue: HttpError }>(
  "wants",
  async (filters: string | undefined, { rejectWithValue }) => {
    try {
      return await generalDataService.getWants(filters ?? "");
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const createWant = createAsyncThunk<
  Want,
  Partial<Want>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("wants/createWant", async (want: Partial<Want>, { rejectWithValue }) => {
  try {
    return await generalDataService.createWant(want);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const updateWantById = createAsyncThunk<
  Want,
  Partial<Want>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("wants/updateWant", async (want: Partial<Want>, { rejectWithValue }) => {
  try {
    return await generalDataService.updateWantById(want);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const deleteWantById = createAsyncThunk<{ id: string }, string, { rejectValue: HttpError }>(
  "wants/deleteWant",
  async (wantId: string, { rejectWithValue }) => {
    try {
      return await generalDataService.deleteWantById(wantId);
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const addWantItemById = createAsyncThunk<Want, string, { rejectValue: HttpError }>(
  "wants/addWantItem",
  async (wantId: string, { rejectWithValue }) => {
    try {
      return await generalDataService.addWantItemById(wantId);
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const planPurchaseWantItemById = createAsyncThunk<
  Want,
  { id: string; entry: Partial<BudgetEntry> },
  { rejectValue: HttpError }
>("wants/planPurchaseWantItem", async (data: { id: string; entry: Partial<BudgetEntry> }, { rejectWithValue }) => {
  try {
    return await generalDataService.planPurchaseWantItemById(data.id, data.entry);
  } catch (error) {
    return rejectWithValue(returnAxiosOrGeneralFetchError(error));
  }
});

export const getFoodItems = createAsyncThunk<FoodItem[], string | undefined, { rejectValue: HttpError }>(
  "foodItems",
  async (filters: string | undefined, { rejectWithValue }) => {
    try {
      return await generalDataService.getFoodItems(filters ?? "");
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const createFoodItem = createAsyncThunk<
  FoodItem,
  Partial<FoodItem>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("foodItems/createFoodItem", async (foodItem: Partial<FoodItem>, { rejectWithValue }) => {
  try {
    return await generalDataService.createFoodItem(foodItem);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const updateFoodItemById = createAsyncThunk<
  FoodItem,
  Partial<FoodItem>,
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("foodItems/updateFoodItem", async (foodItem: Partial<FoodItem>, { rejectWithValue }) => {
  try {
    return await generalDataService.updateFoodItemById(foodItem);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});

export const deleteFoodItemById = createAsyncThunk<{ id: string }, string, { rejectValue: HttpError }>(
  "foodItems/deleteFoodItem",
  async (foodItemId: string, { rejectWithValue }) => {
    try {
      return await generalDataService.deleteFoodItemById(foodItemId);
    } catch (error) {
      return rejectWithValue(returnAxiosOrGeneralFetchError(error));
    }
  }
);

export const updateFoodHistoryById = createAsyncThunk<
  FoodItem,
  { id: string; foodItem: Partial<FoodItem> },
  { state: RootState; rejectValue: HttpError | ValidationResult[] }
>("foodItems/updateFoodHistory", async (data: { id: string; foodItem: Partial<FoodItem> }, { rejectWithValue }) => {
  try {
    return await generalDataService.updateFoodHistoryById(data.id, data.foodItem);
  } catch (error) {
    return rejectWithValue(returnAxiosValidationOrGeneralFetchError(error));
  }
});