import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FoodItem from "../../../models/pantry/food-item";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../UI/MainNavigationBar";

const FoodCard: React.FC<{ item: FoodItem }> = ({ item }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const editWantClickHandler = () => {
    navigate(`${NavigationRoutes.Pantry}/${item.id}`);
  };

  return (
    <Grid item xs={12} key={item.id}>
      <Card>
        <CardActionArea onClick={editWantClickHandler}>
          <CardContent>
            <Stack direction="row">
              <Stack spacing={1} sx={{ overflow: "hidden", maxWidth: "100%" }}>
                <Typography variant="h2">{item.item}</Typography>
                <Typography variant="body2" width="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                  {item.quantity}
                  {t("food-unit-" + item.unit)}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default FoodCard;
