import { DateTime } from "luxon";

export const isDateTimeInRange = (start: DateTime, check: DateTime, end: DateTime) => {
  const startDate = DateTime.fromISO(start.toString());
  const checkDate = DateTime.fromISO(check.toString());
  const endDate = DateTime.fromISO(end.toString());

  return startDate <= checkDate && checkDate <= endDate;
};

export const dateToStringFormat = (date: DateTime | Date | string, shortFormat?: boolean) => {
  const unformattedDate = DateTime.fromISO(date.toString());
  if (shortFormat) {
    return unformattedDate.toFormat("dd/MM/yy");
  }
  return unformattedDate.toFormat("dd LLL yyyy - HH:mm");
};

export const sortByDate = (dateA: DateTime | Date | string, dateB: DateTime | Date | string) => {
  const parsedDateA = DateTime.fromISO(dateA.toString());
  const parsedDateB = DateTime.fromISO(dateB.toString());

  return parsedDateB.toMillis() - parsedDateA.toMillis();
};

export const isValidDate = (d: any) => {
  return !isNaN(d);
};