import React from "react";

const TabPanel: React.FC<{
  index: any;
  value: any;
  children: React.ReactNode;
}> = ({ index, value, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
};

const a11yProps = (index: any) => {
  return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}` };
};

export { a11yProps };
export default TabPanel;
