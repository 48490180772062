import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Task from "../../../models/calendar/task";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store";
import { setEditedTask } from "../../../store/general/general_slice";
import { CompleteTaskIcon } from "../../UI/icons";
import { updateTaskById } from "../../../store/general/general_actions";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import TextWithLabel from "../../misc/TextWithLabel";
import { dateToStringFormat } from "../../../utils/date";

const TaskCard: React.FC<{ task: Task; patchInProgress: boolean }> = ({ task, patchInProgress }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const markCompleteClickHandler = () => {
    dispatch(updateTaskById({ task: { ...task, dateCompleted: new Date() } }));
  };

  const editTaskClickHandler = () => {
    dispatch(setEditedTask(task));
  };

  return (
    <Grid item xs={12}>
      <Card sx={{ position: "relative" }}>
        <CardActionArea onClick={editTaskClickHandler}>
          <CardContent>
            <Stack spacing={1}>
              <Stack direction="row">
                <Stack
                  spacing={1}
                  sx={(theme) => ({
                    overflow: "hidden",
                    maxWidth: `calc(100% - ${task.dateCompleted == null ? theme.spacing(5) : "0px"})`,
                  })}
                >
                  <Typography variant="h2">{task.title}</Typography>
                  <Typography
                    variant="body2"
                    width="100%"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    pl={2}
                  >
                    {task.description}
                  </Typography>
                </Stack>
                {task.dateCompleted == null && (
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexGrow: 0,
                      width: theme.spacing(5),
                      height: theme.spacing(5),
                    })}
                  />
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                {task.deadline != null && (
                  <TextWithLabel
                    textVariant="body2"
                    textColor={
                      new Date(task.deadline).getTime() < new Date().getTime() && task.dateCompleted == null
                        ? "error"
                        : undefined
                    }
                    text={dateToStringFormat(task.deadline)}
                    label={t("input-label-task-deadline")}
                  />
                )}
                {task.subTasks.length > 0 && (
                  <Tooltip
                    title={`${task.subTasks.filter((subTask) => subTask.dateCompleted != null).length} / ${
                      task.subTasks.length
                    }`}
                  >
                    <Box sx={(theme) => ({ width: theme.spacing(16) })}>
                      <LinearProgress
                        color="success"
                        sx={(theme) => ({ height: theme.spacing(3), borderRadius: theme.spacing(1) })}
                        variant="determinate"
                        value={
                          (task.subTasks.filter((subTask) => subTask.dateCompleted != null).length /
                            task.subTasks.length) *
                          100
                        }
                      />
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
        {task.dateCompleted == null && (
          <React.Fragment>
            {patchInProgress && (
              <CircularProgress
                size={40}
                color="success"
                sx={(theme) => ({
                  position: "absolute",
                  right: theme.spacing(2),
                  top: theme.spacing(2),
                })}
              />
            )}
            <Tooltip title={t("tooltip-mark-task-complete")}>
              <Box sx={(theme) => ({ position: "absolute", right: theme.spacing(2), top: theme.spacing(2) })}>
                <IconButton color="success" onClick={markCompleteClickHandler} disabled={patchInProgress}>
                  <CompleteTaskIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </React.Fragment>
        )}
      </Card>
    </Grid>
  );
};

export default TaskCard;
