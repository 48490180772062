import i18n from "../../../i18n";

enum CurrencyChoice {
  CHF = "CHF",
  NZD = "NZD",
  EUR = "EUR",
}

export default CurrencyChoice;

export const amountAndCurrencyToString = (amount: number | null, currency: CurrencyChoice) => {
  if (amount == null) return i18n.t("info-no-amount-set");
  return `${i18n.t("currency-" + currency)} ${amount}`;
};
