import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectFoodItemsData } from "../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";
import { setEditedFoodItem, setEditedWant } from "../../store/general/general_slice";
import { AddToCardIcon, CloseIcon, EditIcon } from "../UI/icons";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import { getFoodItems } from "../../store/general/general_actions";
import TextWithLabel from "../misc/TextWithLabel";
import { dateToStringFormat } from "../../utils/date";
import FoodHistory from "../pantry/foods/FoodHistory";
import { quantityAndUnitToString } from "../../models/enums/pantry/foodUnit";
import FoodHistoryForm from "../pantry/foods/FoodHistoryForm";
import TagGroup from "../../models/enums/entry/TagGroup";

const FoodItemPage: React.FC = () => {
  const { t } = useTranslation();

  const { itemId } = useParams<{ itemId: string }>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const foodData = useAppSelector((state) => selectFoodItemsData(state));

  const [isUpdateHistoryFormOpen, setIsUpdateHistoryFormOpen] = useState(false);

  useEffect(() => {
    if (foodData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getFoodItems());
    }
  }, [dispatch, foodData.rest.getStatus]);

  useEffect(() => {
    if (foodData.rest.deleteStatus === HttpFetchStatus.Success) {
      navigate(-1);
    }
  }, [foodData.rest.deleteStatus, navigate]);

  useEffect(() => {
    if (foodData.rest.updateHistoryPostStatus === HttpFetchStatus.Success) {
      setIsUpdateHistoryFormOpen(false);
    }
  }, [foodData.rest.updateHistoryPostStatus]);

  const backClickHandler = () => {
    navigate(-1);
  };

  const foodItem = foodData.data?.find((item) => item.id === itemId && itemId != null);

  if (foodItem == null) {
    return null;
  }

  const editFoodItemClickHandler = () => {
    dispatch(setEditedFoodItem(foodItem));
  };

  const openUpdateHistoryClickHandler = () => {
    setIsUpdateHistoryFormOpen(true);
  };

  const closeUpdateHistoryFormHandler = () => {
    setIsUpdateHistoryFormOpen(false);
  };

  const addToCartClickHandler = () => {
    dispatch(
      setEditedWant({
        data: {
          pantryItem: true,
          title: foodItem.item,
          tags: foodItem.tags,
          unit: foodItem.unit,
        },
        tagGroups: [TagGroup.Pantry],
      })
    );
  };

  return (
    <React.Fragment>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={backClickHandler} size="medium">
              <CloseIcon />
            </IconButton>
            <Typography variant="h1">{foodItem.item}</Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={openUpdateHistoryClickHandler}>
              {t("button-update-food-quantity")}
            </Button>
            <Button startIcon={<EditIcon />} onClick={editFoodItemClickHandler}>
              {t("button-edit")}
            </Button>
            <Button startIcon={<AddToCardIcon />} onClick={addToCartClickHandler}>
              {t("button-add-to-cart")}
            </Button>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <TextWithLabel
            label={t("input-label-food-quantity")}
            text={quantityAndUnitToString(foodItem.quantity, foodItem.unit)}
            allowWrap
          />
          <TextWithLabel
            label={t("input-label-want-date-created")}
            text={dateToStringFormat(foodItem.dateCreated)}
            allowWrap
          />
        </Stack>

        {foodItem.tags.length > 0 && (
          <Stack direction="row" spacing={2}>
            {foodItem.tags.map((tag) => (
              <Chip key={tag.id} label={tag.name} />
            ))}
          </Stack>
        )}

        {foodItem.history != null && <FoodHistory foodItem={foodItem} history={foodItem.history} />}
      </Stack>
      <FoodHistoryForm foodItem={foodItem} open={isUpdateHistoryFormOpen} onClose={closeUpdateHistoryFormHandler} />
    </React.Fragment>
  );
};

export default FoodItemPage;
