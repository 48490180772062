import React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store";
import { logout } from "../../store/general/general_slice";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../UI/MainNavigationBar";

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutClickHandler = () => {
    navigate(NavigationRoutes.Home);
    dispatch(logout());
  };

  return (
    <React.Fragment>
      <Button onClick={logoutClickHandler}>{t("button-logout")}</Button>
    </React.Fragment>
  );
};

export default SettingsPage;
