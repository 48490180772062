import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  buttonSideBox: {
    width: "100%",
    "&: after": {
      content: "''",
      height: "100%",
      width: theme.spacing(1),
      position: "absolute",
      left: "-8px",
      top: 0,
      borderTopRightRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
  },
  buttonTopBox: {
    width: "100%",
    "&: after": {
      content: "''",
      width: "100%",
      height: theme.spacing(1),
      position: "absolute",
      bottom: "-12px",
      left: 0,
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
  },
  bigSideBox: {
    "&: after": {
      left: "-16px",
    },
  },
  bottomStyles: {
    textAlign: "center",
  },
}));

const NavigationButton: React.FC<{
  isNavOpen: boolean;
  title: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  isCurrentPage: boolean;
  onClick: () => void;
  isOnBottom: boolean;
}> = ({ isNavOpen, title, Icon, isCurrentPage, isOnBottom, onClick }) => {
  const { classes, cx } = useStyles();

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        className={cx({
          [classes.buttonSideBox]: isCurrentPage && !isOnBottom,
          [classes.buttonTopBox]: isCurrentPage && isOnBottom,
          [classes.bigSideBox]: isCurrentPage && isNavOpen,
          [classes.bottomStyles]: isOnBottom,
        })}
      >
        <Tooltip title={isNavOpen ? undefined : title} placement="right">
          {isNavOpen ? (
            <Button
              onClick={onClick}
              sx={(theme) => ({
                height: "48px",
                width: "100%",
                justifyContent: "left",
                color: isCurrentPage ? theme.palette.secondary.main : theme.palette.grey[500],
              })}
            >
              <Icon sx={(theme) => ({})} fontSize="large" />
              <Typography
                sx={(theme) => ({
                  fontSize: 20,
                  ml: theme.spacing(2),
                })}
              >
                {title}
              </Typography>
            </Button>
          ) : (
            <IconButton sx={{ height: "48px" }} onClick={onClick} style={{ fill: "green" }}>
              <Icon
                sx={(theme) => ({ color: isCurrentPage ? theme.palette.background.default : theme.palette.grey[500] })}
                fontSize="large"
              />
            </IconButton>
          )}
        </Tooltip>
      </div>
    </div>
  );
};

export default NavigationButton;
