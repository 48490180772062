import React from "react";
import Grid from "@mui/material/Grid";
import CustomPieChart from "../charts/CustomPieChart";
import { useAppSelector } from "../../../store";
import {
  selectBudgetDateFilter,
  selectBudgetEntriesData,
  selectBudgetingEntries,
} from "../../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";
import { CashflowDirection, calculateValueForRepeat } from "../../../models/enums/entry/CashflowDirection";
import { Theme, useMediaQuery } from "@mui/material";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import CustomSkeleton from "../../misc/CustomSkeleton";

const BudgetingPieCharts: React.FC = () => {
  const { t } = useTranslation();

  const matchesMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const budgetEntries = useAppSelector((state) => selectBudgetingEntries(state));
  const {
    rest: { getStatus },
  } = useAppSelector((state) => selectBudgetEntriesData(state));
  const datesFilter = useAppSelector((state) => selectBudgetDateFilter(state));

  if (getStatus === HttpFetchStatus.Loading) {
    return (
      <React.Fragment>
        <CustomSkeleton gridSize={{ xs: matchesMdUp ? 6 : 12 }} height={32} />
        <CustomSkeleton gridSize={{ xs: matchesMdUp ? 6 : 12 }} height={32} />
      </React.Fragment>
    );
  }

  if (budgetEntries == null || budgetEntries.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Grid item xs={matchesMdUp ? 6 : 12}>
        <CustomPieChart
          label={t("title-income")}
          noneLabel={t("info-no-income-set-in-budget")}
          data={budgetEntries
            .filter(
              (entry) =>
                entry.cashflowDirection === CashflowDirection.INCOME &&
                entry.amountDue != null &&
                entry.dateArchived == null
            )
            .map((entry) => ({
              tag: entry.tags.length > 0 ? { color: entry.tags[0].color, name: entry.tags[0].name } : null,
              value: -calculateValueForRepeat(
                entry.amountDue!,
                entry.repeat,
                datesFilter.type,
                entry.cashflowDirection
              ),
            }))}
          miniLabel={t("currency-" + budgetEntries[0].currency)}
        />
      </Grid>
      <Grid item xs={matchesMdUp ? 6 : 12}>
        <CustomPieChart
          label={t("title-expenses")}
          noneLabel={t("info-no-expenses-set-in-budget")}
          data={budgetEntries
            .filter(
              (entry) =>
                entry.cashflowDirection === CashflowDirection.EXPENSE &&
                entry.amountDue != null &&
                entry.dateArchived == null
            )
            .map((entry) => ({
              tag: entry.tags.length > 0 ? { color: entry.tags[0].color, name: entry.tags[0].name } : null,
              value: calculateValueForRepeat(entry.amountDue!, entry.repeat, datesFilter.type, entry.cashflowDirection),
            }))}
          miniLabel={t("currency-" + budgetEntries[0].currency)}
        />
      </Grid>
    </React.Fragment>
  );
};

export default BudgetingPieCharts;
