import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectBudgetEntriesData, selectBudgetingEntries } from "../../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";
import TransactionListCard from "../transactions/TransactionListCard";
import { CashflowDirection, EntryType } from "../../../models/enums/entry/CashflowDirection";
import { AddIcon } from "../../UI/icons";
import { setEditedEntry } from "../../../store/general/general_slice";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import CustomSkeleton from "../../misc/CustomSkeleton";

const BudgetingTransactions: React.FC<{ cashflowDirection: CashflowDirection }> = ({ cashflowDirection }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const budgetingEntries = useAppSelector((state) => selectBudgetingEntries(state));
  const {
    rest: { getStatus },
  } = useAppSelector((state) => selectBudgetEntriesData(state));

  const [showArchived, setShowArchived] = useState(false);

  const addTransactionClickHandler = () => {
    dispatch(setEditedEntry({ amountDue: 0, entryType: EntryType.BUDGETING, cashflowDirection }));
  };

  const archivedSwitchClickHandler = () => {
    setShowArchived((prevState) => !prevState);
  };

  return (
    <Stack spacing={2} sx={{ mt: 4 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h1">
          {cashflowDirection === CashflowDirection.INCOME ? t("title-income") : t("title-expenses")}
        </Typography>
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={<Switch checked={showArchived} onChange={archivedSwitchClickHandler} />}
            label={t("switch-archived")}
          />
          <Button startIcon={<AddIcon />} onClick={addTransactionClickHandler}>
            {t("button-add-new")}
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        {getStatus === HttpFetchStatus.Loading ? (
          Array.from(Array(2)).map((index) => <CustomSkeleton key={index} height={16} />)
        ) : budgetingEntries == null ||
          budgetingEntries.filter(
            (entry) =>
              entry.cashflowDirection === cashflowDirection &&
              ((!showArchived && entry.dateArchived == null) || (showArchived && entry.dateArchived != null))
          ).length === 0 ? (
          showArchived ? (
            <Alert severity="info" sx={{ alignItems: "center" }}>
              {t("info-no-archived-entries")}
            </Alert>
          ) : (
            <Alert
              severity="info"
              sx={{ alignItems: "center" }}
              action={
                <Button variant="outlined" sx={{ m: 1 }} startIcon={<AddIcon />} onClick={addTransactionClickHandler}>
                  {t("button-add-new")}
                </Button>
              }
            >
              {cashflowDirection === CashflowDirection.INCOME
                ? t("info-no-income-set-in-budget")
                : t("info-no-expenses-set-in-budget")}
            </Alert>
          )
        ) : (
          budgetingEntries
            .filter(
              (entry) =>
                entry.cashflowDirection === cashflowDirection &&
                ((!showArchived && entry.dateArchived == null) || (showArchived && entry.dateArchived != null))
            )
            .sort((a, b) => {
              if (a.repeat.toUpperCase() < b.repeat.toUpperCase()) return -1;
              if (a.repeat.toUpperCase() > b.repeat.toUpperCase()) return 1;
              return 0;
            })
            .map((entry) => <TransactionListCard entry={entry} key={entry.id} />)
        )}
      </Stack>
    </Stack>
  );
};

export default BudgetingTransactions;
