import React, { useCallback, useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectUser } from "../../store/general/general_slice_selectors";
import ValidationResult, { isValidationResultArray } from "../../services/validation/common/validation-result";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import { resetUserPostStatus } from "../../store/general/general_slice";
import useValidatedInputEffect from "../../hooks/useValidatedInputEffect";
import {
  validateEmail,
  validateName,
  validatePassword,
  validateRepeatedPassword,
  validateUsername,
} from "../../services/validation/general/user_validations";
import AuthenticationState from "../../models/enums/authenticationState";
import { createAccount, login } from "../../store/general/general_actions";
import { isHttpError } from "../../models/misc/isObjectType";
import TextInput from "../misc/forms/TextInput";
import { EmailIcon, HideFieldIcon, PasswordIcon, ShowFieldIcon, UserIcon, UsernameIcon } from "../UI/icons";
import LoginBackdrop from "../../media/images/login_backdrop.png";

const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => selectUser(state));

  const [showPassword, setShowPassword] = useState(false);
  const [authState, setAuthState] = useState(AuthenticationState.Login);
  const [serverValidationErrors, setServerValidationErrors] = useState<ValidationResult[]>(
    isValidationResultArray(user.rest.postError) ? user.rest.postError : []
  );

  useEffect(() => {
    if (user.rest.postStatus === HttpFetchStatus.Failed && isValidationResultArray(user.rest.postError)) {
      setServerValidationErrors([...user.rest.postError]);
      dispatch(resetUserPostStatus());
    }
  }, [user.rest, dispatch]);

  const removeServerError = (inputKey: string) => {
    setServerValidationErrors((prevState) =>
      prevState.filter((sv) => !(sv.inputKey !== undefined && sv.inputKey === inputKey))
    );
  };

  const getServerValidationResultOrNull = (inputKey: string) => {
    let serverValidation = serverValidationErrors.find((sv) => sv.inputKey === inputKey);
    return serverValidation !== undefined ? serverValidation : null;
  };

  const {
    value: username,
    validationResult: usernameValidationResult,
    valueChangeHandler: usernameChangeHandler,
  } = useValidatedInputEffect(
    "",
    (newUsername) => validateUsername(newUsername),
    (_) => () => {},
    getServerValidationResultOrNull("username"),
    removeServerError
  );

  const {
    value: name,
    validationResult: nameValidationResult,
    valueChangeHandler: nameChangeHandler,
  } = useValidatedInputEffect(
    "",
    (newName) => validateName(newName),
    (_) => () => {},
    getServerValidationResultOrNull("name"),
    removeServerError
  );

  const {
    value: email,
    validationResult: emailValidationResult,
    valueChangeHandler: emailChangeHandler,
  } = useValidatedInputEffect(
    "",
    (newEmail) => validateEmail(newEmail),
    (_) => () => {},
    getServerValidationResultOrNull("email"),
    removeServerError
  );

  const {
    value: password,
    validationResult: passwordValidationResult,
    valueChangeHandler: passwordChangeHandler,
  } = useValidatedInputEffect(
    "",
    (newPassword) => validatePassword(newPassword),
    (_) => () => {},
    getServerValidationResultOrNull("password"),
    removeServerError
  );

  const {
    value: repeatedPassword,
    validationResult: repeatedPasswordValidationResult,
    valueChangeHandler: repeatedPasswordChangeHandler,
  } = useValidatedInputEffect(
    "",
    (newPassword) => validateRepeatedPassword(password, newPassword),
    (_) => () => {},
    getServerValidationResultOrNull("repeatedPassword")
  );

  const showPasswordClickHandler = () => setShowPassword((show) => !show);

  const mainActionClickHandler = useCallback(() => {
    switch (authState) {
      case AuthenticationState.Login:
        dispatch(login({ username, password }));
        break;
      case AuthenticationState.CreateAccount:
        dispatch(createAccount({ username, password, repeatedPassword, name, email }));
        break;
      default:
        break;
    }
  }, [authState, dispatch, email, name, password, repeatedPassword, username]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key?.toLowerCase() === "enter" || event.key?.toLowerCase() === "numpadenter") {
        mainActionClickHandler();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [mainActionClickHandler]);

  const mainActionDisabled =
    (authState === AuthenticationState.Login &&
      (!usernameValidationResult.isValid || !passwordValidationResult.isValid)) ||
    (authState === AuthenticationState.CreateAccount &&
      (!usernameValidationResult.isValid ||
        !passwordValidationResult.isValid ||
        !nameValidationResult.isValid ||
        !emailValidationResult.isValid ||
        !passwordValidationResult.isValid ||
        !repeatedPasswordValidationResult.isValid));

  return (
    <React.Fragment>
      <img src={LoginBackdrop} alt="" style={{ objectFit: "scale-down", position: "absolute", right: 0, bottom: 0 }} />
      <Dialog open maxWidth="sm" fullWidth>
        <DialogTitle textAlign="center" variant="h1">
          {authState === AuthenticationState.Login ? t("title-login") : t("title-create-account")}
          <Button
            variant="text"
            onClick={() =>
              setAuthState((prevState) =>
                prevState === AuthenticationState.Login ? AuthenticationState.CreateAccount : AuthenticationState.Login
              )
            }
          >
            {authState === AuthenticationState.Login
              ? t("button-switch-to-create-account")
              : t("button-switch-to-login")}
          </Button>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {user.rest.postError !== null && isHttpError(user.rest.postError) && (
              <Alert severity="error">{t(user.rest.postError.message)}</Alert>
            )}

            <React.Fragment>
              <TextInput
                label={t("input-label-username")}
                type="string"
                startIcon={<UsernameIcon />}
                value={username}
                onChange={usernameChangeHandler}
                validationResult={usernameValidationResult}
              />
              {authState === AuthenticationState.CreateAccount && (
                <React.Fragment>
                  <TextInput
                    label={t("input-label-name")}
                    type="string"
                    startIcon={<UserIcon />}
                    value={name}
                    onChange={nameChangeHandler}
                    validationResult={nameValidationResult}
                  />
                  <TextInput
                    label={t("input-label-email")}
                    type="string"
                    startIcon={<EmailIcon />}
                    value={email}
                    onChange={emailChangeHandler}
                    validationResult={emailValidationResult}
                  />
                </React.Fragment>
              )}
              <TextInput
                label={t("input-label-password")}
                type={showPassword ? "string" : "password"}
                startIcon={<PasswordIcon />}
                endIcon={showPassword ? <HideFieldIcon /> : <ShowFieldIcon />}
                onEndIconClick={showPasswordClickHandler}
                value={password}
                onChange={passwordChangeHandler}
                validationResult={passwordValidationResult}
              />
              {authState === AuthenticationState.CreateAccount && (
                <TextInput
                  label={t("input-label-repeat-password")}
                  type={showPassword ? "string" : "password"}
                  startIcon={<PasswordIcon />}
                  endIcon={showPassword ? <HideFieldIcon /> : <ShowFieldIcon />}
                  onEndIconClick={showPasswordClickHandler}
                  value={repeatedPassword}
                  onChange={repeatedPasswordChangeHandler}
                  validationResult={repeatedPasswordValidationResult}
                />
              )}
            </React.Fragment>
            <Button
              variant="contained"
              fullWidth
              size="large"
              onClick={mainActionClickHandler}
              disabled={mainActionDisabled}
              type="submit"
            >
              {authState === AuthenticationState.Login ? t("button-log-in") : t("button-create-account")}
            </Button>
            <Button variant="text">{t("button-forgot-password")}</Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default LoginPage;
