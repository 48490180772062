import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../store";
import { selectBudgetEntriesData } from "../../../store/general/general_slice_selectors";
import { dateToStringFormat, sortByDate } from "../../../utils/date";
import BudgetEntry from "../../../models/budget/budget-entry";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

const NextPlannedTransaction: React.FC<{ entry: BudgetEntry }> = ({ entry }) => {
  const { t } = useTranslation();

  const budgetEntries = useAppSelector((state) => selectBudgetEntriesData(state));

  const nextEntry = budgetEntries.data
    ?.filter(
      (e) =>
        e.dateDue != null &&
        e.budgetingEntry === entry.id &&
        DateTime.fromJSDate(new Date(e.dateDue)).toMillis() > DateTime.now().toMillis()
    )
    .sort((a, b) => sortByDate(b.dateDue!, a.dateDue!))
    .find((e) => e.budgetingEntry === entry.id);

  return (
    <React.Fragment>
      <Box>
        <Typography variant="caption">{t("label-next-entry")}</Typography>
        <Typography ml={2} variant="body1">
          {nextEntry != null ? dateToStringFormat(nextEntry.dateDue!) : t("info-no-planned-entry")}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default NextPlannedTransaction;
