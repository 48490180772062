import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextInput from "../../misc/forms/TextInput";
import { useTranslation } from "react-i18next";
import useValidatedInputEffect from "../../../hooks/useValidatedInputEffect";
import ValidationResult, { isValidationResultArray } from "../../../services/validation/common/validation-result";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectFoodItemsData, selectTagsData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import {
  createFoodItem,
  deleteFoodItemById,
  getFoodItems,
  getTags,
  updateFoodItemById,
} from "../../../store/general/general_actions";
import useValidatedMultipleSelectEffect from "../../../hooks/useValidatedMultipleSelectEffect";
import TagGroup from "../../../models/enums/entry/TagGroup";
import TagSelect from "../../misc/forms/TagSelect";
import FoodItem from "../../../models/pantry/food-item";
import {
  validateFoodItem,
  validateFoodItemExpiryDate,
  validateFoodItemQuantity,
  validateFoodItemTags,
} from "../../../services/validation/general/food_item_validation";
import FoodUnit from "../../../models/enums/pantry/foodUnit";
import useValidatedSelectEffect from "../../../hooks/useValidatedSelectEffect";
import SelectInput from "../../misc/forms/SelectInput";
import AutoComplete from "../../misc/forms/AutoComplete";
import { DateTime } from "luxon";
import { FormControlLabel, IconButton, Switch } from "@mui/material";
import DateInput from "../../misc/forms/DateInput";
import { AddIcon, MinusIcon } from "../../UI/icons";

const FoodForm: React.FC<{
  foodItem: Partial<FoodItem>;
  onClose: () => void;
}> = ({ foodItem, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const foodData = useAppSelector((state) => selectFoodItemsData(state));
  const tagsData = useAppSelector((state) => selectTagsData(state));

  const [serverValidationErrors, setServerValidationErrors] = useState<ValidationResult[]>([]);
  const [isExpiryDateSet, setIsExpiryDateSet] = useState(false);
  const [isIncreasingQuantity, setIsIncreasingQuantity] = useState(true);

  useEffect(() => {
    if (isValidationResultArray(foodData.rest.postError)) {
      setServerValidationErrors(foodData.rest.postError);
    } else if (isValidationResultArray(foodData.rest.patchError)) {
      setServerValidationErrors(foodData.rest.patchError);
    }
  }, [foodData.rest.patchError, foodData.rest.postError]);

  useEffect(() => {
    if (foodData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getFoodItems());
    }
  }, [foodData.rest.getStatus, dispatch]);

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [tagsData.rest.getStatus, dispatch]);

  const removeServerError = (inputKey: string) => {
    setServerValidationErrors((prevState) =>
      prevState.filter((sv) => !(sv.inputKey !== undefined && sv.inputKey === inputKey))
    );
  };

  const getServerValidationResultOrNull = (inputKey: string) => {
    let serverValidation = serverValidationErrors.find((sv) => sv.inputKey === inputKey);
    return serverValidation !== undefined ? serverValidation : null;
  };

  const {
    value: item,
    validationResult: itemValidationResult,
    valueChangeHandler: itemChangeHandler,
  } = useValidatedInputEffect<string>(
    foodItem.item ?? "",
    (newItem) => validateFoodItem(newItem),
    (_) => () => {},
    getServerValidationResultOrNull("item"),
    removeServerError
  );

  const {
    value: tags,
    validationResult: tagsValidationResult,
    valueChangeHandler: tagsChangeHandler,
  } = useValidatedMultipleSelectEffect(
    foodItem?.tags != null ? foodItem.tags.map((tag) => tag.id) : [],
    (tags) => validateFoodItemTags(tags),
    (_) => () => {},
    getServerValidationResultOrNull("tags"),
    removeServerError
  );

  const {
    value: quantity,
    validationResult: quantityValidationResult,
    valueChangeHandler: quantityChangeHandler,
  } = useValidatedInputEffect<number>(
    foodItem?.quantity ?? 0,
    (newQuantity) => validateFoodItemQuantity(newQuantity),
    (_) => () => {},
    getServerValidationResultOrNull("quantity"),
    removeServerError
  );

  const {
    value: unit,
    validationResult: unitValidationResult,
    valueChangeHandler: unitChangeHandler,
  } = useValidatedSelectEffect(
    foodItem?.unit ?? FoodUnit.GRAMS,
    (newUnit) => ({ isValid: true, error: null }),
    (_) => () => {},
    getServerValidationResultOrNull("unit"),
    removeServerError
  );

  const [expiryDate, setExpiryDate] = useState<{ value: DateTime; validation: ValidationResult }>({
    value:
      foodItem?.expiryDate != null
        ? DateTime.fromJSDate(new Date(foodItem.expiryDate))
        : DateTime.fromISO(DateTime.now().toString()).toLocal(),
    validation: getServerValidationResultOrNull("expiryDate") ?? {
      ...validateFoodItemExpiryDate(DateTime.fromISO(DateTime.now().toString())),
    },
  });

  const addFoodItemClickHandler = () => {
    dispatch(
      createFoodItem({
        item: item,
        tags: tagsData.data?.filter((t) => tags.some((tId) => tId === t.id)),
        quantity: quantity * (isIncreasingQuantity ? 1 : -1),
        unit: unit as FoodUnit,
        expiryDate: isExpiryDateSet ? expiryDate.value.toJSDate() : null,
      })
    );
  };

  const updateFoodItemClickHandler = () => {
    if (foodItem.id != null) {
      dispatch(
        updateFoodItemById({
          id: foodItem.id,
          item: item,
          tags: tagsData.data?.filter((t) => tags.some((tId) => tId === t.id)) ?? foodItem.tags,
        })
      );
    }
  };

  const deleteFoodItemClickHandler = () => {
    if (foodItem.id != null) {
      dispatch(deleteFoodItemById(foodItem.id));
    }
  };

  const closeFormClickHandler = () => {
    onClose();
  };

  const toggleIsExpiryDateSetHandler = () => {
    setIsExpiryDateSet((prevState) => !prevState);
  };

  const expiryDateChangeHandler = (newExpiryDate: DateTime | null) => {
    if (newExpiryDate) {
      setExpiryDate({ value: newExpiryDate, validation: validateFoodItemExpiryDate(newExpiryDate) });
      removeServerError("expiryDate");
    }
  };

  const toggleIsIncreasingQuantityClickHandler = () => {
    setIsIncreasingQuantity((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <Dialog open onClose={closeFormClickHandler} fullWidth maxWidth="sm">
        <DialogContent>
          <Box>
            <Stack spacing={2}>
              <Typography variant="h2">
                {foodItem.id == null ? t("title-add-food-item") : t("title-edit-food-item")}
              </Typography>

              <AutoComplete
                label={t("input-label-food-item")}
                value={item}
                onChange={itemChangeHandler}
                validationResult={itemValidationResult}
                options={(foodData.data ?? []).map((item) => item.item)}
              />
              {foodItem.id == null && (
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={(theme) => ({
                      height: theme.spacing(7),
                      pt: 1,
                    })}
                  >
                    <IconButton
                      color={isIncreasingQuantity ? "success" : "error"}
                      onClick={toggleIsIncreasingQuantityClickHandler}
                    >
                      {isIncreasingQuantity ? <AddIcon /> : <MinusIcon />}
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}>
                    <TextInput
                      label={t("input-label-food-quantity")}
                      type="number"
                      value={quantity}
                      onChange={quantityChangeHandler}
                      validationResult={quantityValidationResult}
                    />
                  </Box>
                  <Box sx={(theme) => ({ width: theme.spacing(11) })}>
                    <SelectInput
                      label={t("input-label-food-item-unit")}
                      value={unit}
                      onChange={unitChangeHandler}
                      validationResult={unitValidationResult}
                    >
                      {Object.values(FoodUnit).map((u) => (
                        <MenuItem key={u} value={u}>
                          {t("food-unit-" + u)}
                        </MenuItem>
                      ))}
                    </SelectInput>
                  </Box>
                </Stack>
              )}
              <TagSelect
                tagIds={tags}
                tagGroups={[TagGroup.Pantry]}
                tagsValidationResult={tagsValidationResult}
                onTagsChange={tagsChangeHandler}
              />

              {foodItem.id == null && (
                <Stack spacing={1}>
                  <FormControlLabel
                    control={<Switch checked={isExpiryDateSet} onChange={toggleIsExpiryDateSetHandler} />}
                    label={t("switch-food-item-expiry-date-set")}
                  />
                  {isExpiryDateSet && (
                    <DateInput
                      date={expiryDate.value}
                      title={t("input-label-food-item-expiry-date") as string}
                      dateValidation={expiryDate.validation}
                      onDateTimeChange={expiryDateChangeHandler}
                    />
                  )}
                </Stack>
              )}

              <Button
                variant="contained"
                fullWidth
                disabled={
                  foodData.rest.postStatus === HttpFetchStatus.Loading ||
                  foodData.rest.patchStatus === HttpFetchStatus.Loading
                }
                onClick={foodItem.id == null ? addFoodItemClickHandler : updateFoodItemClickHandler}
              >
                {foodData.rest.postStatus === HttpFetchStatus.Loading ||
                foodData.rest.patchStatus === HttpFetchStatus.Loading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : foodItem.id == null ? (
                  t("button-add")
                ) : (
                  t("button-save")
                )}
              </Button>
              {foodItem.id != null && (
                <Button
                  variant="text"
                  fullWidth
                  size="small"
                  color="error"
                  onClick={deleteFoodItemClickHandler}
                  disabled={foodData.rest.deleteStatus === HttpFetchStatus.Loading}
                >
                  {foodData.rest.deleteStatus === HttpFetchStatus.Loading ? (
                    <CircularProgress size={24} color="error" />
                  ) : (
                    t("button-delete")
                  )}
                </Button>
              )}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default FoodForm;
