import React, { useEffect } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { Route, Routes } from "react-router-dom";

import { customTheme } from "./utils/theme";
import { useAppDispatch, useAppSelector } from "./store";
import { getToken } from "./services/data/axios-base";
import { getUser } from "./store/general/general_actions";
import Home from "./components/pages/Home";
import PageNotFound from "./components/pages/PageNotFound";
import CentralContentBlock from "./components/UI/CentralContentBlock";
import RightQuickFormsBlock from "./components/UI/RightQuickFormsBlock";
import { localLogin } from "./store/general/general_slice";
import { selectUser } from "./store/general/general_slice_selectors";
import LoginPage from "./components/pages/LoginPage";
import BudgetPage from "./components/pages/BudgetPage";
import SettingsPage from "./components/pages/SettingsPage";
import GoalsPage from "./components/pages/GoalsPage";
import DailyPage from "./components/pages/DailyPage";
import MainNavigationBar, { NavigationRoutes } from "./components/UI/MainNavigationBar";
import TransactionsPage from "./components/budget/transactions/TransactionsPage";
import TagsPage from "./components/tags/TagsPage";
import TransactionOverviewPage from "./components/budget/transactions/TransactionOverviewPage";
import PantryPage from "./components/pages/PantryPage";
import HttpFetchStatus from "./models/common/api/http-fetch-status";
import WishlistPage from "./components/pages/WishlistPage";
import FoodItemPage from "./components/pages/FoodItemPage";
// https://miro.medium.com/v2/resize:fit:1400/1*NnpSXRhJMAj1d8kUIm4UfQ.png

declare module "@mui/private-theming" {
  interface DefaultTheme extends Theme {}
}

export const getRouteBase = () => "/";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matchesMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const user = useAppSelector((state) => selectUser(state));

  useEffect(() => {
    const accessToken = getToken();
    if (accessToken !== null) {
      dispatch(localLogin(accessToken));
      dispatch(getUser(accessToken));
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {user.rest.getStatus === HttpFetchStatus.Idle || user.rest.getStatus === HttpFetchStatus.Loading ? (
        <Box />
      ) : user.data?.id != null ? (
        <Stack
          direction={matchesMdUp ? "row" : "column-reverse"}
          sx={(theme) => ({ height: "100vh", backgroundColor: theme.palette.background.default, width: "100%" })}
        >
          <MainNavigationBar />
          <CentralContentBlock>
            <Routes>
              <Route path={NavigationRoutes.Home} element={<Home />} />
              <Route path={NavigationRoutes.Calendar} element={<DailyPage />} />
              <Route path={NavigationRoutes.Budget} element={<BudgetPage />} />
              <Route path={NavigationRoutes.Pantry} element={<PantryPage />} />
              <Route path={NavigationRoutes.Goals} element={<GoalsPage />} />
              <Route path={NavigationRoutes.Settings} element={<SettingsPage />} />
              <Route path={NavigationRoutes.Transactions} element={<TransactionsPage />} />
              <Route path={NavigationRoutes.Tags} element={<TagsPage />} />
              <Route path={`${NavigationRoutes.Transactions}/:transactionId`} element={<TransactionOverviewPage />} />
              <Route path={`${NavigationRoutes.Wishlist}/:wantId`} element={<WishlistPage />} />
              <Route path={`${NavigationRoutes.Pantry}/:itemId`} element={<FoodItemPage />} />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </CentralContentBlock>
          <RightQuickFormsBlock />
        </Stack>
      ) : (
        <Routes>
          <Route path={getRouteBase()} element={<LoginPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </ThemeProvider>
  );
};

export default App;
