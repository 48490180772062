import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import ValidationResult from "../../../services/validation/common/validation-result";
import i18n from "../../../i18n";
import { DatePicker } from "@mui/x-date-pickers";

const DateInput: React.FC<{
  date: DateTime;
  title: string;
  dateValidation: ValidationResult;
  minDate?: DateTime;
  onDateTimeChange: (datetime: DateTime | null) => void;
}> = ({ date, title, dateValidation, minDate, onDateTimeChange }) => {
  return (
    <Stack gap={0} sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
        <DatePicker
          value={date}
          label={title}
          slotProps={{ textField: { variant: "filled" } }}
          onChange={onDateTimeChange}
          minDate={minDate}
          format="dd/MM/yyyy"
        />
      </LocalizationProvider>
      {dateValidation.error && (
        <FormHelperText
          sx={(theme) => ({ paddingLeft: 2, color: dateValidation.isValid ? undefined : theme.palette.error.main })}
        >
          {dateValidation.error}
        </FormHelperText>
      )}
    </Stack>
  );
};

export default DateInput;
