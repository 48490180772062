import { DateTime } from "luxon";
import { RootState } from "..";
import FilterDate from "../../models/budget/filter-date";
import { createSelector } from "@reduxjs/toolkit";
import { EntryType } from "../../models/enums/entry/CashflowDirection";

export const selectUser = (state: RootState) => state.general.user;

export const selectBudgetEntriesData = (state: RootState) => state.general.budgetEntries;
export const selectBudgetingEntries = (state: RootState) =>
  state.general.budgetEntries.data?.filter((entry) => entry.entryType === EntryType.BUDGETING);
export const selectTasksData = (state: RootState) => state.general.tasks;
export const selectTagsData = (state: RootState) => state.general.tags;
export const selectWantsData = (state: RootState) => state.general.wants;
export const selectFoodItemsData = (state: RootState) => state.general.foodItems;

export const selectEditedEntry = (state: RootState) => state.general.editedEntry;
export const selectEditedTag = (state: RootState) => state.general.editedTag;
export const selectEditedTask = (state: RootState) => state.general.editedTask;
export const selectEditedWant = (state: RootState) => state.general.editedWant;
export const selectEditedFoodItem = (state: RootState) => state.general.editedFoodItem;

export const selectBudgetDateFilter = (state: RootState): FilterDate => ({
  ...state.general.filters.budgetDateFilter,
  date: DateTime.fromISO(state.general.filters.budgetDateFilter.date),
});

export const selectTimeFilteredBudgetEntries = createSelector(
  [selectBudgetEntriesData, selectBudgetDateFilter],
  (entries, dateFilter) => {
    if (entries.data == null) {
      return null;
    }
    return entries.data.filter(
      (entry) =>
        entry.entryType !== EntryType.BUDGETING &&
        ((entry.datePaid != null &&
          DateTime.fromJSDate(new Date(entry.datePaid.toString())).toMillis() >=
            dateFilter.date.startOf(dateFilter.type).toMillis() &&
          DateTime.fromJSDate(new Date(entry.datePaid.toString())).toMillis() <=
            dateFilter.date.endOf(dateFilter.type).toMillis()) ||
          (dateFilter.includePlanned &&
            entry.dateDue != null &&
            DateTime.fromJSDate(new Date(entry.dateDue.toString())).toMillis() >=
              dateFilter.date.startOf(dateFilter.type).toMillis() &&
            DateTime.fromJSDate(new Date(entry.dateDue.toString())).toMillis() <=
              dateFilter.date.endOf(dateFilter.type).toMillis()))
    );
  }
);
