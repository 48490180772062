import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const AmountDescriptionCard: React.FC<{
  label: string;
  currency: string;
  amount: string;
  description: string;
  amountColor?: "green" | "error";
}> = ({ label, currency, amount, description, amountColor }) => {
  return (
    <Card>
      <CardContent>
        <Stack direction="row" gap={8} alignItems="center">
          <Box>
            <Typography variant="caption">{label}</Typography>
            <Stack direction="row" alignItems="baseline" gap={2}>
              <Typography variant="h2">{currency}</Typography>
              <Typography variant="h1" color={amountColor}>
                {amount}
              </Typography>
            </Stack>
          </Box>
          <Typography>{description}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AmountDescriptionCard;
