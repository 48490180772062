import React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import ValidationResult from "../../../services/validation/common/validation-result";

const TextInput: React.FC<{
  label: string;
  type: "string" | "password" | "number";
  size?: "small" | "medium";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  value: string | number;
  minValue?: number;
  multiline?: boolean;
  validationResult?: ValidationResult;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEndIconClick?: () => void;
}> = ({
  label,
  type,
  size = "medium",
  startIcon,
  endIcon,
  multiline,
  value,
  minValue,
  validationResult,
  onChange,
  onEndIconClick,
}) => {
  const { t } = useTranslation();

  const mouseDownEndIconHandler = (
    event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      fullWidth
      size={size}
      label={label}
      value={value ?? ""}
      variant="filled"
      multiline={multiline}
      minRows={multiline ? 2 : undefined}
      onChange={onChange}
      helperText={
        validationResult !== undefined && validationResult.error !== null ? t(validationResult.error) : undefined
      }
      InputProps={{
        inputProps: { min: minValue },
        startAdornment:
          startIcon !== undefined ? <InputAdornment position="start">{startIcon}</InputAdornment> : undefined,
        endAdornment:
          endIcon !== undefined ? (
            <InputAdornment position="end">
              {onEndIconClick !== undefined ? (
                <IconButton onClick={onEndIconClick} onMouseDown={mouseDownEndIconHandler} edge="end">
                  {endIcon}
                </IconButton>
              ) : (
                endIcon
              )}
            </InputAdornment>
          ) : undefined,
      }}
      type={value.toString().length === 0 ? "string" : type}
      error={validationResult !== undefined && !validationResult.isValid}
    />
  );
};

export default TextInput;
