interface ValidationResult {
  isValid: boolean;
  error: string | null;
  inputKey?: string;
}

export const mergeMainSubValidationResult = (
  mainValidationResult: ValidationResult,
  subValidationResult: ValidationResult
) => {
  mainValidationResult.isValid = mainValidationResult.isValid && subValidationResult.isValid;
  if (subValidationResult.error != null) {
    mainValidationResult.error =
      mainValidationResult.error == null
        ? subValidationResult.error
        : mainValidationResult.error + "\n" + subValidationResult.error;
  }
};

export const newValidValidationResult = (): ValidationResult => {
  return { isValid: true, error: null };
};

export const isValidationResultArray = (array: any): array is ValidationResult[] => {
  return Array.isArray(array) && array.length > 0 && "isValid" in array[0] && "error" in array[0];
};

export default ValidationResult;
