import axios from "axios";
import i18n, { getCurrentLanguage } from "../../i18n";
import HttpError, { newHttpError } from "../../models/common/api/http-error";
import ValidationResult from "../validation/common/validation-result";
import { accessTokenStorageName } from "../../store/general/general_slice_definitions";

// export const baseUrlApiManagement = `http://0.0.0.0:8000/api`;
export const baseUrlApiManagement = `${window.location.protocol}//${
  window.location.hostname === "localhost" ? "localhost:8001" : window.location.hostname
}/api`;

export const currentVersionGeneralManagement = "v1.0";

export const generalManagementApiInstance = axios.create({
  baseURL: baseUrlApiManagement,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": getCurrentLanguage().languageCode,
  },
});

export const getToken = () =>
  localStorage.getItem(accessTokenStorageName) ? localStorage.getItem(accessTokenStorageName) : null;

export const removeToken = () => {
  localStorage.removeItem(accessTokenStorageName);
};

generalManagementApiInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Token " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const returnAxiosValidationOrGeneralFetchError = (error: any): HttpError | ValidationResult[] => {
  if (axios.isAxiosError(error)) {
    if (error.response?.data !== null && typeof error.response?.data === "object") {
      let validationResults: ValidationResult[] = [];
      Object.entries(error.response.data).map(([key, value]) => {
        validationResults.push({
          isValid: false,
          error:
            typeof value === "string"
              ? value
              : Array.isArray(value)
              ? i18n.t(value[0])
              : i18n.t("error-unknown-validation-error"),
          inputKey: key,
        });
        return null;
      });
      return validationResults;
    } else {
      return newHttpError(
        String(error.response?.status),
        error.response?.data != null &&
          typeof error.response.data === "string" &&
          (error.response.status === 400 || error.response.status === 503)
          ? `${error.response.data}`
          : i18n.t("error-unknown-server-error"),
        error.response?.data != null && typeof error.response.data === "string" && error.response.status === 500
          ? `${error.response.data}`
          : null
      );
    }
  }
  return newHttpError("FAILED", "");
};

export const returnAxiosOrGeneralFetchError = (error: any): HttpError => {
  if (axios.isAxiosError(error)) {
    return newHttpError(
      String(error.response?.status),
      error.response?.data != null &&
        typeof error.response.data === "string" &&
        (error.response.status === 400 || error.response.status === 503)
        ? `${error.response.data}`
        : i18n.t("error-unknown-server-error"),
      error.response?.data != null && typeof error.response.data === "string" && error.response.status === 500
        ? `${error.response.data}`
        : null
    );
  }
  return newHttpError("FAILED", "");
};
