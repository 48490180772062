import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { parseEnumFromString, parseStringFromEnum } from "../../utils/url-tabs-helper";
import TabPanel, { a11yProps } from "../misc/TabsPanel";
import { useTranslation } from "react-i18next";
import Wants from "../pantry/wants/Wants";
import TagGroup from "../../models/enums/entry/TagGroup";
import Foods from "../pantry/foods/Foods";

enum PantryPageTabs {
  Pantry,
  ShoppingList,
}

const PANTRY_TAB_QUERY_STRING = "t";

const PantryPage: React.FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesLgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const [filterParams, setFilterParams] = useSearchParams();
  const pantryTab = parseEnumFromString(PantryPageTabs, filterParams.get(PANTRY_TAB_QUERY_STRING));

  const [tabsValue, setTabsValue] = useState(pantryTab);

  const tabChangeHandler = (_event: React.SyntheticEvent, newValue: number) => {
    setFilterParams(`${PANTRY_TAB_QUERY_STRING}=${parseStringFromEnum(PantryPageTabs, newValue)}`);
    setTabsValue(newValue);
  };

  return (
    <React.Fragment>
      {matchesLgDown ? (
        <React.Fragment>
          <Tabs value={tabsValue} onChange={tabChangeHandler} sx={(theme) => ({ mb: theme.spacing(2) })}>
            <Tab label={t("title-pantry")} {...a11yProps(PantryPageTabs.Pantry)} />
            <Tab label={t("title-shopping-list")} {...a11yProps(PantryPageTabs.ShoppingList)} />
          </Tabs>

          <TabPanel value={tabsValue} index={PantryPageTabs.Pantry}>
            <Foods />
          </TabPanel>
          <TabPanel value={tabsValue} index={PantryPageTabs.ShoppingList}>
            <Wants isShoppingList tagGroups={[TagGroup.Pantry]} />
          </TabPanel>
        </React.Fragment>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Foods />
          </Grid>
          <Grid item md={4}>
            <Wants isShoppingList tagGroups={[TagGroup.Pantry]} />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default PantryPage;
