export const updateDataList = <T extends { id: string }>(entry: T | null, existingList: T[]): T[] => {
  if (entry != null) {
    if (existingList == null) {
      return [entry];
    } else {
      const index = existingList.findIndex((r) => r.id === entry.id);
      if (index !== -1) {
        return [...existingList.slice(0, index), entry, ...existingList.slice(index + 1)];
      } else {
        return [...existingList, entry];
      }
    }
  }
  return existingList;
};
