import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  accessTokenStorageName,
  defaultState,
  getInitialGeneralState,
  includePlannedStorageName,
} from "./general_slice_definitions";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import {
  addWantItemById,
  createAccount,
  createBudgetEntry,
  createFoodItem,
  createTag,
  createTask,
  createWant,
  deleteEntryById,
  deleteFoodItemById,
  deleteTagById,
  deleteTaskById,
  deleteWantById,
  getBudgetEntries,
  getFoodItems,
  getTags,
  getTasks,
  getUser,
  getWants,
  login,
  planPurchaseWantItemById,
  updateBudgetEntryById,
  updateFoodHistoryById,
  updateFoodItemById,
  updateSubTaskByTaskId,
  updateTagById,
  updateTaskById,
  updateWantById,
} from "./general_actions";
import { updateDataList } from "../../utils/generic-data-list-handlers";
import { removeToken } from "../../services/data/axios-base";
import { StateFilterDate } from "../../models/budget/filter-date";
import BudgetEntry from "../../models/budget/budget-entry";
import Tag from "../../models/general/tag";
import Task from "../../models/calendar/task";
import Want from "../../models/common/wants/want";
import FoodItem from "../../models/pantry/food-item";
import TagGroup from "../../models/enums/entry/TagGroup";

const initialGeneralState = getInitialGeneralState();

const generalSlice = createSlice({
  name: "general",
  initialState: initialGeneralState,
  reducers: {
    resetGeneralState(state) {
      // Reset to initial admin state
      return { ...initialGeneralState };
    },
    localLogin(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    logout(state) {
      removeToken();
      return { ...initialGeneralState };
    },
    resetLoginState(state) {
      state.user.rest.postStatus = HttpFetchStatus.Idle;
      state.user.rest.postError = null;
    },
    resetUserPostStatus(state) {
      state.user.rest.postError = null;
      state.user.rest.postStatus = HttpFetchStatus.Idle;
    },
    resetBudgetEntriesState(state) {
      state.budgetEntries = defaultState;
    },
    resetBudgetEntriesPostState(state) {
      state.budgetEntries.rest.postStatus = HttpFetchStatus.Idle;
      state.budgetEntries.rest.postError = null;
    },
    resetBudgetEntriesPatchState(state) {
      state.budgetEntries.rest.patchStatus = HttpFetchStatus.Idle;
      state.budgetEntries.rest.patchError = null;
    },
    resetBudgetEntriesDeleteState(state) {
      state.budgetEntries.rest.deleteStatus = HttpFetchStatus.Idle;
      state.budgetEntries.rest.deleteError = null;
    },
    resetTagsPostState(state) {
      state.tags.rest.postStatus = HttpFetchStatus.Idle;
      state.tags.rest.postError = null;
    },
    resetTagsPatchState(state) {
      state.tags.rest.patchStatus = HttpFetchStatus.Idle;
      state.tags.rest.patchError = null;
    },
    resetTagsDeleteState(state) {
      state.tags.rest.deleteStatus = HttpFetchStatus.Idle;
      state.tags.rest.deleteError = null;
    },
    resetTasksPostState(state) {
      state.tasks.rest.postStatus = HttpFetchStatus.Idle;
      state.tasks.rest.postError = null;
    },
    resetSubTasksPostState(state) {
      state.tasks.subTaskRest.postStatus = HttpFetchStatus.Idle;
      state.tasks.subTaskRest.postError = null;
    },
    resetTasksPatchState(state) {
      state.tasks.rest.patchStatus = HttpFetchStatus.Idle;
      state.tasks.rest.patchError = null;
    },
    resetTasksDeleteState(state) {
      state.tasks.rest.deleteStatus = HttpFetchStatus.Idle;
      state.tasks.rest.deleteError = null;
    },
    resetWantsPostState(state) {
      state.wants.rest.postStatus = HttpFetchStatus.Idle;
      state.wants.rest.postError = null;
    },
    resetWantsPatchState(state) {
      state.wants.rest.patchStatus = HttpFetchStatus.Idle;
      state.wants.rest.patchError = null;
    },
    resetWantsDeleteState(state) {
      state.wants.rest.deleteStatus = HttpFetchStatus.Idle;
      state.wants.rest.deleteError = null;
    },
    resetAddWantItemPostState(state) {
      state.wants.rest.addPostStatus = HttpFetchStatus.Idle;
      state.wants.rest.addPostError = null;
    },
    resetPlanPurchaseWantItemPostState(state) {
      state.wants.rest.planPurchasePostStatus = HttpFetchStatus.Idle;
      state.wants.rest.planPurchasePostError = null;
    },
    resetFoodItemsPostState(state) {
      state.foodItems.rest.postStatus = HttpFetchStatus.Idle;
      state.foodItems.rest.postError = null;
    },
    resetFoodItemsPatchState(state) {
      state.foodItems.rest.patchStatus = HttpFetchStatus.Idle;
      state.foodItems.rest.patchError = null;
    },
    resetFoodItemsDeleteState(state) {
      state.foodItems.rest.deleteStatus = HttpFetchStatus.Idle;
      state.foodItems.rest.deleteError = null;
    },
    resetFoodHistoryPostState(state) {
      state.foodItems.rest.updateHistoryPostStatus = HttpFetchStatus.Idle;
      state.foodItems.rest.updateHistoryPostError = null;
    },
    setBudgetDateFilter(state, action: PayloadAction<Partial<StateFilterDate>>) {
      state.filters.budgetDateFilter = { ...state.filters.budgetDateFilter, ...action.payload };
      if (action.payload.includePlanned != null) {
        localStorage.setItem(includePlannedStorageName, JSON.stringify(action.payload.includePlanned));
      }
    },
    setEditedEntry(state, action: PayloadAction<Partial<BudgetEntry> | null>) {
      state.editedEntry = action.payload;
    },
    setEditedTag(state, action: PayloadAction<Partial<Tag> | null>) {
      state.editedTag = action.payload;
    },
    setEditedTask(state, action: PayloadAction<Partial<Task> | null>) {
      state.editedTask = action.payload;
    },
    setEditedWant(state, action: PayloadAction<{ data: Partial<Want> | null; tagGroups?: TagGroup[] }>) {
      state.editedWant = { ...state.editedWant, ...action.payload };
    },
    setEditedFoodItem(state, action: PayloadAction<Partial<FoodItem> | null>) {
      state.editedFoodItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBudgetEntries.pending, (state, action) => {
      state.budgetEntries.rest.getStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(getBudgetEntries.fulfilled, (state, action) => {
      state.budgetEntries.rest.getStatus = HttpFetchStatus.Success;
      state.budgetEntries.data = action.payload ?? null;
    });
    builder.addCase(getBudgetEntries.rejected, (state, action) => {
      state.budgetEntries.rest.getStatus = HttpFetchStatus.Failed;
      state.budgetEntries.rest.getError = action.payload ?? null;
    });
    builder.addCase(createBudgetEntry.pending, (state, action) => {
      state.budgetEntries.rest.postStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(createBudgetEntry.fulfilled, (state, action) => {
      state.budgetEntries.rest.postStatus = HttpFetchStatus.Success;
      state.editedEntry = null;
      if (state.budgetEntries.data != null) {
        state.budgetEntries.data = updateDataList(action.payload, state.budgetEntries.data);
      }
    });
    builder.addCase(createBudgetEntry.rejected, (state, action) => {
      state.budgetEntries.rest.postStatus = HttpFetchStatus.Failed;
      state.budgetEntries.rest.postError = action.payload ?? null;
    });
    builder.addCase(updateBudgetEntryById.pending, (state, action) => {
      state.budgetEntries.rest.patchStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(updateBudgetEntryById.fulfilled, (state, action) => {
      state.budgetEntries.rest.patchStatus = HttpFetchStatus.Success;
      state.editedEntry = null;
      if (state.budgetEntries.data != null) {
        state.budgetEntries.data = updateDataList(action.payload, state.budgetEntries.data);
      }
    });
    builder.addCase(updateBudgetEntryById.rejected, (state, action) => {
      state.budgetEntries.rest.patchStatus = HttpFetchStatus.Failed;
      state.budgetEntries.rest.patchError = action.payload ?? null;
    });
    builder.addCase(deleteEntryById.pending, (state, action) => {
      state.budgetEntries.rest.deleteStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(deleteEntryById.fulfilled, (state, action) => {
      state.budgetEntries.rest.deleteStatus = HttpFetchStatus.Success;
      state.editedEntry = null;
      if (action.payload != null && state.budgetEntries.data != null) {
        const index = state.budgetEntries.data.findIndex((be) => be.id === action.payload.id);
        if (index !== -1) {
          state.budgetEntries.data.splice(index, 1);
        }
      }
    });
    builder.addCase(deleteEntryById.rejected, (state, action) => {
      state.budgetEntries.rest.deleteStatus = HttpFetchStatus.Failed;
      state.budgetEntries.rest.deleteError = action.payload ?? null;
    });

    builder.addCase(getTags.pending, (state, action) => {
      state.tags.rest.getStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.tags.rest.getStatus = HttpFetchStatus.Success;
      state.tags.data = action.payload ?? null;
    });
    builder.addCase(getTags.rejected, (state, action) => {
      state.tags.rest.getStatus = HttpFetchStatus.Failed;
      state.tags.rest.getError = action.payload ?? null;
    });
    builder.addCase(createTag.pending, (state, action) => {
      state.tags.rest.postStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(createTag.fulfilled, (state, action) => {
      state.tags.rest.postStatus = HttpFetchStatus.Success;
      if (state.tags.data != null) {
        state.tags.data = updateDataList(action.payload, state.tags.data);
      }
    });
    builder.addCase(createTag.rejected, (state, action) => {
      state.tags.rest.postStatus = HttpFetchStatus.Failed;
      state.tags.rest.postError = action.payload ?? null;
    });
    builder.addCase(updateTagById.pending, (state, action) => {
      state.tags.rest.patchStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(updateTagById.fulfilled, (state, action) => {
      state.tags.rest.patchStatus = HttpFetchStatus.Success;
      if (state.tags.data != null) {
        state.tags.data = updateDataList(action.payload, state.tags.data);
      }
    });
    builder.addCase(updateTagById.rejected, (state, action) => {
      state.tags.rest.patchStatus = HttpFetchStatus.Failed;
      state.tags.rest.patchError = action.payload ?? null;
    });
    builder.addCase(deleteTagById.pending, (state, action) => {
      state.tags.rest.deleteStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(deleteTagById.fulfilled, (state, action) => {
      state.tags.rest.deleteStatus = HttpFetchStatus.Success;
      if (action.payload != null && state.tags.data != null) {
        const index = state.tags.data.findIndex((be) => be.id === action.payload.id);
        if (index !== -1) {
          state.tags.data.splice(index, 1);
        }
      }
    });
    builder.addCase(deleteTagById.rejected, (state, action) => {
      state.tags.rest.deleteStatus = HttpFetchStatus.Failed;
      state.tags.rest.deleteError = action.payload ?? null;
    });

    builder.addCase(getTasks.pending, (state, action) => {
      state.tasks.rest.getStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.tasks.rest.getStatus = HttpFetchStatus.Success;
      state.tasks.data = action.payload ?? null;
    });
    builder.addCase(getTasks.rejected, (state, action) => {
      state.tasks.rest.getStatus = HttpFetchStatus.Failed;
      state.tasks.rest.getError = action.payload ?? null;
    });
    builder.addCase(createTask.pending, (state, action) => {
      state.tasks.rest.postStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(createTask.fulfilled, (state, action) => {
      state.tasks.rest.postStatus = HttpFetchStatus.Success;
      state.editedTask = null;
      if (state.tasks.data != null) {
        state.tasks.data = updateDataList(action.payload, state.tasks.data);
      }
    });
    builder.addCase(createTask.rejected, (state, action) => {
      state.tasks.rest.postStatus = HttpFetchStatus.Failed;
      state.tasks.rest.postError = action.payload ?? null;
    });
    builder.addCase(updateTaskById.pending, (state, action) => {
      state.tasks.rest.patchStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(updateTaskById.fulfilled, (state, action) => {
      state.tasks.rest.patchStatus = HttpFetchStatus.Success;
      if (action.meta.arg.reopening && state.editedTask != null) {
        state.editedTask.dateCompleted = null;
      } else {
        state.editedTask = null;
      }
      if (state.tasks.data != null) {
        state.tasks.data = updateDataList(action.payload, state.tasks.data);
      }
    });
    builder.addCase(updateTaskById.rejected, (state, action) => {
      state.tasks.rest.patchStatus = HttpFetchStatus.Failed;
      state.tasks.rest.patchError = action.payload ?? null;
    });
    builder.addCase(updateSubTaskByTaskId.pending, (state, action) => {
      state.tasks.subTaskRest.postStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(updateSubTaskByTaskId.fulfilled, (state, action) => {
      state.tasks.subTaskRest.postStatus = HttpFetchStatus.Success;
      const task = state.tasks.data?.find((task) => task.id === action.meta.arg.taskId);
      if (task != null) {
        task.subTasks = updateDataList(action.payload, task.subTasks);
      }
      if (state.editedTask?.id === action.meta.arg.taskId) {
        state.editedTask.subTasks = updateDataList(action.payload, state.editedTask!.subTasks ?? []);
      }
    });
    builder.addCase(updateSubTaskByTaskId.rejected, (state, action) => {
      state.tasks.subTaskRest.patchStatus = HttpFetchStatus.Failed;
      state.tasks.subTaskRest.patchError = action.payload ?? null;
    });
    builder.addCase(deleteTaskById.pending, (state, action) => {
      state.tasks.rest.deleteStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(deleteTaskById.fulfilled, (state, action) => {
      state.tasks.rest.deleteStatus = HttpFetchStatus.Success;
      if (action.meta.arg.parentTaskId != null) {
        const parentTask = state.tasks.data?.find((task) => task.id === action.meta.arg.parentTaskId);
        const subTaskIndex = parentTask?.subTasks.findIndex((subTask) => subTask.id === action.payload.id);
        const editedSubTaskIndex = state.editedTask?.subTasks?.findIndex((subTask) => subTask.id === action.payload.id);
        if (subTaskIndex != null && subTaskIndex !== -1) {
          parentTask?.subTasks.splice(subTaskIndex, 1);
        }
        if (editedSubTaskIndex != null && subTaskIndex !== -1 && state.editedTask?.subTasks != null) {
          state.editedTask?.subTasks.splice(editedSubTaskIndex, 1);
        }
      } else {
        state.editedTask = null;
        if (action.payload != null && state.tasks.data != null) {
          const index = state.tasks.data.findIndex((be) => be.id === action.payload.id);
          if (index !== -1) {
            state.tasks.data.splice(index, 1);
          }
        }
      }
    });
    builder.addCase(deleteTaskById.rejected, (state, action) => {
      state.tasks.rest.deleteStatus = HttpFetchStatus.Failed;
      state.tasks.rest.deleteError = action.payload ?? null;
    });

    builder.addCase(getWants.pending, (state, action) => {
      state.wants.rest.getStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(getWants.fulfilled, (state, action) => {
      state.wants.rest.getStatus = HttpFetchStatus.Success;
      state.wants.data = action.payload ?? null;
    });
    builder.addCase(getWants.rejected, (state, action) => {
      state.wants.rest.getStatus = HttpFetchStatus.Failed;
      state.wants.rest.getError = action.payload ?? null;
    });
    builder.addCase(createWant.pending, (state, action) => {
      state.wants.rest.postStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(createWant.fulfilled, (state, action) => {
      state.wants.rest.postStatus = HttpFetchStatus.Success;
      state.editedWant.data = null;
      if (state.wants.data != null) {
        state.wants.data = updateDataList(action.payload, state.wants.data);
      }
    });
    builder.addCase(createWant.rejected, (state, action) => {
      state.wants.rest.postStatus = HttpFetchStatus.Failed;
      state.wants.rest.postError = action.payload ?? null;
    });
    builder.addCase(updateWantById.pending, (state, action) => {
      state.wants.rest.patchStatus = HttpFetchStatus.Loading;
      state.wants.rest.patchId = action.meta.arg.id ?? null;
    });
    builder.addCase(updateWantById.fulfilled, (state, action) => {
      state.wants.rest.patchStatus = HttpFetchStatus.Success;
      state.wants.rest.patchId = null;
      state.editedWant.data = null;
      if (state.wants.data != null) {
        state.wants.data = updateDataList(action.payload, state.wants.data);
      }
    });
    builder.addCase(updateWantById.rejected, (state, action) => {
      state.wants.rest.patchStatus = HttpFetchStatus.Failed;
      state.wants.rest.patchId = null;
      state.wants.rest.patchError = action.payload ?? null;
    });
    builder.addCase(deleteWantById.pending, (state, action) => {
      state.wants.rest.deleteStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(deleteWantById.fulfilled, (state, action) => {
      state.wants.rest.deleteStatus = HttpFetchStatus.Success;
      state.editedWant.data = null;
      if (action.payload != null && state.wants.data != null) {
        const index = state.wants.data.findIndex((be) => be.id === action.payload.id);
        if (index !== -1) {
          state.wants.data.splice(index, 1);
        }
      }
    });
    builder.addCase(deleteWantById.rejected, (state, action) => {
      state.wants.rest.deleteStatus = HttpFetchStatus.Failed;
      state.wants.rest.deleteError = action.payload ?? null;
    });
    builder.addCase(addWantItemById.pending, (state, action) => {
      state.wants.rest.addPostStatus = HttpFetchStatus.Loading;
      state.wants.rest.addPostId = action.meta.arg;
    });
    builder.addCase(addWantItemById.fulfilled, (state, action) => {
      state.wants.rest.addPostStatus = HttpFetchStatus.Success;
      state.wants.rest.addPostId = null;
      if (state.wants.data != null) {
        state.wants.data = updateDataList(action.payload, state.wants.data);
      }
    });
    builder.addCase(addWantItemById.rejected, (state, action) => {
      state.wants.rest.addPostStatus = HttpFetchStatus.Failed;
      state.wants.rest.addPostError = action.payload ?? null;
      state.wants.rest.addPostId = null;
    });
    builder.addCase(planPurchaseWantItemById.pending, (state, action) => {
      state.wants.rest.planPurchasePostStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(planPurchaseWantItemById.fulfilled, (state, action) => {
      state.wants.rest.planPurchasePostStatus = HttpFetchStatus.Success;
      if (state.wants.data != null) {
        state.wants.data = updateDataList(action.payload, state.wants.data);
      }
    });
    builder.addCase(planPurchaseWantItemById.rejected, (state, action) => {
      state.wants.rest.planPurchasePostStatus = HttpFetchStatus.Failed;
      state.wants.rest.planPurchasePostError = action.payload ?? null;
    });

    builder.addCase(getFoodItems.pending, (state, action) => {
      state.foodItems.rest.getStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(getFoodItems.fulfilled, (state, action) => {
      state.foodItems.rest.getStatus = HttpFetchStatus.Success;
      state.foodItems.data = action.payload ?? null;
    });
    builder.addCase(getFoodItems.rejected, (state, action) => {
      state.foodItems.rest.getStatus = HttpFetchStatus.Failed;
      state.foodItems.rest.getError = action.payload ?? null;
    });
    builder.addCase(createFoodItem.pending, (state, action) => {
      state.foodItems.rest.postStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(createFoodItem.fulfilled, (state, action) => {
      state.foodItems.rest.postStatus = HttpFetchStatus.Success;
      state.editedFoodItem = null;
      if (state.foodItems.data != null) {
        state.foodItems.data = updateDataList(action.payload, state.foodItems.data);
      }
    });
    builder.addCase(createFoodItem.rejected, (state, action) => {
      state.foodItems.rest.postStatus = HttpFetchStatus.Failed;
      state.foodItems.rest.postError = action.payload ?? null;
    });
    builder.addCase(updateFoodItemById.pending, (state, action) => {
      state.foodItems.rest.patchStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(updateFoodItemById.fulfilled, (state, action) => {
      state.foodItems.rest.patchStatus = HttpFetchStatus.Success;
      state.editedFoodItem = null;
      if (state.foodItems.data != null) {
        state.foodItems.data = updateDataList(action.payload, state.foodItems.data);
      }
    });
    builder.addCase(updateFoodItemById.rejected, (state, action) => {
      state.foodItems.rest.patchStatus = HttpFetchStatus.Failed;
      state.foodItems.rest.patchError = action.payload ?? null;
    });
    builder.addCase(deleteFoodItemById.pending, (state, action) => {
      state.foodItems.rest.deleteStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(deleteFoodItemById.fulfilled, (state, action) => {
      state.foodItems.rest.deleteStatus = HttpFetchStatus.Success;
      state.editedFoodItem = null;
      if (action.payload != null && state.foodItems.data != null) {
        const index = state.foodItems.data.findIndex((be) => be.id === action.payload.id);
        if (index !== -1) {
          state.foodItems.data.splice(index, 1);
        }
      }
    });
    builder.addCase(deleteFoodItemById.rejected, (state, action) => {
      state.foodItems.rest.deleteStatus = HttpFetchStatus.Failed;
      state.foodItems.rest.deleteError = action.payload ?? null;
    });
    builder.addCase(updateFoodHistoryById.pending, (state, action) => {
      state.foodItems.rest.updateHistoryPostStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(updateFoodHistoryById.fulfilled, (state, action) => {
      state.foodItems.rest.updateHistoryPostStatus = HttpFetchStatus.Success;
      if (state.foodItems.data != null) {
        state.foodItems.data = updateDataList(action.payload, state.foodItems.data);
      }
    });
    builder.addCase(updateFoodHistoryById.rejected, (state, action) => {
      state.foodItems.rest.updateHistoryPostStatus = HttpFetchStatus.Failed;
      state.foodItems.rest.updateHistoryPostError = action.payload ?? null;
    });

    builder.addCase(login.pending, (state, action) => {
      state.user.rest.postStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user.rest.postStatus = HttpFetchStatus.Success;
      state.user.rest.postError = null;
      state.accessToken = action.payload.token;
      localStorage.setItem(accessTokenStorageName, action.payload.token);
      state.user.data = action.payload.user;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.user.rest.postStatus = HttpFetchStatus.Failed;
      state.user.rest.postError = action.payload ?? null;
    });
    builder.addCase(createAccount.pending, (state, action) => {
      state.user.rest.postStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(createAccount.fulfilled, (state, action) => {
      state.user.rest.postStatus = HttpFetchStatus.Success;
      state.user.rest.postError = null;
      state.accessToken = action.payload.token;
      localStorage.setItem(accessTokenStorageName, action.payload.token);
      state.user.data = action.payload.user;
    });
    builder.addCase(createAccount.rejected, (state, action) => {
      state.user.rest.postStatus = HttpFetchStatus.Failed;
      state.user.rest.postError = action.payload ?? null;
    });
    builder.addCase(getUser.pending, (state, action) => {
      state.user.rest.getStatus = HttpFetchStatus.Loading;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user.rest.getStatus = HttpFetchStatus.Success;
      state.user.rest.getError = null;
      state.user.data = action.payload.user;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.user.rest.getStatus = HttpFetchStatus.Failed;
      state.user.rest.getError = action.payload ?? null;
      state.accessToken = null;
      removeToken();
    });
  },
});

export const {
  resetGeneralState,
  localLogin,
  logout,
  resetLoginState,
  resetUserPostStatus,
  resetBudgetEntriesState,
  resetBudgetEntriesPostState,
  resetBudgetEntriesPatchState,
  resetBudgetEntriesDeleteState,
  resetTagsDeleteState,
  resetTagsPatchState,
  resetTagsPostState,
  resetTasksDeleteState,
  resetTasksPatchState,
  resetSubTasksPostState,
  resetTasksPostState,
  resetWantsDeleteState,
  resetWantsPatchState,
  resetWantsPostState,
  resetAddWantItemPostState,
  resetPlanPurchaseWantItemPostState,
  resetFoodItemsDeleteState,
  resetFoodItemsPatchState,
  resetFoodItemsPostState,
  resetFoodHistoryPostState,
  setBudgetDateFilter,
  setEditedEntry,
  setEditedTag,
  setEditedTask,
  setEditedWant,
  setEditedFoodItem,
} = generalSlice.actions;

export default generalSlice.reducer;