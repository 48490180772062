import React from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { useAppSelector } from "../../store";
import { selectUser } from "../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme) => ({}));

const Home: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const user = useAppSelector((state) => selectUser(state));

  return (
    <React.Fragment>
      <Stack>
        <Card>
          <CardContent>
            <Typography variant="h1">{`${t("title-welcome")} ${user.data?.name.split(" ")[0]}`}</Typography>
          </CardContent>
        </Card>
      </Stack>
    </React.Fragment>
  );
};

export default Home;
