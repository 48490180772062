import React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import ValidationResult from "../../../services/validation/common/validation-result";
import Autocomplete from "@mui/material/Autocomplete";

const AutoComplete: React.FC<{
  label: string;
  size?: "small" | "medium";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  value: string;
  minValue?: number;
  multiline?: boolean;
  validationResult?: ValidationResult;
  onChange: (newValue: string) => void;
  onEndIconClick?: () => void;
  options: string[];
}> = ({
  label,
  size = "medium",
  startIcon,
  endIcon,
  multiline,
  value,
  minValue,
  validationResult,
  options,
  onChange,
  onEndIconClick,
}) => {
  const { t } = useTranslation();

  const mouseDownEndIconHandler = (
    event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const inputChangeHandler = (event: React.SyntheticEvent, newInputValue: string) => {
    onChange(newInputValue);
  };

  return (
    <Autocomplete
      freeSolo
      fullWidth
      options={options}
      size={size}
      inputValue={value}
      onInputChange={inputChangeHandler}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="filled"
          multiline={multiline}
          minRows={multiline ? 2 : undefined}
          helperText={
            validationResult !== undefined && validationResult.error !== null ? t(validationResult.error) : undefined
          }
          error={validationResult !== undefined && !validationResult.isValid}
          InputProps={{
            ...params.InputProps,
            inputProps: { ...params.inputProps, min: minValue },
            startAdornment:
              startIcon !== undefined ? <InputAdornment position="start">{startIcon}</InputAdornment> : undefined,
            endAdornment:
              endIcon !== undefined ? (
                <InputAdornment position="end">
                  {onEndIconClick !== undefined ? (
                    <IconButton onClick={onEndIconClick} onMouseDown={mouseDownEndIconHandler} edge="end">
                      {endIcon}
                    </IconButton>
                  ) : (
                    endIcon
                  )}
                </InputAdornment>
              ) : undefined,
          }}
        />
      )}
    />
  );
};

export default AutoComplete;
