import React from "react";
import Typography from "@mui/material/Typography";

const PageNotFound: React.FC = () => {
  return (
    <React.Fragment>
      <Typography>Page not found</Typography>
    </React.Fragment>
  );
};

export default PageNotFound;
