import FilterDateType from "../budgeting/filterDateType";

export enum Currencies {
  CHF = "CHF",
  NZD = "NZD",
  EUR = "EUR",
}

export enum CashflowDirection {
  INCOME = "INCOME",
  EXPENSE = "EXPENSE",
}

export enum EntryType {
  BUDGETING = "BUDGETING",
  PLANNED = "PLANNED",
  UNPLANNED = "UNPLANNED",
}

export enum RepeatEntry {
  NEVER = "NEVER",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export const calculateValueForRepeat = (
  value: number,
  repeatIn: RepeatEntry,
  repeatOut: FilterDateType,
  cashflowDirection: CashflowDirection
) => {
  if (repeatIn === RepeatEntry.NEVER) {
    return value;
  }

  let dailyCost = value;
  switch (repeatIn) {
    case RepeatEntry.WEEKLY:
      dailyCost = value / 7;
      break;
    case RepeatEntry.FORTNIGHTLY:
      dailyCost = value / 14;
      break;
    case RepeatEntry.MONTHLY:
      dailyCost = value / (365 / 12);
      break;
    case RepeatEntry.YEARLY:
      dailyCost = value / 365;
      break;
    default:
      break;
  }
  const directionMultiplier = cashflowDirection === CashflowDirection.EXPENSE ? 1 : -1;
  switch (repeatOut) {
    case FilterDateType.Week:
      return directionMultiplier * dailyCost * 7;
    case FilterDateType.Month:
      return directionMultiplier * dailyCost * (365 / 12);
    case FilterDateType.Year:
      return directionMultiplier * dailyCost * 365;
    default:
      break;
  }

  return directionMultiplier * value;
};
