import React from "react";
import Grid from "@mui/material/Grid";
import { useAppSelector } from "../../../store";
import {
  selectBudgetDateFilter,
  selectBudgetEntriesData,
  selectTimeFilteredBudgetEntries,
} from "../../../store/general/general_slice_selectors";
import { EntryType, calculateValueForRepeat } from "../../../models/enums/entry/CashflowDirection";
import Tag from "../../../models/general/tag";
import TagBarChart from "../charts/TagBarChart";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import CustomSkeleton from "../../misc/CustomSkeleton";

const CashflowTagSummaries: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const entries = useAppSelector((state) => selectBudgetEntriesData(state));
  const timeFilteredEntries = useAppSelector((state) => selectTimeFilteredBudgetEntries(state));
  const {
    rest: { getStatus },
  } = useAppSelector((state) => selectBudgetEntriesData(state));
  const datesFilter = useAppSelector((state) => selectBudgetDateFilter(state));

  if (getStatus === HttpFetchStatus.Idle || getStatus === HttpFetchStatus.Loading) {
    return (
      <Grid container spacing={2}>
        {Array.from(Array(10)).map((_, index) => (
          <CustomSkeleton key={index} gridSize={{ xs: 12 }} height={26} />
        ))}
      </Grid>
    );
  }

  if (entries.data == null || timeFilteredEntries == null || timeFilteredEntries.length === 0) {
    return null;
  }

  const budgetedTags: Tag[] = [];
  entries.data
    .filter((entry) => entry.entryType === EntryType.BUDGETING)
    .forEach((entry) => {
      entry.tags
        .filter((tag) => !budgetedTags.some((t) => t.id === tag.id))
        .forEach((tag) => {
          budgetedTags.push(tag);
        });
    });

  return (
    <Grid container spacing={2}>
      {budgetedTags.map((budgetTag) => (
        <Grid item key={budgetTag.id}>
          <TagBarChart
            color={budgetTag.color}
            label={budgetTag.name}
            maxValue={entries
              .data!.filter(
                (entry) =>
                  entry.entryType === EntryType.BUDGETING &&
                  entry.tags.some((tag) => tag.id === budgetTag.id) &&
                  entry.amountDue != null
              )
              .reduce(
                (acc, entry) =>
                  acc +
                  calculateValueForRepeat(entry.amountDue!, entry.repeat, datesFilter.type, entry.cashflowDirection),
                0
              )}
            value={timeFilteredEntries
              .filter(
                (entry) =>
                  entry.entryType !== EntryType.BUDGETING &&
                  entry.tags.some((tag) => tag.id === budgetTag.id) &&
                  (entry.amountPaid != null || entry.amountDue != null)
              )
              .reduce(
                (acc, entry) =>
                  acc +
                  calculateValueForRepeat(
                    (entry.amountPaid ?? entry.amountDue)!,
                    entry.repeat,
                    datesFilter.type,
                    entry.cashflowDirection
                  ),
                0
              )}
          />
        </Grid>
      ))}
      <Grid item>
        <TagBarChart
          color={theme.palette.grey[700]}
          label={t("unsorted-entry-tag")}
          maxValue={entries
            .data!.filter(
              (entry) => entry.entryType === EntryType.BUDGETING && entry.tags.length === 0 && entry.amountDue != null
            )
            .reduce(
              (acc, entry) =>
                acc +
                calculateValueForRepeat(entry.amountDue!, entry.repeat, datesFilter.type, entry.cashflowDirection),
              0
            )}
          value={timeFilteredEntries
            .filter(
              (entry) =>
                entry.entryType !== EntryType.BUDGETING &&
                entry.tags.length === 0 &&
                (entry.amountPaid != null || entry.amountDue != null)
            )
            .reduce(
              (acc, entry) =>
                acc +
                calculateValueForRepeat(
                  (entry.amountPaid ?? entry.amountDue)!,
                  entry.repeat,
                  datesFilter.type,
                  entry.cashflowDirection
                ),
              0
            )}
        />
      </Grid>
    </Grid>
  );
};

export default CashflowTagSummaries;
