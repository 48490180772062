import React, { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { NavigationRoutes } from "../../UI/MainNavigationBar";
import { useNavigate } from "react-router-dom";
import DatesFilter from "../DatesFilter";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectTimeFilteredBudgetEntries } from "../../../store/general/general_slice_selectors";
import { sortByDate } from "../../../utils/date";
import { selectBudgetEntriesData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { setEditedEntry } from "../../../store/general/general_slice";
import TransactionListCard from "./TransactionListCard";
import { AddIcon } from "../../UI/icons";
import { getBudgetEntries } from "../../../store/general/general_actions";
import { EntryType } from "../../../models/enums/entry/CashflowDirection";

const TransactionsPage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const timeFilteredEntries = useAppSelector((state) => selectTimeFilteredBudgetEntries(state));
  const {
    rest: { patchStatus, getStatus, deleteStatus, postStatus },
  } = useAppSelector((state) => selectBudgetEntriesData(state));

  useEffect(() => {
    if (getStatus === HttpFetchStatus.Idle) {
      dispatch(getBudgetEntries());
    }
  }, [dispatch, getStatus]);

  useEffect(() => {
    if (
      patchStatus === HttpFetchStatus.Success ||
      deleteStatus === HttpFetchStatus.Success ||
      postStatus === HttpFetchStatus.Success
    ) {
      dispatch(setEditedEntry(null));
    }
  }, [deleteStatus, dispatch, patchStatus, postStatus]);

  const budgetBreadcrumbClickHandler = () => {
    navigate(NavigationRoutes.Budget);
  };

  const addTransactionClickHandler = () => {
    dispatch(setEditedEntry({ amountPaid: 0, entryType: EntryType.UNPLANNED }));
  };

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit" onClick={budgetBreadcrumbClickHandler} sx={{ cursor: "pointer" }}>
            {t("navigate-/budget")}
          </Link>
          <Typography color="text.primary">{t("navigate-/budget/transactions")}</Typography>
        </Breadcrumbs>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h1">{t("title-transactions")}</Typography>
          <Button startIcon={<AddIcon />} onClick={addTransactionClickHandler}>
            {t("button-add-new")}
          </Button>
        </Stack>

        <DatesFilter />

        {timeFilteredEntries == null || timeFilteredEntries.length === 0 ? (
          <Alert
            severity="info"
            sx={{ alignItems: "center" }}
            action={
              <Button variant="outlined" sx={{ m: 1 }} startIcon={<AddIcon />} onClick={addTransactionClickHandler}>
                {t("button-add-new")}
              </Button>
            }
          >
            {t("info-no-transaction-entries")}
          </Alert>
        ) : (
          timeFilteredEntries
            .sort((a, b) => sortByDate((a.datePaid ?? a.dateDue)!, (b.datePaid ?? b.dateDue)!))
            .map((entry) => <TransactionListCard entry={entry} key={entry.id} />)
        )}
      </Stack>
    </React.Fragment>
  );
};

export default TransactionsPage;
