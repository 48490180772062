import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  typography: {
    fontFamily: "Roboto",
  },
  palette: {
    primary: { main: "#0E2B55" }, // #21D0B2, #34F5C5
    secondary: { main: "#E6ECF3", light: "#B5B5B5" },

    // background: { default: "#2F455C" },
    background: { default: "#F7F9FB" },
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          // color: ,
          fontSize: "1.225rem",
          fontWeight: "700",
          textTransform: "capitalize",
        },
        h2: {
          // color: "#2B2B2BED",
          fontSize: "1.2rem",
          fontWeight: "bold",
        },
        //       h4: {
        //         color: "#2B2B2BED",
        //         fontSize: "2rem",
        //         fontWeight: "bold",
        //         textTransform: "uppercase",
        //       },
      },
    },

    //   MuiToolbar: {
    //     styleOverrides: {
    //       regular: {
    //         background: "#FFFFFF",
    //       },
    //     },
    //   },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    //   MuiCardActionArea: {
    //     styleOverrides: {
    //       root: {
    //         borderRadius: "4px",
    //       },
    //     },
    //   },
    //   MuiLink: {
    //     styleOverrides: {
    //       root: {
    //         textDecoration: "none",
    //       },
    //     },
    //   },
  },
});
