import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectBudgetEntriesData } from "../../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";
import { EntryType } from "../../../models/enums/entry/CashflowDirection";
import { NavigationRoutes } from "../../UI/MainNavigationBar";
import TransactionListCard from "./TransactionListCard";
import { AddIcon } from "../../UI/icons";
import { setEditedEntry } from "../../../store/general/general_slice";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { getBudgetEntries } from "../../../store/general/general_actions";
import { sortByDate } from "../../../utils/date";
import CustomLineChartCard from "../charts/CustomLineChartCard";
import CustomSkeleton from "../../misc/CustomSkeleton";

const TransactionOverviewPage: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { transactionId } = useParams<{ transactionId: string }>();

  const allEntriesData = useAppSelector((state) => selectBudgetEntriesData(state));
  const entry = allEntriesData.data?.find((e) => e.id === transactionId);
  const budgetedEntries = [
    ...(allEntriesData.data?.filter(
      (e) => e.budgetingEntry === entry?.id && (e.datePaid != null || e.dateDue != null)
    ) ?? []),
  ].sort((a, b) => sortByDate((a.dateDue ?? a.datePaid)!, (b.dateDue ?? b.datePaid)!));

  useEffect(() => {
    if (allEntriesData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getBudgetEntries());
    }
  }, [allEntriesData.rest.getStatus, dispatch]);

  if (
    allEntriesData.rest.getStatus === HttpFetchStatus.Idle ||
    allEntriesData.rest.getStatus === HttpFetchStatus.Loading
  ) {
    return (
      <Grid container spacing={2}>
        <CustomSkeleton gridSize={{ xs: 12 }} height={6} width="32" />
        <CustomSkeleton gridSize={{ xs: 12 }} height={6} width="32" />
        <CustomSkeleton gridSize={{ xs: 12 }} height={12} />
        <CustomSkeleton gridSize={{ xs: 12 }} height={12} />

        <Grid item xs={12} marginTop={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h1">{t("title-transactions")}</Typography>
            <Button startIcon={<AddIcon />} disabled>
              {t("button-add-new")}
            </Button>
          </Stack>
        </Grid>

        <CustomSkeleton gridSize={{ xs: 12 }} height={11} />
        <CustomSkeleton gridSize={{ xs: 12 }} height={11} />
        <CustomSkeleton gridSize={{ xs: 12 }} height={11} />
      </Grid>
    );
  } else if (entry == null) {
    return <Alert severity="error">{t("entry-not-found")}</Alert>;
  }

  const transactionsBreadcrumbClickHandler = () => {
    if (entry.entryType === EntryType.BUDGETING) {
      navigate({ pathname: NavigationRoutes.Budget, search: createSearchParams({ t: "budgeting" }).toString() });
    } else {
      navigate(NavigationRoutes.Transactions);
    }
  };

  const addTransactionClickHandler = () => {
    dispatch(
      setEditedEntry({
        amountDue: entry.amountDue,
        entryType: EntryType.PLANNED,
        cashflowDirection: entry.cashflowDirection,
        budgetingEntry: entry.id,
        currency: entry.currency,
        description: entry.description,
        tags: entry.tags.map((tag) => ({ ...tag })),
      })
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              onClick={transactionsBreadcrumbClickHandler}
              sx={{ cursor: "pointer" }}
            >
              {entry.entryType === EntryType.BUDGETING ? t("title-budgeting") : t("navigate-/budget/transactions")}
            </Link>
            <Typography color="text.primary">{t("entry-single")}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <TransactionListCard entry={entry} clickToEdit />
        </Grid>
        <Grid item xs={12}>
          <CustomLineChartCard
            entries={budgetedEntries.reverse()}
            repeat={entry.repeat}
            expectedValue={entry.amountDue}
            label={t("title-spending-summary-chart")}
          />
        </Grid>
        <Grid item xs={12} container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h1">{t("title-transactions")}</Typography>
              <Button startIcon={<AddIcon />} onClick={addTransactionClickHandler}>
                {t("button-add-new")}
              </Button>
            </Stack>
          </Grid>
          {budgetedEntries.length === 0 ? (
            <Grid item xs={12}>
              <Alert severity="info">{t("no-entries-found-for-budget")}</Alert>
            </Grid>
          ) : (
            budgetedEntries.map((e) => (
              <Grid item xs={12} key={e.id}>
                <TransactionListCard entry={e} />
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TransactionOverviewPage;
