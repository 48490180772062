import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import ValidationResult from "../../../services/validation/common/validation-result";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import FormHelperText from "@mui/material/FormHelperText";

const MultipleSelectInput: React.FC<{
  label: string;
  children: React.ReactNode;
  size?: "small" | "medium";
  values: { id: string; title: string }[];
  validationResult?: ValidationResult;
  onChange: (event: SelectChangeEvent<string[]>) => void;
}> = ({ label, children, size = "medium", values, validationResult, onChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="select-multiple-helper-label" variant="filled">
          {label}
        </InputLabel>
        <Select
          labelId="select-multiple-helper-label"
          multiple
          variant="filled"
          error={validationResult !== undefined && !validationResult.isValid}
          value={values.map((v) => v.id)}
          onChange={onChange}
          input={<FilledInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((s) => {
                const title = values.find((v) => v.id === s)?.title ?? t("unknown-value");
                return <Chip key={s} label={title} />;
              })}
            </Box>
          )}
        >
          {children}
        </Select>
        {validationResult !== undefined && validationResult.error !== null && (
          <FormHelperText sx={{ color: validationResult.isValid === true ? "default" : theme.palette.error.main }}>
            {t(validationResult.error)}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default MultipleSelectInput;
