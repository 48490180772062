import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectFoodItemsData } from "../../../store/general/general_slice_selectors";
import ValidationResult, { isValidationResultArray } from "../../../services/validation/common/validation-result";
import useValidatedInputEffect from "../../../hooks/useValidatedInputEffect";
import {
  validateFoodItemExpiryDate,
  validateFoodItemQuantity,
} from "../../../services/validation/general/food_item_validation";
import useValidatedSelectEffect from "../../../hooks/useValidatedSelectEffect";
import FoodItem from "../../../models/pantry/food-item";
import { DateTime } from "luxon";
import IconButton from "@mui/material/IconButton";
import { AddIcon, MinusIcon } from "../../UI/icons";
import TextInput from "../../misc/forms/TextInput";
import SelectInput from "../../misc/forms/SelectInput";
import FoodUnit from "../../../models/enums/pantry/foodUnit";
import DateInput from "../../misc/forms/DateInput";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import CircularProgress from "@mui/material/CircularProgress";
import { updateFoodHistoryById } from "../../../store/general/general_actions";

const FoodHistoryForm: React.FC<{ foodItem: FoodItem; open: boolean; onClose: () => void }> = ({
  foodItem,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const foodData = useAppSelector((state) => selectFoodItemsData(state));

  const [serverValidationErrors, setServerValidationErrors] = useState<ValidationResult[]>([]);
  const [isExpiryDateSet, setIsExpiryDateSet] = useState(false);
  const [isIncreasingQuantity, setIsIncreasingQuantity] = useState(true);

  useEffect(() => {
    if (isValidationResultArray(foodData.rest.updateHistoryPostError)) {
      setServerValidationErrors(foodData.rest.updateHistoryPostError);
    }
  }, [foodData.rest.patchError, foodData.rest.updateHistoryPostError]);

  const removeServerError = (inputKey: string) => {
    setServerValidationErrors((prevState) =>
      prevState.filter((sv) => !(sv.inputKey !== undefined && sv.inputKey === inputKey))
    );
  };

  const getServerValidationResultOrNull = (inputKey: string) => {
    let serverValidation = serverValidationErrors.find((sv) => sv.inputKey === inputKey);
    return serverValidation !== undefined ? serverValidation : null;
  };

  const {
    value: quantity,
    validationResult: quantityValidationResult,
    valueChangeHandler: quantityChangeHandler,
  } = useValidatedInputEffect<number>(
    0,
    (newQuantity) => validateFoodItemQuantity(newQuantity),
    (_) => () => {},
    getServerValidationResultOrNull("quantity"),
    removeServerError
  );

  const {
    value: unit,
    validationResult: unitValidationResult,
    valueChangeHandler: unitChangeHandler,
  } = useValidatedSelectEffect(
    foodItem.unit,
    (newUnit) => ({ isValid: true, error: null }),
    (_) => () => {},
    getServerValidationResultOrNull("unit"),
    removeServerError
  );

  const [expiryDate, setExpiryDate] = useState<{ value: DateTime; validation: ValidationResult }>({
    value:
      foodItem?.expiryDate != null
        ? DateTime.fromJSDate(new Date(foodItem.expiryDate))
        : DateTime.fromISO(DateTime.now().toString()).toLocal(),
    validation: getServerValidationResultOrNull("expiryDate") ?? {
      ...validateFoodItemExpiryDate(DateTime.fromISO(DateTime.now().toString())),
    },
  });

  const expiryDateChangeHandler = (newExpiryDate: DateTime | null) => {
    if (newExpiryDate) {
      setExpiryDate({ value: newExpiryDate, validation: validateFoodItemExpiryDate(newExpiryDate) });
      removeServerError("expiryDate");
    }
  };

  const toggleIsExpiryDateSetHandler = () => {
    setIsExpiryDateSet((prevState) => !prevState);
  };

  const toggleIsIncreasingQuantityClickHandler = () => {
    setIsIncreasingQuantity((prevState) => !prevState);
  };

  const addHistoryClickHandler = () => {
    dispatch(
      updateFoodHistoryById({
        id: foodItem.id,
        foodItem: {
          creator: foodItem.creator,
          expiryDate: isExpiryDateSet ? expiryDate.value.toJSDate() : null,
          item: foodItem.item,
          quantity: quantity * (isIncreasingQuantity ? 1 : -1),
          unit: unit as FoodUnit,
        },
      })
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h2">{t("title-add-food-history")}</Typography>
          <Stack direction="row" spacing={2}>
            <Box
              sx={(theme) => ({
                height: theme.spacing(7),
                pt: 1,
              })}
            >
              <IconButton
                color={isIncreasingQuantity ? "success" : "error"}
                onClick={toggleIsIncreasingQuantityClickHandler}
              >
                {isIncreasingQuantity ? <AddIcon /> : <MinusIcon />}
              </IconButton>
            </Box>
            <Box flexGrow={1}>
              <TextInput
                label={t("input-label-food-quantity")}
                type="number"
                value={quantity}
                onChange={quantityChangeHandler}
                validationResult={quantityValidationResult}
              />
            </Box>
            <Box sx={(theme) => ({ width: theme.spacing(11) })}>
              <SelectInput
                label={t("input-label-food-item-unit")}
                value={unit}
                onChange={unitChangeHandler}
                validationResult={unitValidationResult}
              >
                {Object.values(FoodUnit).map((u) => (
                  <MenuItem key={u} value={u}>
                    {t("food-unit-" + u)}
                  </MenuItem>
                ))}
              </SelectInput>
            </Box>
          </Stack>

          <Stack spacing={1}>
            <FormControlLabel
              control={<Switch checked={isExpiryDateSet} onChange={toggleIsExpiryDateSetHandler} />}
              label={t("switch-food-item-expiry-date-set")}
            />
            {isExpiryDateSet && (
              <DateInput
                date={expiryDate.value}
                title={t("input-label-food-item-expiry-date") as string}
                dateValidation={expiryDate.validation}
                onDateTimeChange={expiryDateChangeHandler}
              />
            )}
          </Stack>

          <Button
            variant="contained"
            fullWidth
            disabled={foodData.rest.updateHistoryPostStatus === HttpFetchStatus.Loading}
            onClick={addHistoryClickHandler}
          >
            {foodData.rest.updateHistoryPostStatus === HttpFetchStatus.Loading ? (
              <CircularProgress size={24} color="secondary" />
            ) : (
              t("button-add")
            )}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FoodHistoryForm;
