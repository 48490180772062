import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmAbortDialog: React.FC<{
  isOpen: boolean;
  title: string;
  description: string;
  abortButtonText: string;
  confirmButtonText: string;
  onConfirm: () => void;
  onAbort: () => void;
}> = ({ isOpen, title, description, abortButtonText, confirmButtonText, onConfirm, onAbort }) => {
  const abortClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    onAbort();
  };

  const confirmClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    onConfirm();
  };

  const dialogClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onAbort}
      onClick={dialogClickHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={abortClickHandler} color="warning">
          {abortButtonText}
        </Button>
        <Button onClick={confirmClickHandler} color="primary" autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAbortDialog;
