import { DateTime } from "luxon";
import i18n from "../../../i18n";
import ValidationResult from "../common/validation-result";
import { isValidDate } from "../../../utils/date";

export const validateTaskTitle = (title: string): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-task-title"),
  };
};

export const validateTaskDescription = (description: string): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-task-description"),
  };
};

export const validateTaskTags = (tags: string[]): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-task-tags"),
  };
};

export const validateTaskDeadline = (datePaid: DateTime): ValidationResult => {
  if (!isValidDate(datePaid)) {
    return {
      isValid: false,
      error: i18n.t("validationError-date-invalid"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-task-date-due"),
  };
};
