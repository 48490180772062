import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const CustomSkeleton: React.FC<{
  width?: string;
  height: number;
  variant?: "rectangular" | "text";
  gridSize?: { xs: number; md?: number };
}> = ({ width = "100%", variant = "rectangular", height, gridSize }) => {
  const skeletonComponent = (
    <Skeleton
      sx={(theme) => ({
        width: width != null && width.includes("%") ? width : theme.spacing(width),
        height: theme.spacing(height),
        borderRadius: theme.spacing(0.5),
        maxWidth: "100%",
      })}
      variant={variant}
    ></Skeleton>
  );

  if (gridSize != null) {
    return (
      <Grid item xs={gridSize.xs} md={gridSize.md}>
        {skeletonComponent}
      </Grid>
    );
  }

  return skeletonComponent;
};

export default CustomSkeleton;
