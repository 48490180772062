import { DateTime } from "luxon";
import BudgetEntry from "../../models/budget/budget-entry";
import { StateFilterDate } from "../../models/budget/filter-date";
import RestSet, { defaultRestSet, FoodRestSet, WantRestSet } from "../../models/common/api/rest-set";
import Tag from "../../models/general/tag";
import User from "../../models/general/user";
import FilterDateType from "../../models/enums/budgeting/filterDateType";
import Task from "../../models/calendar/task";
import Want from "../../models/common/wants/want";
import FoodItem from "../../models/pantry/food-item";
import TagGroup from "../../models/enums/entry/TagGroup";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";

export const accessTokenStorageName = "accessToken";
export const includePlannedStorageName = "includePlanned";

interface GeneralState {
  user: {
    data: User | null;
    rest: RestSet;
  };
  accessToken: string | null;

  budgetEntries: {
    data: BudgetEntry[] | null;
    rest: RestSet;
  };
  editedEntry: Partial<BudgetEntry> | null;

  wants: {
    data: Want[] | null;
    rest: WantRestSet;
  };
  editedWant: {
    data: Partial<Want> | null;
    tagGroups: TagGroup[];
  };

  foodItems: {
    data: FoodItem[] | null;
    rest: FoodRestSet;
  };
  editedFoodItem: Partial<FoodItem> | null;

  tags: {
    data: Tag[] | null;
    rest: RestSet;
  };
  editedTag: Partial<Tag> | null;

  filters: {
    budgetDateFilter: StateFilterDate;
  };

  tasks: {
    data: Task[] | null;
    rest: RestSet;
    subTaskRest: RestSet;
  };
  editedTask: Partial<Task> | null;
}

export const defaultState = {
  data: null,
  rest: defaultRestSet,
};

export const getDefaultFilterDate = () => {
  const includePlanned = localStorage.getItem(includePlannedStorageName) === "true";
  return { date: DateTime.now().toISO(), type: FilterDateType.Month, includePlanned } as StateFilterDate;
};

export const getInitialGeneralState = (): GeneralState => {
  return {
    user: defaultState,
    budgetEntries: defaultState,
    editedEntry: null,
    tasks: { ...defaultState, subTaskRest: defaultRestSet },
    editedTask: null,
    wants: {
      data: null,
      rest: {
        ...defaultRestSet,
        addPostId: null,
        addPostError: null,
        addPostStatus: HttpFetchStatus.Idle,
        planPurchasePostError: null,
        planPurchasePostStatus: HttpFetchStatus.Idle,
      },
    },
    editedWant: { data: null, tagGroups: [] },
    foodItems: {
      data: null,
      rest: {
        ...defaultRestSet,
        updateHistoryPostError: null,
        updateHistoryPostStatus: HttpFetchStatus.Idle,
      },
    },
    editedFoodItem: null,
    tags: defaultState,
    editedTag: null,
    accessToken: null,
    filters: {
      budgetDateFilter: getDefaultFilterDate(),
    },
  };
};
export type { GeneralState };
