import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectBudgetDateFilter } from "../../store/general/general_slice_selectors";
import { setBudgetDateFilter } from "../../store/general/general_slice";
import { DateTime } from "luxon";
import FilterDateType from "../../models/enums/budgeting/filterDateType";
import { LeftArrow, MonthIcon, RightArrow, TodayIcon, WeekIcon, YearIcon } from "../UI/icons";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  scrollbar: {
    maxWidth: "100%",
    overflow: "auto",
    alignItems: "center",
    "&::-webkit-scrollbar": {
      height: theme.spacing(1.8),
    },
    "&::-webkit-scrollbar-track": {
      borderTop: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[100],
    },
    "&::-webkit-scrollbar-thumb": {
      borderTop: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const DatesFilter: React.FC<{ dateTypeOnly?: boolean; disableYear?: boolean; disablePlannedToggle?: boolean }> = ({
  dateTypeOnly,
  disableYear,
  disablePlannedToggle,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const filterDate = useAppSelector((state) => selectBudgetDateFilter(state));

  useEffect(() => {
    if (disableYear && filterDate.type === FilterDateType.Year) {
      dispatch(setBudgetDateFilter({ type: FilterDateType.Week }));
    }
  }, [disableYear, dispatch, filterDate.type]);

  const setTodayClickHandler = () => {
    dispatch(setBudgetDateFilter({ date: DateTime.now().toISO() }));
  };

  const switchIncludePlannedClickHandler = () => {
    dispatch(setBudgetDateFilter({ includePlanned: !filterDate.includePlanned }));
  };

  const toggleDateFilterType = () => {
    switch (filterDate.type) {
      case FilterDateType.Month:
        if (disableYear) {
          dispatch(setBudgetDateFilter({ type: FilterDateType.Week }));
        } else {
          dispatch(setBudgetDateFilter({ type: FilterDateType.Year }));
        }
        break;
      case FilterDateType.Year:
        dispatch(setBudgetDateFilter({ type: FilterDateType.Week }));
        break;
      case FilterDateType.Week:
        dispatch(setBudgetDateFilter({ type: FilterDateType.Month }));
        break;
      default:
        break;
    }
  };

  const changeDateClickHandler = (direction: 1 | -1) => {
    switch (filterDate.type) {
      case FilterDateType.Month:
        dispatch(setBudgetDateFilter({ date: filterDate.date.plus({ months: direction }).toISO() }));
        break;
      case FilterDateType.Year:
        dispatch(setBudgetDateFilter({ date: filterDate.date.plus({ years: direction }).toISO() }));

        break;
      case FilterDateType.Week:
        dispatch(setBudgetDateFilter({ date: filterDate.date.plus({ weeks: direction }).toISO() }));
        break;
      default:
        break;
    }
  };

  let filterDateTypeIcon;
  let filterDateTypeTooltip;
  let filterDateString = "";

  switch (filterDate.type) {
    case FilterDateType.Month:
      filterDateTypeIcon = <MonthIcon />;
      filterDateTypeTooltip = t("tooltip-filter-month");
      filterDateString = `${t("month-" + filterDate.date.month)} ${filterDate.date.year}`;
      break;
    case FilterDateType.Year:
      filterDateTypeIcon = <YearIcon />;
      filterDateTypeTooltip = t("tooltip-filter-year");
      filterDateString = filterDate.date.year.toString();
      break;
    default:
      filterDateTypeIcon = <WeekIcon />;
      filterDateTypeTooltip = t("tooltip-filter-week");
      filterDateString = `${filterDate.date.startOf("week").toLocaleString()} - ${filterDate.date
        .endOf("week")
        .toLocaleString()}`;
      break;
  }
  return (
    <Stack direction="row" spacing={2} className={classes.scrollbar}>
      <Box flexGrow={1} />
      {disablePlannedToggle !== true && (
        <FormControlLabel
          control={<Switch checked={filterDate.includePlanned} onChange={switchIncludePlannedClickHandler} />}
          label={t("switch-show-planned-transactions")}
        />
      )}
      {!dateTypeOnly && (
        <React.Fragment>
          <IconButton color="primary" onClick={() => changeDateClickHandler(-1)}>
            <LeftArrow />
          </IconButton>
          <Typography>{filterDateString}</Typography>
          <IconButton color="primary" onClick={() => changeDateClickHandler(1)}>
            <RightArrow />
          </IconButton>
          <Tooltip title={t("tooltip-today")}>
            <IconButton
              color={
                DateTime.now().startOf(filterDate.type).toMillis() ===
                filterDate.date.startOf(filterDate.type).toMillis()
                  ? "primary"
                  : "default"
              }
              onClick={setTodayClickHandler}
            >
              <TodayIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )}
      <Tooltip title={filterDateTypeTooltip}>
        <IconButton color="primary" onClick={toggleDateFilterType}>
          {filterDateTypeIcon}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default DatesFilter;
