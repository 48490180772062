import i18n from "../../../i18n";

enum FoodUnit {
  GRAMS = "GRAMS",
  KILOGRAMS = "KILOGRAMS",
  LITERS = "LITERS",
  MILLILITERS = "MILLILITERS",
  SINGLE_UNIT = "SINGLE_UNIT",
}

export default FoodUnit;

export const quantityAndUnitToString = (quantity: number, unit: FoodUnit) => {
  return quantity.toString() + (unit !== FoodUnit.SINGLE_UNIT ? i18n.t("food-unit-" + unit) : "");
};
