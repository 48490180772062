import React from "react";
import Tag from "../../models/general/tag";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "../../store";
import { setEditedTag } from "../../store/general/general_slice";

const TagCard: React.FC<{ tag: Tag }> = ({ tag }) => {
  const dispatch = useAppDispatch();

  const editTagClickHandler = () => {
    dispatch(setEditedTag(tag));
  };

  return (
    <React.Fragment>
      <Card>
        <CardActionArea onClick={editTagClickHandler}>
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={(theme) => ({
                    height: theme.spacing(2),
                    width: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: tag.color,
                  })}
                />
                <Typography variant="h2">{tag.name}</Typography>
              </Stack>
              <Typography variant="body2">{tag.description}</Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
};

export default TagCard;
