import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import ValidationResult from "../../../services/validation/common/validation-result";
import i18n from "../../../i18n";

const DateTimeInput: React.FC<{
  datetime: DateTime;
  title: string;
  dateValidation: ValidationResult;
  minDateTime?: DateTime;
  onDateTimeChange: (datetime: DateTime | null) => void;
}> = ({ datetime, title, dateValidation, minDateTime, onDateTimeChange }) => {
  return (
    <Stack gap={0} sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
        <DateTimePicker
          value={datetime}
          label={title}
          slotProps={{ textField: { variant: "filled" } }}
          onChange={onDateTimeChange}
          minDateTime={minDateTime}
          format="dd/MM/yyyy HH:mm"
        />
      </LocalizationProvider>
      {dateValidation.error && (
        <FormHelperText
          sx={(theme) => ({ paddingLeft: 2, color: dateValidation.isValid ? undefined : theme.palette.error.main })}
        >
          {dateValidation.error}
        </FormHelperText>
      )}
    </Stack>
  );
};

export default DateTimeInput;
