import { DateTime } from "luxon";
import i18n from "../../../i18n";
import ValidationResult from "../common/validation-result";
import { CashflowDirection } from "../../../models/enums/entry/CashflowDirection";
import { isValidDate } from "../../../utils/date";

export const validateBudgetEntryAmountPaid = (entryAmount: string): ValidationResult => {
  if (!parseFloat(entryAmount) && entryAmount !== "0") {
    return {
      isValid: false,
      error: i18n.t("validationError-invalid-number"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-budgetEntryAmountPaid-required"),
  };
};

export const validateBudgetEntryAmountDue = (entryAmount: string): ValidationResult => {
  if (!parseFloat(entryAmount) && entryAmount !== "0") {
    return {
      isValid: false,
      error: i18n.t("validationError-invalid-number"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-budgetEntryAmountDue-required"),
  };
};

export const validateDescription = (description: string, cashflowDirection: CashflowDirection): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-description-required-" + cashflowDirection),
  };
};

export const validateDatePaid = (datePaid: DateTime): ValidationResult => {
  if (!isValidDate(datePaid)) {
    return {
      isValid: false,
      error: i18n.t("validationError-datePaid-invalid"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-datePaid-required"),
  };
};

export const validateDateDue = (datePaid: DateTime): ValidationResult => {
  if (!isValidDate(datePaid)) {
    return {
      isValid: false,
      error: i18n.t("validationError-datePaid-invalid"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-datePaid-required"),
  };
};

export const validateTags = (tags: string[]): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-tags"),
  };
};
