import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BudgetEntry from "../../../models/budget/budget-entry";
import { useTranslation } from "react-i18next";
import { EntryType } from "../../../models/enums/entry/CashflowDirection";
import { dateToStringFormat } from "../../../utils/date";
import TextWithLabel from "../../misc/TextWithLabel";
import NextPlannedTransaction from "./NextPlannedTransaction";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../UI/MainNavigationBar";
import { setEditedEntry } from "../../../store/general/general_slice";
import { useAppDispatch } from "../../../store";
import { Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  scrollbar: {
    padding: theme.spacing(1),
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const TransactionListCard: React.FC<{ entry: BudgetEntry; clickToEdit?: boolean }> = ({ entry, clickToEdit }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const matchesMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const cardClickHandler = () => {
    if (entry.entryType !== EntryType.BUDGETING || clickToEdit === true) {
      dispatch(setEditedEntry(entry));
    } else {
      navigate(`${NavigationRoutes.Transactions}/${entry.id}`);
    }
  };

  return (
    <React.Fragment>
      <Card sx={(theme) => ({ backgroundColor: entry.dateArchived != null ? theme.palette.grey[300] : "white" })}>
        <CardActionArea onClick={cardClickHandler}>
          <CardContent className={classes.scrollbar}>
            <Box>
              <Stack direction="row" spacing={{ xs: 1, md: 8 }} alignItems="center">
                <Box>
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography>{t("currency-" + entry.currency)}</Typography>
                    {entry.amountPaid != null && entry.datePaid != null && (
                      <Typography variant="h1" sx={(theme) => ({ width: theme.spacing(20) })} noWrap>
                        {entry.amountPaid.toFixed(2)}
                      </Typography>
                    )}
                    {entry.amountDue != null && entry.datePaid == null && (
                      <Typography variant="h1" sx={(theme) => ({ width: theme.spacing(20) })} noWrap>
                        {entry.amountDue.toFixed(2)}
                      </Typography>
                    )}
                  </Stack>
                </Box>
                {entry.entryType === EntryType.PLANNED && entry.dateDue != null && (
                  <TextWithLabel
                    label={t("input-label-dateDue")}
                    text={dateToStringFormat(entry.dateDue, matchesMdDown)}
                  />
                )}
                {entry.datePaid != null && (
                  <TextWithLabel
                    label={t("input-label-datePaid")}
                    text={dateToStringFormat(entry.datePaid, matchesMdDown)}
                  />
                )}
                <Box flexGrow={1} minWidth="116px">
                  <TextWithLabel label={t("input-label-description")} text={entry.description} />
                </Box>
                {entry.entryType !== EntryType.PLANNED && entry.tags.length > 0 && (
                  <Box>
                    {entry.tags.map((tag) => (
                      <Chip label={tag.name} sx={{ backgroundColor: tag.color }} key={tag.id} />
                    ))}
                  </Box>
                )}
              </Stack>
              {entry.entryType === EntryType.BUDGETING && (
                <Stack direction="row" alignItems="center" spacing={8}>
                  <TextWithLabel text={t("repeats-" + entry.repeat)} label={t("input-label-entry-repeats")} />
                  <NextPlannedTransaction entry={entry} />
                </Stack>
              )}
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
};

export default TransactionListCard;
