import React from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  selectBudgetEntriesData,
  selectTimeFilteredBudgetEntries,
} from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { CashflowDirection, EntryType } from "../../../models/enums/entry/CashflowDirection";
import { AddIcon } from "../../UI/icons";
import { setEditedEntry } from "../../../store/general/general_slice";
import AmountDescriptionCard from "../../misc/AmountDescriptionCard";
import CustomSkeleton from "../../misc/CustomSkeleton";

const CashflowOverviewCard: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const timeFilteredEntries = useAppSelector((state) => selectTimeFilteredBudgetEntries(state));
  const {
    rest: { getStatus },
  } = useAppSelector((state) => selectBudgetEntriesData(state));

  const addNewEntryClickHandler = () => {
    dispatch(setEditedEntry({ amountPaid: 0, entryType: EntryType.UNPLANNED }));
  };

  if (getStatus === HttpFetchStatus.Idle || getStatus === HttpFetchStatus.Loading) {
    return <CustomSkeleton height={12} />;
  }

  let netCredit = 0;
  if (timeFilteredEntries !== null) {
    netCredit =
      timeFilteredEntries
        .filter(
          (entry) =>
            entry.cashflowDirection === CashflowDirection.INCOME &&
            (entry.amountPaid != null || entry.amountDue != null)
        )
        .reduce((acc, entry) => acc + (entry.amountPaid ?? entry.amountDue)!, 0) -
      timeFilteredEntries
        .filter(
          (entry) =>
            entry.cashflowDirection === CashflowDirection.EXPENSE &&
            (entry.amountPaid != null || entry.amountDue != null)
        )
        .reduce((acc, entry) => acc + (entry.amountPaid ?? entry.amountDue)!, 0);
  }

  if (timeFilteredEntries == null) {
    return <Alert severity="error">{t("error-loading-budget-entries")}</Alert>;
  } else if (timeFilteredEntries.length === 0) {
    return (
      <Alert
        severity="info"
        sx={{ alignItems: "center" }}
        action={
          <Button variant="outlined" sx={{ m: 1 }} startIcon={<AddIcon />} onClick={addNewEntryClickHandler}>
            {t("button-add-new")}
          </Button>
        }
      >
        {t("info-no-transaction-entries")}
      </Alert>
    );
  }

  return (
    <AmountDescriptionCard
      label={t("label-net-credit")}
      currency={t("currency-" + timeFilteredEntries[0].currency)}
      amount={netCredit.toFixed(2)}
      description={netCredit > 0 ? t("congrats-message") : t("overbudget-message")}
      amountColor={netCredit > 0 ? "green" : "error"}
    />
  );
};

export default CashflowOverviewCard;
