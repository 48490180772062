const getValueIntensityColor = (value: number, maxValue: number): string => {
  if (value >= maxValue) {
    // Max or over max value, return red
    return "#ff0000"; // Red
  }

  // Calculate the ratio of the value to the maximum value
  const ratio = value / maxValue;

  // If the value is less than 55% of max, return green
  if (ratio <= 0.55) {
    return "#00ff00"; // Green
  }

  // Calculate color intensity
  // Convert the range 0.55 - 1 to 0 - 1 for the green to red transition
  const adjustedRatio = (ratio - 0.55) / (1 - 0.55);

  // Calculate green to red transition
  const red = Math.floor(adjustedRatio * 255);
  const green = 255 - red;

  return `rgb(${red},${green},0)`;
};

export default getValueIntensityColor;
