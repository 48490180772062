import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../UI/MainNavigationBar";
import { TagsIcon, TransactionsIcon } from "../../UI/icons";
import DatesFilter from "../DatesFilter";
import Grid from "@mui/material/Grid";

const CashflowTopActionsBar: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const transactionsLinkClickHandler = () => {
    navigate(NavigationRoutes.Transactions);
  };

  const tagsLinkClickHandler = () => {
    navigate(NavigationRoutes.Tags);
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={4}>
        <Stack direction="row" spacing={2}>
          <Button startIcon={<TransactionsIcon />} onClick={transactionsLinkClickHandler}>
            {t("navigate-/budget/transactions")}
          </Button>
          <Button startIcon={<TagsIcon />} onClick={tagsLinkClickHandler}>
            {t("navigate-/tags")}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12} md={8} justifyContent="flex-end">
        <DatesFilter />
      </Grid>
    </Grid>
  );
};

export default CashflowTopActionsBar;
