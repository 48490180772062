interface Tag {
  id: string;
  name: string;
  description: string;
  color: TagColor;
  dateCreated: string;
  tagGroups: string[];
}

export default Tag;

export enum TagColor {
  "LIGHT_GRAY" = "#D3D3D3",
  "SKY_BLUE" = "#87CEEB",
  "PALE_BLUE" = "#ADD8E6",
  "NAVY_BLUE" = "#000080",
  "MINT_GREEN" = "#98FF98",
  "TEAL" = "#008080",
  "CORAL" = "#FF6F61",
  "PEACH" = "#FFDAB9",
  "LAVENDER" = "#E6E6FA",
  "CHARCOAL_GRAY" = "#36454F",
  "SOFT_PINK" = "#FFC0CB",
  "SAND" = "#F4A460",
}
