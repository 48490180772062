import BudgetEntry from "../../models/budget/budget-entry";
import Task from "../../models/calendar/task";
import Want from "../../models/common/wants/want";
import Tag from "../../models/general/tag";
import User, { CreateAccountDetails, LoginDetails } from "../../models/general/user";
import FoodItem from "../../models/pantry/food-item";
import { currentVersionGeneralManagement, generalManagementApiInstance } from "./axios-base";

class GeneralDataService {
  async login(loginDetails: LoginDetails): Promise<{ token: string; user: User }> {
    const response = await generalManagementApiInstance.post(`/${currentVersionGeneralManagement}/login`, loginDetails);
    return response.data;
  }

  async createAccount(createAccountDetails: CreateAccountDetails): Promise<{ token: string; user: User }> {
    const response = await generalManagementApiInstance.post(
      `/${currentVersionGeneralManagement}/create-account`,
      createAccountDetails
    );
    return response.data;
  }

  async getUser(token: string): Promise<{ user: User }> {
    const response = await generalManagementApiInstance.get(`/${currentVersionGeneralManagement}/get-user/${token}`);
    return response.data;
  }

  async getBudgetEntries(filters: string): Promise<BudgetEntry[]> {
    const response = await generalManagementApiInstance.get<BudgetEntry[]>(
      `/${currentVersionGeneralManagement}/budget-entries${filters}/`
    );
    return response.data;
  }

  async createBudgetEntry(entry: Partial<BudgetEntry>): Promise<BudgetEntry> {
    const response = await generalManagementApiInstance.post<BudgetEntry>(
      `/${currentVersionGeneralManagement}/budget-entries/`,
      entry
    );
    return response.data;
  }

  async updateBudgetEntryById(entry: Partial<BudgetEntry>): Promise<BudgetEntry> {
    const response = await generalManagementApiInstance.patch<BudgetEntry>(
      `/${currentVersionGeneralManagement}/budget-entries/${entry.id}/`,
      entry
    );
    return response.data;
  }

  async deleteBudgetEntryById(entryId: string): Promise<{ id: string }> {
    const response = await generalManagementApiInstance.delete<{ id: string }>(
      `/${currentVersionGeneralManagement}/budget-entries/${entryId}/`
    );
    return response.data;
  }

  async getTags(filters: string): Promise<Tag[]> {
    const response = await generalManagementApiInstance.get<Tag[]>(
      `/${currentVersionGeneralManagement}/tags${filters}/`
    );
    return response.data;
  }

  async createTag(entry: Partial<Tag>): Promise<Tag> {
    const response = await generalManagementApiInstance.post<Tag>(`/${currentVersionGeneralManagement}/tags/`, entry);
    return response.data;
  }

  async updateTagById(tag: Partial<Tag>): Promise<Tag> {
    const response = await generalManagementApiInstance.patch<Tag>(
      `/${currentVersionGeneralManagement}/tags/${tag.id}/`,
      tag
    );
    return response.data;
  }

  async deleteTagById(tagId: string): Promise<{ id: string }> {
    const response = await generalManagementApiInstance.delete<{ id: string }>(
      `/${currentVersionGeneralManagement}/tags/${tagId}/`
    );
    return response.data;
  }

  async getTasks(filters: string): Promise<Task[]> {
    const response = await generalManagementApiInstance.get<Task[]>(
      `/${currentVersionGeneralManagement}/tasks${filters}/`
    );
    return response.data;
  }

  async createTask(task: Partial<Task>): Promise<Task> {
    const response = await generalManagementApiInstance.post<Task>(`/${currentVersionGeneralManagement}/tasks/`, task);
    return response.data;
  }

  async updateTaskById(task: Partial<Task>): Promise<Task> {
    const response = await generalManagementApiInstance.patch<Task>(
      `/${currentVersionGeneralManagement}/tasks/${task.id}/`,
      task
    );
    return response.data;
  }

  async updateSubTaskByTaskId(taskId: string, subTask: Partial<Task>): Promise<Task> {
    const response = await generalManagementApiInstance.post<Task>(
      `/${currentVersionGeneralManagement}/tasks/${taskId}/subtasks/`,
      subTask
    );
    return response.data;
  }

  async deleteTaskById(taskId: string): Promise<{ id: string }> {
    const response = await generalManagementApiInstance.delete<{ id: string }>(
      `/${currentVersionGeneralManagement}/tasks/${taskId}/`
    );
    return response.data;
  }

  async getWants(filters: string): Promise<Want[]> {
    const response = await generalManagementApiInstance.get<Want[]>(
      `/${currentVersionGeneralManagement}/wants${filters}/`
    );
    return response.data;
  }

  async createWant(want: Partial<Want>): Promise<Want> {
    const response = await generalManagementApiInstance.post<Want>(`/${currentVersionGeneralManagement}/wants/`, want);
    return response.data;
  }

  async updateWantById(want: Partial<Want>): Promise<Want> {
    const response = await generalManagementApiInstance.patch<Want>(
      `/${currentVersionGeneralManagement}/wants/${want.id}/`,
      want
    );
    return response.data;
  }

  async deleteWantById(wantId: string): Promise<{ id: string }> {
    const response = await generalManagementApiInstance.delete<{ id: string }>(
      `/${currentVersionGeneralManagement}/wants/${wantId}/`
    );
    return response.data;
  }

  async addWantItemById(wantId: string): Promise<Want> {
    const response = await generalManagementApiInstance.post<Want>(
      `/${currentVersionGeneralManagement}/wants/${wantId}/add-item/`
    );
    return response.data;
  }

  async planPurchaseWantItemById(wantId: string, entry: Partial<BudgetEntry>): Promise<Want> {
    const response = await generalManagementApiInstance.post<Want>(
      `/${currentVersionGeneralManagement}/wants/${wantId}/plan-purchase/`,
      entry
    );
    return response.data;
  }

  async getFoodItems(filters: string): Promise<FoodItem[]> {
    const response = await generalManagementApiInstance.get<FoodItem[]>(
      `/${currentVersionGeneralManagement}/pantry${filters}/`
    );
    return response.data;
  }

  async createFoodItem(foodItem: Partial<FoodItem>): Promise<FoodItem> {
    const response = await generalManagementApiInstance.post<FoodItem>(
      `/${currentVersionGeneralManagement}/pantry/`,
      foodItem
    );
    return response.data;
  }

  async updateFoodItemById(foodItem: Partial<FoodItem>): Promise<FoodItem> {
    const response = await generalManagementApiInstance.patch<FoodItem>(
      `/${currentVersionGeneralManagement}/pantry/${foodItem.id}/`,
      foodItem
    );
    return response.data;
  }

  async deleteFoodItemById(foodItemId: string): Promise<{ id: string }> {
    const response = await generalManagementApiInstance.delete<{ id: string }>(
      `/${currentVersionGeneralManagement}/pantry/${foodItemId}/`
    );
    return response.data;
  }

  async updateFoodHistoryById(id: string, foodItem: Partial<FoodItem>): Promise<FoodItem> {
    const response = await generalManagementApiInstance.post<FoodItem>(
      `/${currentVersionGeneralManagement}/pantry/${id}/history/`,
      foodItem
    );
    return response.data;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GeneralDataService();
