import React from "react";
import Typography from "@mui/material/Typography";

const GoalsPage: React.FC = () => {
  return (
    <React.Fragment>
      <Typography variant="h1">Goals</Typography>
      <Typography>Place to set goals and manage habits (habits being steps to achieving goals)</Typography>
      <Typography>
        Habit Customization: Lets users create and customize habits with specific frequencies and reminders. Streak
        Tracking: Encourages users to maintain habits by tracking consecutive days completed. Goal Setting: Users can
        set long-term goals related to their habits for additional motivation. Reminders and Notifications: Sends timely
        reminders to ensure users perform their habits. Accountability Partners: Connects users with friends or
        community members for mutual encouragement and accountability. Reward System: Rewards users for habit
        consistency, which can be as simple as positive feedback or as complex as a points system. Data Visualization:
        Shows progress over time through charts and calendars. Journaling Feature: Allows users to journal about their
        habit progress and reflect on their journey. Educational Content: Provides tips and articles to help users
        understand the science of habit formation. Custom Challenges: Users can join or create challenges for a group to
        foster a sense of community.
      </Typography>
    </React.Fragment>
  );
};

export default GoalsPage;
