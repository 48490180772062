import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import TextInput from "../../misc/forms/TextInput";
import { useTranslation } from "react-i18next";
import useValidatedInputEffect from "../../../hooks/useValidatedInputEffect";
import ValidationResult, { isValidationResultArray } from "../../../services/validation/common/validation-result";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectFoodItemsData, selectTagsData, selectWantsData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import {
  createWant,
  deleteWantById,
  getFoodItems,
  getTags,
  getWants,
  updateWantById,
} from "../../../store/general/general_actions";
import { dateToStringFormat } from "../../../utils/date";
import TextWithLabel from "../../misc/TextWithLabel";
import Want from "../../../models/common/wants/want";
import {
  validateWantCost,
  validateWantDescription,
  validateWantQuantity,
  validateWantTags,
  validateWantTitle,
} from "../../../services/validation/general/want_validation";
import useValidatedMultipleSelectEffect from "../../../hooks/useValidatedMultipleSelectEffect";
import TagGroup from "../../../models/enums/entry/TagGroup";
import TagSelect from "../../misc/forms/TagSelect";
import FoodUnit from "../../../models/enums/pantry/foodUnit";
import useValidatedSelectEffect from "../../../hooks/useValidatedSelectEffect";
import SelectInput from "../../misc/forms/SelectInput";
import AutoComplete from "../../misc/forms/AutoComplete";

const WantForm: React.FC<{
  want: Partial<Want>;
  tagGroups: TagGroup[];
  onClose: () => void;
}> = ({ want, tagGroups, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const wantsData = useAppSelector((state) => selectWantsData(state));
  const tagsData = useAppSelector((state) => selectTagsData(state));
  const foodData = useAppSelector((state) => selectFoodItemsData(state));

  const [serverValidationErrors, setServerValidationErrors] = useState<ValidationResult[]>([]);
  const [isPantryItem, setIsPantryItem] = useState(want.pantryItem === true);

  useEffect(() => {
    if (isValidationResultArray(wantsData.rest.postError)) {
      setServerValidationErrors(wantsData.rest.postError);
    } else if (isValidationResultArray(wantsData.rest.patchError)) {
      setServerValidationErrors(wantsData.rest.patchError);
    }
  }, [wantsData.rest.patchError, wantsData.rest.postError]);

  useEffect(() => {
    if (wantsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getWants());
    }
  }, [wantsData.rest.getStatus, dispatch]);

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [tagsData.rest.getStatus, dispatch]);

  useEffect(() => {
    if (foodData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getFoodItems());
    }
  }, [dispatch, foodData.rest.getStatus]);

  const removeServerError = (inputKey: string) => {
    setServerValidationErrors((prevState) =>
      prevState.filter((sv) => !(sv.inputKey !== undefined && sv.inputKey === inputKey))
    );
  };

  const getServerValidationResultOrNull = (inputKey: string) => {
    let serverValidation = serverValidationErrors.find((sv) => sv.inputKey === inputKey);
    return serverValidation !== undefined ? serverValidation : null;
  };

  const {
    value: wantTitle,
    validationResult: wantTitleValidationResult,
    valueChangeHandler: wantTitleChangeHandler,
  } = useValidatedInputEffect<string>(
    want.title ?? "",
    (newTitle) => validateWantTitle(newTitle),
    (_) => () => {},
    getServerValidationResultOrNull("title"),
    removeServerError
  );

  const {
    value: wantDescription,
    validationResult: wantDescriptionValidationResult,
    valueChangeHandler: wantDescriptionChangeHandler,
  } = useValidatedInputEffect<string>(
    want.description ?? "",
    (newDescription) => validateWantDescription(newDescription),
    (_) => () => {},
    getServerValidationResultOrNull("description"),
    removeServerError
  );

  const {
    value: tags,
    validationResult: tagsValidationResult,
    valueChangeHandler: tagsChangeHandler,
  } = useValidatedMultipleSelectEffect(
    want?.tags != null ? want.tags.map((tag) => tag.id) : [],
    (tags) => validateWantTags(tags),
    (_) => () => {},
    getServerValidationResultOrNull("tags"),
    removeServerError
  );

  const {
    value: cost,
    validationResult: costValidationResult,
    valueChangeHandler: costChangeHandler,
  } = useValidatedInputEffect<number>(
    want?.cost ?? 0,
    (newCost) => validateWantCost(newCost),
    (_) => () => {},
    getServerValidationResultOrNull("cost"),
    removeServerError
  );

  const {
    value: quantity,
    validationResult: quantityValidationResult,
    valueChangeHandler: quantityChangeHandler,
  } = useValidatedInputEffect<number>(
    want?.quantity ?? 0,
    (newQuantity) => validateWantQuantity(newQuantity),
    (_) => () => {},
    getServerValidationResultOrNull("quantity"),
    removeServerError
  );

  const {
    value: unit,
    validationResult: unitValidationResult,
    valueChangeHandler: unitChangeHandler,
  } = useValidatedSelectEffect(
    want?.unit ?? FoodUnit.SINGLE_UNIT,
    (newUnit) => ({ isValid: true, error: null }),
    (_) => () => {},
    getServerValidationResultOrNull("unit"),
    removeServerError
  );

  const pantryItemSwitchClickHandler = () => {
    setIsPantryItem((prevState) => !prevState);
  };

  const addWantClickHandler = () => {
    dispatch(
      createWant({
        title: wantTitle,
        description: wantDescription.length === 0 ? null : wantDescription,
        tags: tagsData.data?.filter((t) => tags.some((tId) => tId === t.id)),
        pantryItem: isPantryItem,
        cost: cost === 0 ? null : cost,
        quantity,
        unit: unit as FoodUnit,
      })
    );
  };

  const updateWantClickHandler = () => {
    if (want.id != null) {
      dispatch(
        updateWantById({
          id: want.id,
          title: wantTitle,
          description: wantDescription,
          tags: tagsData.data?.filter((t) => tags.some((tId) => tId === t.id)) ?? want.tags,
          pantryItem: isPantryItem,
          cost: cost === 0 ? null : cost,
          quantity,
          unit: unit as FoodUnit,
        })
      );
    }
  };

  const deleteWantClickHandler = () => {
    if (want.id != null) {
      dispatch(deleteWantById(want.id));
    }
  };

  const closeFormClickHandler = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog open onClose={closeFormClickHandler} fullWidth maxWidth="sm">
        <DialogContent>
          <Box>
            <Stack spacing={2}>
              <Typography variant="h2">{want.id == null ? t("title-add-want") : t("title-edit-want")}</Typography>

              {isPantryItem ? (
                <AutoComplete
                  label={t("input-label-title")}
                  value={wantTitle}
                  onChange={wantTitleChangeHandler}
                  validationResult={wantTitleValidationResult}
                  options={(foodData.data ?? []).map((item) => item.item)}
                />
              ) : (
                <TextInput
                  label={t("input-label-title")}
                  type="string"
                  value={wantTitle}
                  onChange={wantTitleChangeHandler}
                  validationResult={wantTitleValidationResult}
                />
              )}
              <TextInput
                label={t("input-label-description")}
                type="string"
                multiline
                value={wantDescription}
                onChange={wantDescriptionChangeHandler}
                validationResult={wantDescriptionValidationResult}
              />
              {!tagGroups.includes(TagGroup.Pantry) && (
                <FormControlLabel
                  control={<Switch checked={isPantryItem} onChange={pantryItemSwitchClickHandler} />}
                  label={t("input-label-want-pantry-item")}
                />
              )}
              <TagSelect
                tagIds={tags}
                tagGroups={tagGroups}
                tagsValidationResult={tagsValidationResult}
                onTagsChange={tagsChangeHandler}
              />
              <TextInput
                label={t("input-label-want-cost")}
                type="number"
                value={cost}
                onChange={costChangeHandler}
                validationResult={costValidationResult}
              />

              <Stack direction="row" spacing={2}>
                <Box flexGrow={1}>
                  <TextInput
                    label={t("input-label-food-quantity")}
                    type="number"
                    value={quantity}
                    onChange={quantityChangeHandler}
                    validationResult={quantityValidationResult}
                  />
                </Box>
                <Box sx={(theme) => ({ width: theme.spacing(11) })}>
                  <SelectInput
                    label={t("input-label-want-unit")}
                    value={unit}
                    onChange={unitChangeHandler}
                    validationResult={unitValidationResult}
                  >
                    {Object.values(FoodUnit).map((u) => (
                      <MenuItem key={u} value={u}>
                        {t("food-unit-" + u)}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Box>
              </Stack>

              {want.dateCreated != null && (
                <TextWithLabel text={dateToStringFormat(want.dateCreated)} label={t("input-label-want-date-created")} />
              )}

              <Button
                variant="contained"
                fullWidth
                disabled={
                  wantsData.rest.postStatus === HttpFetchStatus.Loading ||
                  wantsData.rest.patchStatus === HttpFetchStatus.Loading
                }
                onClick={want.id == null ? addWantClickHandler : updateWantClickHandler}
              >
                {wantsData.rest.postStatus === HttpFetchStatus.Loading ||
                wantsData.rest.patchStatus === HttpFetchStatus.Loading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : want.id == null ? (
                  t("button-add")
                ) : (
                  t("button-save")
                )}
              </Button>
              {want.id != null && (
                <Button
                  variant="text"
                  fullWidth
                  size="small"
                  color="error"
                  onClick={deleteWantClickHandler}
                  disabled={wantsData.rest.deleteStatus === HttpFetchStatus.Loading}
                >
                  {wantsData.rest.deleteStatus === HttpFetchStatus.Loading ? (
                    <CircularProgress size={24} color="error" />
                  ) : (
                    t("button-delete")
                  )}
                </Button>
              )}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default WantForm;
