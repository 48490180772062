import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import CashflowTopActionsBar from "./CashflowTopActionsBar";
import CashflowOverviewCard from "./CashflowOverviewCard";
import CashflowPieCharts from "./CashflowPieCharts";
import CashflowTagSummaries from "./CashflowTagSummaries";

const CashflowOutlook: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1">{t("title-outlook")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <CashflowTopActionsBar />
      </Grid>
      <Grid item xs={12}>
        <CashflowOverviewCard />
      </Grid>
      <CashflowPieCharts />
      <Grid item xs={12}>
        <CashflowTagSummaries />
      </Grid>
    </Grid>
  );
};

export default CashflowOutlook;
