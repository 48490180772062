import { useEffect, useRef, useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import ValidationResult from "../services/validation/common/validation-result";

const useValidatedSelectEffect = (
  defaultValue: string,
  validateValue: (valueToValidate: string) => ValidationResult,
  valueChangeEffectFunction: (changedValue: string) => void,
  serverValidationResult: ValidationResult | null,
  resetServerValidation?: (inputKey: string) => void
) => {
  const [effectHandledValue, setEffectHandledValue] = useState(defaultValue);
  const [value, setValue] = useState(defaultValue);

  let validationResult: ValidationResult = validateValue(value);
  if (
    serverValidationResult !== null &&
    !serverValidationResult.isValid &&
    serverValidationResult.error !== null &&
    validationResult.isValid
  ) {
    validationResult.error = serverValidationResult.error;
    validationResult.isValid = false;
  }

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (validationResult.isValid && value !== effectHandledValue) {
        valueChangeEffectFunction(value);
        setEffectHandledValue(value);
      }
    } else {
      didMount.current = true;
    }
  }, [validationResult, effectHandledValue, value, valueChangeEffectFunction]);

  const valueChangeHandler = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    if (resetServerValidation !== undefined && serverValidationResult?.inputKey !== undefined) {
      resetServerValidation(serverValidationResult.inputKey);
    }
  };

  return { value, validationResult, valueChangeHandler, setValue };
};

export default useValidatedSelectEffect;
