import i18n from "../../../i18n";
import ValidationResult from "../common/validation-result";

export const validateUsername = (username: string): ValidationResult => {
  if (username.length > 0 && username.length < 3) {
    return {
      isValid: false,
      error: i18n.t("validationError-username-too-short"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-username"),
  };
};

export const validatePassword = (password: string): ValidationResult => {
  if (password.length > 0 && password.length < 7) {
    return {
      isValid: false,
      error: i18n.t("validationError-password-too-short"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-password"),
  };
};

export const validateRepeatedPassword = (password: string, repeatedPassword: string): ValidationResult => {
  if (password !== repeatedPassword) {
    return {
      isValid: false,
      error: i18n.t("validationError-passwords-do-not-match"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-repeat-password"),
  };
};

export const validateName = (name: string): ValidationResult => {
  if (name.length === 0) {
    return {
      isValid: true,
      error: i18n.t("form-help-text-full-name"),
    };
  }
  if (name.length < 3) {
    return {
      isValid: false,
      error: i18n.t("validationError-name-too-short"),
    };
  }
  if (name.split(" ").length < 2) {
    return {
      isValid: false,
      error: i18n.t("validationError-needs-two-names"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-full-name"),
  };
};

export const validateEmail = (email: string): ValidationResult => {
  if (email.length > 0 && email.split("@").length < 2) {
    return {
      isValid: false,
      error: i18n.t("validationError-email-invalid-format"),
    };
  }
  return {
    isValid: true,
    error: i18n.t("form-help-text-email"),
  };
};
