import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { AddIcon, CompletedTasksIcon, OpenTasksIcon } from "../../UI/icons";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectWantsData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { getWants } from "../../../store/general/general_actions";
import { setEditedWant } from "../../../store/general/general_slice";
import WantCard from "./WantCard";
import CustomSkeleton from "../../misc/CustomSkeleton";
import TagFilter from "../../events/tasks/TagFilter";
import Tag from "../../../models/general/tag";
import TagGroup from "../../../models/enums/entry/TagGroup";
import IconButton from "@mui/material/IconButton";

const Wants: React.FC<{ isShoppingList?: boolean; tagGroups: TagGroup[] }> = ({
  isShoppingList = false,
  tagGroups,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const wantsData = useAppSelector((state) => selectWantsData(state));

  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const [showClosed, setShowClosed] = useState(false);

  useEffect(() => {
    if (wantsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getWants());
    }
  }, [dispatch, wantsData.rest.getStatus]);

  const addWantClickHandler = () => {
    dispatch(
      setEditedWant({
        data: {
          pantryItem: isShoppingList,
          tags: filteredTags,
        },
        tagGroups: tagGroups,
      })
    );
  };

  const tagFilterChangeHandler = (updatedTags: Tag[]) => {
    setFilteredTags(updatedTags);
  };

  const toggleClosedClickHandler = () => {
    setShowClosed((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h1">{isShoppingList ? t("title-shopping-list") : t("title-wishlist")}</Typography>
        <Stack direction="row" spacing={2}>
          <IconButton onClick={toggleClosedClickHandler}>
            {showClosed ? <OpenTasksIcon /> : <CompletedTasksIcon />}
          </IconButton>
          <Button startIcon={<AddIcon />} onClick={addWantClickHandler}>
            {t("button-add-new")}
          </Button>
        </Stack>
      </Stack>

      <TagFilter filteredTags={filteredTags} tagGroups={tagGroups} onFilteredTagsChange={tagFilterChangeHandler} />

      {wantsData.rest.getStatus === HttpFetchStatus.Idle || wantsData.rest.getStatus === HttpFetchStatus.Loading ? (
        <Stack spacing={2}>
          {Array(10)
            .fill(0)
            .map((_, index) => (
              <Card key={index}>
                <CardContent>
                  <Stack spacing={1}>
                    <CustomSkeleton gridSize={{ xs: 12 }} height={3} width="40%" />
                    <CustomSkeleton gridSize={{ xs: 12 }} height={2} />
                  </Stack>
                </CardContent>
              </Card>
            ))}
        </Stack>
      ) : wantsData.rest.getStatus === HttpFetchStatus.Failed || wantsData.data == null ? (
        <Alert severity="error">{t("error-failed-to-load-wants")}</Alert>
      ) : wantsData.data.length === 0 ? (
        <Alert sx={{ width: "100%" }} severity="info">
          {isShoppingList ? t("info-no-current-grocery-items") : t("info-no-current-wants")}
        </Alert>
      ) : (
        <Grid container spacing={2}>
          {wantsData.data
            .filter(
              (want) =>
                (!isShoppingList || want.pantryItem) &&
                ((want.closed && showClosed) || (!want.closed && !showClosed)) &&
                (filteredTags.length === 0 || want.tags.some((tag) => filteredTags.some((ft) => ft.id === tag.id)))
            )
            .map((want) => (
              <WantCard
                key={want.id}
                want={want}
                tagGroups={tagGroups}
                addInProgress={
                  wantsData.rest.addPostStatus === HttpFetchStatus.Loading && wantsData.rest.addPostId === want.id
                }
              />
            ))}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Wants;
