import React from "react";
import Grid from "@mui/material/Grid";
import CustomPieChart from "../charts/CustomPieChart";
import { useAppSelector } from "../../../store";
import {
  selectBudgetEntriesData,
  selectTimeFilteredBudgetEntries,
} from "../../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";
import { CashflowDirection, RepeatEntry } from "../../../models/enums/entry/CashflowDirection";
import CustomLineChartCard from "../charts/CustomLineChartCard";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import CustomSkeleton from "../../misc/CustomSkeleton";

const CashflowPieCharts: React.FC = () => {
  const { t } = useTranslation();

  const timeFilteredEntries = useAppSelector((state) => selectTimeFilteredBudgetEntries(state));
  const {
    rest: { getStatus },
  } = useAppSelector((state) => selectBudgetEntriesData(state));

  if (getStatus === HttpFetchStatus.Loading) {
    return (
      <React.Fragment>
        <CustomSkeleton gridSize={{ xs: 12 }} height={48} />
        <CustomSkeleton gridSize={{ xs: 12, md: 6 }} height={32} />
        <CustomSkeleton gridSize={{ xs: 12, md: 6 }} height={32} />
      </React.Fragment>
    );
  }

  if (timeFilteredEntries == null || timeFilteredEntries.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <CustomLineChartCard
          entries={timeFilteredEntries}
          label={t("title-spending-summary-chart")}
          expectedValue={null}
          repeat={RepeatEntry.WEEKLY}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomPieChart
          label={t("title-income")}
          noneLabel={t("info-no-income-this-time-period")}
          data={timeFilteredEntries
            .filter(
              (entry) =>
                entry.cashflowDirection === CashflowDirection.INCOME &&
                (entry.amountPaid != null || entry.amountDue != null)
            )
            .map((entry) => ({
              tag: entry.tags.length > 0 ? { color: entry.tags[0].color, name: entry.tags[0].name } : null,
              value: (entry.amountPaid ?? entry.amountDue)!,
            }))}
          miniLabel={t("currency-" + timeFilteredEntries[0].currency)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomPieChart
          label={t("title-expenses")}
          noneLabel={t("info-no-expenses-this-time-period")}
          data={timeFilteredEntries
            .filter(
              (entry) =>
                entry.cashflowDirection === CashflowDirection.EXPENSE &&
                (entry.amountPaid != null || entry.amountDue != null)
            )
            .map((entry) => ({
              tag: entry.tags.length > 0 ? { color: entry.tags[0].color, name: entry.tags[0].name } : null,
              value: (entry.amountPaid ?? entry.amountDue)!,
            }))}
          miniLabel={t("currency-" + timeFilteredEntries[0].currency)}
        />
      </Grid>
    </React.Fragment>
  );
};

export default CashflowPieCharts;
