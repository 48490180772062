import { RepeatEntry } from "../entry/CashflowDirection";

enum FilterDateType {
  Week = "week",
  Month = "month",
  Year = "year",
}

export default FilterDateType;

export const mapFilterDateToEntryRepeat = (filterDateType: FilterDateType) => {
  switch (filterDateType) {
    case FilterDateType.Week:
      return RepeatEntry.WEEKLY;
    case FilterDateType.Month:
      return RepeatEntry.MONTHLY;
    default:
      return RepeatEntry.YEARLY;
  }
};
