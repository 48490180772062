import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { parseEnumFromString, parseStringFromEnum } from "../../utils/url-tabs-helper";
import TabPanel, { a11yProps } from "../misc/TabsPanel";
// import Calendar from "../events/calendar/Calendar";
import Tasks from "../events/tasks/Tasks";
import { useTranslation } from "react-i18next";

enum DailyPageTabs {
  Tasks,
  Calendar,
}

const DAILY_TAB_QUERY_STRING = "t";

const DailyPage: React.FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [filterParams, setFilterParams] = useSearchParams();
  const dailyTab = parseEnumFromString(DailyPageTabs, filterParams.get(DAILY_TAB_QUERY_STRING));

  const [tabsValue, setTabsValue] = useState(dailyTab);

  const tabChangeHandler = (_event: React.SyntheticEvent, newValue: number) => {
    setFilterParams(`${DAILY_TAB_QUERY_STRING}=${parseStringFromEnum(DailyPageTabs, newValue)}`);
    setTabsValue(newValue);
  };

  return (
    <React.Fragment>
      {matchesMdDown ? (
        <React.Fragment>
          <Tabs value={tabsValue} onChange={tabChangeHandler} sx={(theme) => ({ mb: theme.spacing(2) })}>
            {/* <Tab label={t("title-calendar")} {...a11yProps(DailyPageTabs.Calendar)} /> */}
            <Tab label={t("title-tasks")} {...a11yProps(DailyPageTabs.Tasks)} />
          </Tabs>

          {/* <TabPanel value={tabsValue} index={DailyPageTabs.Calendar}>
            <Calendar />
          </TabPanel> */}

          <TabPanel value={tabsValue} index={DailyPageTabs.Tasks}>
            <Tasks />
          </TabPanel>
        </React.Fragment>
      ) : (
        <Grid container spacing={2}>
          {/* <Grid item xs={8}>
            <Calendar />
          </Grid> */}

          <Grid item xs={12}>
            <Tasks />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default DailyPage;

// eslint-disable-next-line no-lone-blocks
{
  /* <Typography>Daily page</Typography>
  <Typography>A place to manage a calendar</Typography>
  <Typography>
    Task Prioritization: Helps users prioritize tasks based on urgency and importance. Calendar Integration: Syncs
    with external calendars for a cohesive view of tasks and events. Time Blocking: Allows for planning tasks in a
    calendar-like interface with dedicated time blocks. Focus Timer: Includes a timer to promote focused work
    sessions, often using techniques like the Pomodoro Technique. Deadline Reminders: Provides alerts for upcoming
    deadlines to keep users on track. Analytical Insights: Offers breakdowns of how time is spent for
    self-improvement and better planning. Project Management: Some apps include project management features like
    Gantt charts or Kanban boards. Task Delegation: Allows teams to assign tasks and track completion. Automated
    Task Tracking: Uses device usage data to automatically track time spent on different tasks. Integration with
    Other Apps: Syncs with other productivity tools and apps to centralize time management.
  </Typography>
  <Divider />
  <Typography>
    Customizable Planners: Users can customize layouts to suit their planning style (daily, weekly, monthly). To-Do
    Lists: Simple lists for daily tasks with checkboxes for a sense of accomplishment upon completion. Event
    Planning: Specialized features for planning events, including checklists, budgets, and schedules. Collaboration
    Tools: Features that allow multiple users to contribute to the same plan. Sync Across Devices: Plans are
    available across various devices, allowing users to plan anytime, anywhere. Attachment Support: The ability to
    attach files, photos, or links to plans for added information. Template Library: Pre-made templates for various
    planning needs, such as travel, weddings, or study schedules. Import/Export Options: Capabilities to import from
    or export to other planning tools or formats. Reminders and Alerts: Custom reminders for plan-related tasks or
    events. AI Assistance: Some apps include AI that suggests optimizations in plans based on user behavior and
    preferences.
  </Typography> */
}
