import { DateTime } from "luxon";
import i18n from "../../../i18n";
import ValidationResult from "../common/validation-result";

export const validateFoodItem = (item: string): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-food-item"),
  };
};

export const validateFoodItemQuantity = (quantity: number): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-food-item-quantity"),
  };
};

export const validateFoodItemTags = (tags: string[]): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-food-item-tags"),
  };
};

export const validateFoodItemExpiryDate = (expiryDate: DateTime): ValidationResult => {
  return {
    isValid: true,
    error: i18n.t("form-help-text-food-item-expiry-date"),
  };
};
