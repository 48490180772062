import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import generalReducer from "./general/general_slice";

import { getInitialGeneralState } from "./general/general_slice_definitions";

export const rootReducer = combineReducers({
  general: generalReducer,
});

export const getInitialState = (): RootState => {
  return {
    general: { ...getInitialGeneralState() },
  };
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export const setupTestStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupTestStore>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
