import React, { useEffect } from "react";
import MultipleSelectInput from "./MultipleSelectForm";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectTagsData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { getTags } from "../../../store/general/general_actions";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material";
import ValidationResult from "../../../services/validation/common/validation-result";
import TagGroup from "../../../models/enums/entry/TagGroup";

const TagSelect: React.FC<{
  tagIds: string[];
  tagsValidationResult: ValidationResult;
  tagGroups: TagGroup[];
  onTagsChange: (event: SelectChangeEvent<string[]>) => void;
}> = ({ tagIds, tagsValidationResult, tagGroups, onTagsChange }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const tagsData = useAppSelector((state) => selectTagsData(state));

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [dispatch, tagsData.rest.getStatus]);

  return (
    <React.Fragment>
      <MultipleSelectInput
        label={t("input-label-tags")}
        values={
          tagsData.data != null
            ? tagsData.data.filter((td) => tagIds.some((t) => t === td.id)).map((td) => ({ id: td.id, title: td.name }))
            : []
        }
        onChange={onTagsChange}
        validationResult={tagsValidationResult}
      >
        {tagsData.data != null
          ? tagsData.data
              .filter((td) => td.tagGroups.length === 0 || tagGroups.some((tg) => td.tagGroups.includes(tg)))
              .map((td) => (
                <MenuItem key={td.id} value={td.id}>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      backgroundColor: td.color,
                      marginRight: "8px",
                    }}
                  />
                  {td.name}
                </MenuItem>
              ))
          : null}
      </MultipleSelectInput>
    </React.Fragment>
  );
};

export default TagSelect;
