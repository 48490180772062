import { default as i18n, default as i18next } from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en/translation.json";

export const availableApiLanguages = [{ languageCode: "en", description: "English" }];

const resources = {
  en: {
    translation: translationEN,
  },
};

export const STORED_LANG_KEY = "max-life-stored-lang";
const getStoredOrDefaultLanguage = () => {
  const storedLanguageCode = localStorage.getItem(STORED_LANG_KEY);
  return availableApiLanguages.find((l) => l.languageCode === storedLanguageCode) ?? availableApiLanguages[0];
};

export const getCurrentLanguage = () => {
  const language = availableApiLanguages.find((lang) => lang.languageCode === i18next.language);
  return language ?? getStoredOrDefaultLanguage();
};

export const isKeyInTranslation = (key: string) => {
  return key in resources.en.translation;
};

i18n.use(initReactI18next).init({
  resources: resources,
  lng: getStoredOrDefaultLanguage().languageCode,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
