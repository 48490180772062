import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const TextWithLabel: React.FC<{
  text: string;
  label: string;
  allowWrap?: boolean;
  textVariant?: "h1" | "h2" | "body1" | "body2";
  textColor?: "error";
}> = ({ text, label, textVariant = "body1", allowWrap, textColor }) => {
  return (
    <Box>
      <Typography variant="caption" sx={(theme) => ({ minHeight: theme.spacing(1.75), display: "block" })}>
        {label}
      </Typography>
      <Typography
        ml={1.5}
        variant={textVariant}
        noWrap={!allowWrap}
        whiteSpace={allowWrap ? "pre-wrap" : undefined}
        lineHeight={1.45}
        color={textColor}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TextWithLabel;
