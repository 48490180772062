import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectBudgetEntriesData, selectTagsData } from "../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import { getBudgetEntries, getTags } from "../../store/general/general_actions";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel, { a11yProps } from "../misc/TabsPanel";
import { useSearchParams } from "react-router-dom";
import { parseEnumFromString, parseStringFromEnum } from "../../utils/url-tabs-helper";
import CashflowOutlook from "../budget/cashflow/CashflowOutlook";
import BudgetOutlook from "../budget/budgeting/BudgetOutlook";
import { setEditedEntry } from "../../store/general/general_slice";
import Wants from "../pantry/wants/Wants";
import TagGroup from "../../models/enums/entry/TagGroup";

enum BudgetingTabs {
  ActualCashflow,
  Budgeting,
  Wishlist,
}

const BUDGET_TAB_QUERY_STRING = "t";

const BudgetPage: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [filterParams, setFilterParams] = useSearchParams();
  const budgetTab = parseEnumFromString(BudgetingTabs, filterParams.get(BUDGET_TAB_QUERY_STRING));

  const [tabsValue, setTabsValue] = useState(budgetTab);

  const budgetEntries = useAppSelector((state) => selectBudgetEntriesData(state));
  const tagsData = useAppSelector((state) => selectTagsData(state));

  useEffect(() => {
    if (budgetEntries.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getBudgetEntries());
    }
    if (
      budgetEntries.rest.postStatus === HttpFetchStatus.Success ||
      budgetEntries.rest.deleteStatus === HttpFetchStatus.Success ||
      budgetEntries.rest.patchStatus === HttpFetchStatus.Success
    ) {
      dispatch(setEditedEntry(null));
    }
  }, [
    budgetEntries.rest.deleteStatus,
    budgetEntries.rest.getStatus,
    budgetEntries.rest.patchStatus,
    budgetEntries.rest.postStatus,
    dispatch,
  ]);

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [tagsData.rest.getStatus, dispatch]);

  const tabChangeHandler = (_event: React.SyntheticEvent, newValue: number) => {
    setFilterParams(`${BUDGET_TAB_QUERY_STRING}=${parseStringFromEnum(BudgetingTabs, newValue)}`);
    setTabsValue(newValue);
  };

  return (
    <React.Fragment>
      <Tabs value={tabsValue} onChange={tabChangeHandler} sx={(theme) => ({ mb: theme.spacing(2) })}>
        <Tab label={t("title-actual-cashflow")} {...a11yProps(0)} />
        <Tab label={t("title-budgeting")} {...a11yProps(1)} />
        <Tab label={t("title-wishlist")} {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={tabsValue} index={BudgetingTabs.ActualCashflow}>
        <CashflowOutlook />
      </TabPanel>

      <TabPanel value={tabsValue} index={BudgetingTabs.Budgeting}>
        <BudgetOutlook />
      </TabPanel>

      <TabPanel value={tabsValue} index={BudgetingTabs.Wishlist}>
        <Wants tagGroups={[TagGroup.Wants]} />
      </TabPanel>
    </React.Fragment>
  );
};

export default BudgetPage;

// Real-Time Expense Tracking: Allows users to enter expenses on the go, often with the option to take a picture of
// receipts. Bank Synchronization: Automatically syncs with user bank accounts to track spending without manual
// entry. Budget Categorization: Enables users to categorize their spending into customizable categories for better
// financial management. Spending Alerts: Sends notifications when spending approaches set limits in budget
// categories. Reports and Charts: Provides visual insights into spending habits over time with detailed reports
// and charts. Debt Tracking and Management: Helps users track their debts and plan repayment strategies. Savings
// Goals: Allows users to set and track savings goals, often with milestone notifications. Subscription Management:
// Tracks recurring payments and subscriptions, with reminders before renewal dates. Shared Accounts: Enables
// budget management with partners or family members on shared accounts. Investment Tracking: Some apps offer a
// feature to monitor investments and net worth over time.
