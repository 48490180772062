import React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { NotificationIcon, SearchIcon } from "./icons";
import { stringAvatar } from "../../utils/avatarStyles";
import { useAppSelector } from "../../store";
import { selectUser } from "../../store/general/general_slice_selectors";
import { useMediaQuery } from "@mui/material";
import { customTheme } from "../../utils/theme";

const RightQuickFormsBlock: React.FC = () => {
  const matchesMdUp = useMediaQuery(() => customTheme.breakpoints.up("md"));

  const user = useAppSelector((state) => selectUser(state));

  if (!matchesMdUp) {
    return null;
  }

  return (
    <Stack direction="column" alignItems={"center"} spacing={1} pt={4} pb={4} pl={2} pr={2}>
      <Avatar {...stringAvatar(user.data?.name ?? "")} />
      <IconButton size="large">
        <NotificationIcon />
      </IconButton>
      <IconButton size="large">
        <SearchIcon />
      </IconButton>
    </Stack>
  );
};

export default RightQuickFormsBlock;
