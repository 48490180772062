import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectWantsData } from "../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";
import { resetAddWantItemPostState, setEditedWant } from "../../store/general/general_slice";
import { CalendarIcon, CloseIcon, EditIcon } from "../UI/icons";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import { addWantItemById, getWants } from "../../store/general/general_actions";
import TextWithLabel from "../misc/TextWithLabel";
import { dateToStringFormat } from "../../utils/date";
import { quantityAndUnitToString } from "../../models/enums/pantry/foodUnit";
import { amountAndCurrencyToString } from "../../models/enums/entry/CurrencyChoice";
import CustomSnackbar from "../misc/CustomSnackbar";
import PlanPurchaseForm from "../pantry/wants/PlanPurchaseForm";

const WishlistPage: React.FC = () => {
  const { t } = useTranslation();

  const { wantId } = useParams<{ wantId: string }>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const wantsData = useAppSelector((state) => selectWantsData(state));

  const [isPlanPurchaseFormOpen, setIsPlanPurchaseFormOpen] = useState(false);

  useEffect(() => {
    if (wantsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getWants());
    }
  }, [dispatch, wantsData.rest.getStatus]);

  useEffect(() => {
    if (wantsData.rest.planPurchasePostStatus === HttpFetchStatus.Success) {
      setIsPlanPurchaseFormOpen(false);
    }
  }, [wantsData.rest.planPurchasePostStatus]);

  useEffect(() => {
    if (wantsData.rest.deleteStatus === HttpFetchStatus.Success) {
      navigate(-1);
    }
  }, [navigate, wantsData.rest.deleteStatus]);

  const backClickHandler = () => {
    navigate(-1);
  };

  const want = wantsData.data?.find((want) => want.id === wantId && wantId != null);

  if (want == null) {
    return null;
  }

  const editWantClickHandler = () => {
    dispatch(setEditedWant({ data: want }));
  };

  const addWantItemClickHandler = () => {
    dispatch(addWantItemById(want.id));
  };

  const closeAddWantItemSnackbarClickHandler = () => {
    dispatch(resetAddWantItemPostState());
  };

  const closePlanPurchaseFormHandler = () => {
    setIsPlanPurchaseFormOpen(false);
  };

  const openPlanPurchaseFormClickHandler = () => {
    setIsPlanPurchaseFormOpen(true);
  };

  return (
    <React.Fragment>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={backClickHandler} size="medium">
              <CloseIcon />
            </IconButton>
            <Typography variant="h1">{want.title}</Typography>
          </Stack>

          {want.closed ? (
            <Alert severity="info">{t("info-want-closed")}</Alert>
          ) : (
            <Stack direction="row" spacing={2}>
              <Button variant="contained" onClick={addWantItemClickHandler}>
                {t("button-complete-purchase")}
              </Button>
              <Button startIcon={<EditIcon />} onClick={editWantClickHandler}>
                {t("button-edit")}
              </Button>
              {want.budgetEntry == null && (
                <Button startIcon={<CalendarIcon />} onClick={openPlanPurchaseFormClickHandler}>
                  {t("button-plan-purchase")}
                </Button>
              )}
            </Stack>
          )}
        </Stack>

        <Stack spacing={2}>
          <TextWithLabel label={t("input-label-description")} text={want.description ?? ""} allowWrap />

          <TextWithLabel
            label={t("input-label-want-date-created")}
            text={dateToStringFormat(want.dateCreated)}
            allowWrap
          />

          <Grid container>
            {want.cost != null && (
              <Grid item xs={6}>
                <TextWithLabel
                  label={t("input-label-want-cost")}
                  text={amountAndCurrencyToString(want.cost, want.currency)}
                  allowWrap
                />
              </Grid>
            )}
            <Grid item xs={want.cost == null ? 12 : 6}>
              <TextWithLabel
                label={t("input-label-want-quantity")}
                text={quantityAndUnitToString(want.quantity, want.unit)}
                allowWrap
              />
            </Grid>
          </Grid>

          {want.tags.length > 0 && (
            <Stack direction="row" spacing={2}>
              {want.tags.map((tag) => (
                <Chip key={tag.id} label={tag.name} />
              ))}
            </Stack>
          )}
        </Stack>

        {want.pantryItem && (
          <Stack spacing={2}>
            <Typography variant="h2">{t("title-pantry")}</Typography>
            {want.foodItem == null ? (
              <Alert severity="info">{t("info-new-food-item")}</Alert>
            ) : (
              <React.Fragment>
                <TextWithLabel
                  text={quantityAndUnitToString(want.foodItem.quantity, want.foodItem.unit)}
                  label={t("label-current-stock")}
                />
              </React.Fragment>
            )}
          </Stack>
        )}

        <Stack spacing={2}>
          <Typography variant="h2">{t("title-budgeting")}</Typography>
          {want.budgetEntry == null ? (
            <Alert severity="info">{t("info-no-budgeting-info")}</Alert>
          ) : (
            <React.Fragment>
              {want.budgetEntry.dateDue != null && (
                <TextWithLabel text={dateToStringFormat(want.budgetEntry.dateDue)} label={t("input-label-dateDue")} />
              )}
              {want.budgetEntry.datePaid != null && (
                <TextWithLabel text={dateToStringFormat(want.budgetEntry.datePaid)} label={t("input-label-datePaid")} />
              )}
              {want.budgetEntry.amountPaid != null && (
                <TextWithLabel
                  text={amountAndCurrencyToString(want.budgetEntry.amountPaid, want.budgetEntry.currency)}
                  label={t("input-label-budgetEntryAmountPaid")}
                />
              )}
            </React.Fragment>
          )}
        </Stack>
      </Stack>
      <CustomSnackbar
        onClose={closeAddWantItemSnackbarClickHandler}
        open={wantsData.rest.addPostStatus === HttpFetchStatus.Failed}
        severity="error"
        title={t("error-failed-to-add-want")}
      />
      <PlanPurchaseForm want={want} onClose={closePlanPurchaseFormHandler} open={isPlanPurchaseFormOpen} />
    </React.Fragment>
  );
};

export default WishlistPage;
