import ValidationResult from "../../../services/validation/common/validation-result";
import HttpError from "./http-error";
import HttpFetchStatus from "./http-fetch-status";

interface RestSet {
  postStatus: HttpFetchStatus;
  postError: HttpError | ValidationResult[] | null;
  patchId: string | null;
  patchStatus: HttpFetchStatus;
  patchError: HttpError | ValidationResult[] | null;
  getStatus: HttpFetchStatus;
  getError: HttpError | null;
  deleteStatus: HttpFetchStatus;
  deleteError: HttpError | null;
}

export const defaultRestSet = {
  postStatus: HttpFetchStatus.Idle,
  postError: null,
  patchId: null,
  patchStatus: HttpFetchStatus.Idle,
  patchError: null,
  getStatus: HttpFetchStatus.Idle,
  getError: null,
  deleteStatus: HttpFetchStatus.Idle,
  deleteError: null,
} as RestSet;

export interface WantRestSet extends RestSet {
  addPostId: string | null;
  addPostStatus: HttpFetchStatus;
  addPostError: HttpError | null;
  planPurchasePostStatus: HttpFetchStatus;
  planPurchasePostError: HttpError | null;
}

export interface FoodRestSet extends RestSet {
  updateHistoryPostStatus: HttpFetchStatus;
  updateHistoryPostError: HttpError | ValidationResult[] | null;
}

export default RestSet;
