import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import TextInput from "../misc/forms/TextInput";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import useValidatedInputEffect from "../../hooks/useValidatedInputEffect";
import ValidationResult, { isValidationResultArray } from "../../services/validation/common/validation-result";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectBudgetEntriesData, selectTagsData } from "../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import {
  createBudgetEntry,
  deleteEntryById,
  getBudgetEntries,
  getTags,
  updateBudgetEntryById,
} from "../../store/general/general_actions";
import {
  validateBudgetEntryAmountDue,
  validateBudgetEntryAmountPaid,
  validateDatePaid,
  validateDateDue,
  validateDescription,
  validateTags,
} from "../../services/validation/general/budget_entry_validations";
import DateTimeInput from "../misc/forms/DateTimeInput";
import { DateTime } from "luxon";
import MultipleSelectInput from "../misc/forms/MultipleSelectForm";
import useValidatedMultipleSelectEffect from "../../hooks/useValidatedMultipleSelectEffect";
import BudgetEntry from "../../models/budget/budget-entry";
import { CashflowDirection, EntryType, RepeatEntry } from "../../models/enums/entry/CashflowDirection";
import useValidatedSelectEffect from "../../hooks/useValidatedSelectEffect";
import SelectInput from "../misc/forms/SelectInput";
import ConfirmAbortDialog from "../misc/ConfirmAbortDialog";
import TagGroup from "../../models/enums/entry/TagGroup";
import { amountAndCurrencyToString } from "../../models/enums/entry/CurrencyChoice";

const NONE = "NONE";

const AddBudgetEntryForm: React.FC<{
  isFormOpen: boolean;
  entry?: Partial<BudgetEntry>;
  onCloseEntry: () => void;
}> = ({ isFormOpen, entry, onCloseEntry }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const budgetEntriesData = useAppSelector((state) => selectBudgetEntriesData(state));
  const tagsData = useAppSelector((state) => selectTagsData(state));

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isPlanningPaidOpen, setIsPlanningPaidOpen] = useState(entry?.datePaid != null);
  const [serverValidationErrors, setServerValidationErrors] = useState<ValidationResult[]>([]);
  const [isShowAllExpanded, setIsShowAllExpanded] = useState(false);

  useEffect(() => {
    if (isValidationResultArray(budgetEntriesData.rest.postError)) {
      setServerValidationErrors(budgetEntriesData.rest.postError);
    } else if (isValidationResultArray(budgetEntriesData.rest.patchError)) {
      setServerValidationErrors(budgetEntriesData.rest.patchError);
    }
  }, [budgetEntriesData.rest.patchError, budgetEntriesData.rest.postError]);

  useEffect(() => {
    if (budgetEntriesData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getBudgetEntries());
    }
  }, [budgetEntriesData.rest.getStatus, dispatch]);

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [tagsData.rest.getStatus, dispatch]);

  const removeServerError = (inputKey: string) => {
    setServerValidationErrors((prevState) =>
      prevState.filter((sv) => !(sv.inputKey !== undefined && sv.inputKey === inputKey))
    );
  };

  const getServerValidationResultOrNull = (inputKey: string) => {
    let serverValidation = serverValidationErrors.find((sv) => sv.inputKey === inputKey);
    return serverValidation !== undefined ? serverValidation : null;
  };

  const {
    value: budgetEntryAmountPaid,
    validationResult: budgetEntryAmountPaidValidationResult,
    valueChangeHandler: budgetEntryAmountPaidChangeHandler,
  } = useValidatedInputEffect<string>(
    entry?.amountPaid?.toString() ?? entry?.amountDue?.toString() ?? "0",
    (newBudgetEntryAmount) => validateBudgetEntryAmountPaid(newBudgetEntryAmount),
    (_) => () => {},
    getServerValidationResultOrNull("amountPaid"),
    removeServerError
  );

  const {
    value: budgetEntryAmountDue,
    validationResult: budgetEntryAmountDueValidationResult,
    valueChangeHandler: budgetEntryAmountDueChangeHandler,
  } = useValidatedInputEffect<string>(
    entry?.amountDue?.toString() ?? "0",
    (newBudgetEntryAmount) => validateBudgetEntryAmountDue(newBudgetEntryAmount),
    (_) => () => {},
    getServerValidationResultOrNull("amountDue"),
    removeServerError
  );

  const {
    value: cashflowDirection,
    validationResult: cashflowDirectionValidationResult,
    valueChangeHandler: cashflowDirectionChangeHandler,
  } = useValidatedSelectEffect(
    entry?.cashflowDirection ?? CashflowDirection.EXPENSE,
    (newCashflowDirection) => ({ isValid: true, error: t("form-help-text-cashflow-direction") }),
    (_) => () => {},
    getServerValidationResultOrNull("cashflowDirection"),
    removeServerError
  );

  const {
    value: entryRepeats,
    validationResult: entryRepeatsValidationResult,
    valueChangeHandler: entryRepeatsChangeHandler,
  } = useValidatedSelectEffect(
    entry?.repeat ?? RepeatEntry.NEVER,
    (newEntryRepeats) => ({ isValid: true, error: t("form-help-text-entry-repeats") }),
    (_) => () => {},
    getServerValidationResultOrNull("repeats"),
    removeServerError
  );

  const {
    value: budgetingEntry,
    validationResult: budgetingEntryValidationResult,
    valueChangeHandler: budgetingEntryChangeHandler,
  } = useValidatedSelectEffect(
    entry?.budgetingEntry ?? NONE,
    (newBudgetingEntry) => ({ isValid: true, error: t("form-help-text-budgeting-entry") }),
    (_) => () => {},
    getServerValidationResultOrNull("budgetingEntry"),
    removeServerError
  );

  const {
    value: description,
    validationResult: descriptionValidationResult,
    valueChangeHandler: descriptionChangeHandler,
  } = useValidatedInputEffect(
    entry?.description ?? "",
    (newDescription) => validateDescription(newDescription, cashflowDirection as CashflowDirection),
    (_) => () => {},
    getServerValidationResultOrNull("description"),
    removeServerError
  );

  const [datePaid, setDatePaid] = useState<{ value: DateTime; validation: ValidationResult }>({
    value:
      entry?.datePaid != null
        ? DateTime.fromJSDate(new Date(entry.datePaid))
        : DateTime.fromISO(DateTime.now().toString()).toLocal(),
    validation: getServerValidationResultOrNull("datePaid") ?? {
      ...validateDatePaid(DateTime.fromISO(DateTime.now().toString())),
    },
  });

  const [dateDue, setDateDue] = useState<{ value: DateTime; validation: ValidationResult }>({
    value:
      entry?.dateDue != null
        ? DateTime.fromJSDate(new Date(entry.dateDue))
        : DateTime.fromISO(DateTime.now().toString()).toLocal(),
    validation: getServerValidationResultOrNull("dateDue") ?? {
      ...validateDateDue(DateTime.fromISO(DateTime.now().toString())),
    },
  });

  const datePaidChangeHandler = (newDatePaid: DateTime | null) => {
    if (newDatePaid) {
      setDatePaid({ value: newDatePaid, validation: validateDatePaid(newDatePaid) });
      removeServerError("datePaid");
    }
  };

  const dateDueChangeHandler = (newDateDue: DateTime | null) => {
    if (newDateDue) {
      setDateDue({ value: newDateDue, validation: validateDateDue(newDateDue) });
      removeServerError("dateDue");
    }
  };

  const {
    value: tags,
    validationResult: tagsValidationResult,
    valueChangeHandler: tagsChangeHandler,
  } = useValidatedMultipleSelectEffect(
    entry?.tags != null ? entry.tags.map((tag) => tag.id) : [],
    (tags) => validateTags(tags),
    (_) => () => {},
    getServerValidationResultOrNull("tags"),
    removeServerError
  );

  const addExpenseClickHandler = () => {
    dispatch(
      createBudgetEntry({
        description,
        amountPaid:
          entry?.entryType === EntryType.UNPLANNED || isPlanningPaidOpen ? parseFloat(budgetEntryAmountPaid) : null,
        amountDue: parseFloat(budgetEntryAmountDue),
        tags: tagsData.data?.filter((t) => tags.some((tId) => tId === t.id)),
        datePaid: entry?.entryType === EntryType.UNPLANNED || isPlanningPaidOpen ? datePaid.value.toJSDate() : null,
        dateDue: entry?.entryType === EntryType.PLANNED ? dateDue.value.toJSDate() : null,
        cashflowDirection: cashflowDirection,
        entryType: entry?.entryType ?? EntryType.UNPLANNED,
        repeat: entryRepeats,
        budgetingEntry: budgetingEntry === NONE ? null : budgetingEntry,
      } as Partial<BudgetEntry>)
    );
  };

  const updateExpenseClickHandler = () => {
    if (entry != null) {
      dispatch(
        updateBudgetEntryById({
          id: entry.id,
          description,
          amountPaid:
            entry?.entryType === EntryType.UNPLANNED || isPlanningPaidOpen ? parseFloat(budgetEntryAmountPaid) : null,
          amountDue: parseFloat(budgetEntryAmountDue),
          cashflowDirection,
          tags: tagsData.data?.filter((t) => tags.some((tId) => tId === t.id)) ?? entry.tags,
          datePaid: entry?.entryType === EntryType.UNPLANNED || isPlanningPaidOpen ? datePaid.value.toJSDate() : null,
          dateDue: entry?.entryType === EntryType.PLANNED ? dateDue.value.toJSDate() : null,
          repeat: entryRepeats,
          budgetingEntry: budgetingEntry === NONE ? null : budgetingEntry,
        } as Partial<BudgetEntry>)
      );
    }
  };

  const deleteEntryClickHandler = () => {
    setIsConfirmDeleteOpen(true);
  };

  const dispatchDeleteEntry = () => {
    if (entry?.id != null) {
      dispatch(deleteEntryById(entry.id));
    }
    closeConfirmDeleteDialog();
  };

  const dispatchArchiveEntry = () => {
    if (entry != null) {
      dispatch(updateBudgetEntryById({ ...entry, dateArchived: DateTime.now().toJSDate() }));
    }
  };

  const closeConfirmDeleteDialog = () => {
    setIsConfirmDeleteOpen(false);
  };

  const closeFormClickHandler = () => {
    onCloseEntry();
  };

  const switchPlanningPaidClickHandler = () => {
    setIsPlanningPaidOpen((prevState) => !prevState);
  };

  const switchShowAllExtendedClickHandler = () => {
    setIsShowAllExpanded((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <Dialog open={isFormOpen} onClose={closeFormClickHandler} fullWidth maxWidth="sm">
        <DialogContent>
          <Box>
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h2">
                  {entry?.entryType !== EntryType.BUDGETING
                    ? entry?.id == null
                      ? t("title-add-transaction")
                      : t("title-edit-transaction")
                    : entry?.id == null
                    ? t("title-add-" + entry.cashflowDirection)
                    : t("title-edit-" + entry.cashflowDirection)}
                </Typography>
                {entry?.id != null && (
                  <FormControlLabel
                    control={<Switch checked={isShowAllExpanded} onChange={switchShowAllExtendedClickHandler} />}
                    label={t("show-all")}
                  />
                )}
              </Stack>

              <TextInput
                label={t("input-label-description")}
                type="string"
                value={description}
                onChange={descriptionChangeHandler}
                validationResult={descriptionValidationResult}
              />
              {(isShowAllExpanded || entry?.entryType !== EntryType.BUDGETING) && (
                <SelectInput
                  label={t("input-label-budgetingEntry")}
                  value={budgetingEntry}
                  onChange={budgetingEntryChangeHandler}
                  validationResult={budgetingEntryValidationResult}
                >
                  <MenuItem value={NONE}>{t(NONE)}</MenuItem>
                  {(budgetEntriesData.data ?? [])
                    .filter((e) => e.entryType === EntryType.BUDGETING)
                    .map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {`${amountAndCurrencyToString(e.amountDue, e.currency)} - ${e.description}`}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
              {(entry?.entryType !== EntryType.UNPLANNED || isShowAllExpanded) && (
                <TextInput
                  label={t("input-label-budgetEntryAmountDue")}
                  type="number"
                  value={budgetEntryAmountDue}
                  onChange={budgetEntryAmountDueChangeHandler}
                  validationResult={budgetEntryAmountDueValidationResult}
                />
              )}
              {(entry?.entryType === EntryType.PLANNED || isShowAllExpanded) && (
                <DateTimeInput
                  datetime={dateDue.value}
                  title={t("input-label-dateDue") as string}
                  dateValidation={dateDue.validation}
                  onDateTimeChange={dateDueChangeHandler}
                />
              )}
              {entry?.entryType === EntryType.PLANNED && (
                <FormControlLabel
                  control={<Switch checked={isPlanningPaidOpen} onChange={switchPlanningPaidClickHandler} />}
                  label={t("switch-planning-paid")}
                />
              )}
              {(entry?.entryType === EntryType.UNPLANNED || isPlanningPaidOpen || isShowAllExpanded) && (
                <TextInput
                  label={t("input-label-budgetEntryAmountPaid")}
                  type="number"
                  value={budgetEntryAmountPaid}
                  onChange={budgetEntryAmountPaidChangeHandler}
                  validationResult={budgetEntryAmountPaidValidationResult}
                />
              )}
              {(entry?.entryType === EntryType.UNPLANNED || isPlanningPaidOpen || isShowAllExpanded) && (
                <DateTimeInput
                  datetime={datePaid.value}
                  title={t("input-label-datePaid") as string}
                  dateValidation={datePaid.validation}
                  onDateTimeChange={datePaidChangeHandler}
                />
              )}
              {(entry == null || entry.entryType === EntryType.UNPLANNED || isShowAllExpanded) && (
                <SelectInput
                  label={t("input-label-cashflowDirection")}
                  value={cashflowDirection}
                  onChange={cashflowDirectionChangeHandler}
                  validationResult={cashflowDirectionValidationResult}
                >
                  {Object.values(CashflowDirection).map((cf) => (
                    <MenuItem key={cf} value={cf}>
                      {t("cashflow-direction-" + cf)}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
              {(entry?.entryType === EntryType.BUDGETING || isShowAllExpanded) && (
                <SelectInput
                  label={t("input-label-entry-repeats")}
                  value={entryRepeats}
                  onChange={entryRepeatsChangeHandler}
                  validationResult={entryRepeatsValidationResult}
                >
                  {Object.values(RepeatEntry).map((re) => (
                    <MenuItem key={re} value={re}>
                      {t("repeats-" + re)}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
              {(entry?.entryType !== EntryType.PLANNED || isShowAllExpanded) && (
                <MultipleSelectInput
                  label={t("input-label-tags")}
                  values={
                    tagsData.data != null
                      ? tagsData.data
                          .filter((td) => tags.some((t) => t === td.id))
                          .map((td) => ({ id: td.id, title: td.name }))
                      : []
                  }
                  onChange={tagsChangeHandler}
                  validationResult={tagsValidationResult}
                >
                  {tagsData.data != null
                    ? tagsData.data
                        .filter((td) => td.tagGroups.length === 0 || td.tagGroups.includes(TagGroup.Budgeting))
                        .map((td) => (
                          <MenuItem key={td.id} value={td.id}>
                            <span
                              style={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "50%",
                                backgroundColor: td.color,
                                marginRight: "8px",
                              }}
                            />
                            {td.name}
                          </MenuItem>
                        ))
                    : null}
                </MultipleSelectInput>
              )}
              <Button
                variant="contained"
                fullWidth
                onClick={entry?.id == null ? addExpenseClickHandler : updateExpenseClickHandler}
              >
                {entry?.id == null ? t("button-add") : t("button-save")}
              </Button>
              {entry?.id != null ? (
                <Button variant="text" fullWidth size="small" color="error" onClick={deleteEntryClickHandler}>
                  {entry.entryType === EntryType.BUDGETING && entry.dateArchived == null
                    ? t("button-archive")
                    : t("button-delete")}
                </Button>
              ) : null}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>

      <ConfirmAbortDialog
        isOpen={isConfirmDeleteOpen}
        title={
          entry?.entryType === EntryType.BUDGETING && entry.dateArchived == null
            ? t("title-confirm-archive-entry")
            : t("title-confirm-delete-entry")
        }
        description={
          entry?.entryType === EntryType.BUDGETING && entry.dateArchived == null
            ? t("info-confirm-archive-entry")
            : t("info-confirm-delete-entry")
        }
        abortButtonText={t("button-cancel")}
        confirmButtonText={t("button-confirm")}
        onConfirm={
          entry?.entryType === EntryType.BUDGETING && entry.dateArchived == null
            ? dispatchArchiveEntry
            : dispatchDeleteEntry
        }
        onAbort={closeConfirmDeleteDialog}
      />
    </React.Fragment>
  );
};

export default AddBudgetEntryForm;
