import React from "react";
import ValidationResult from "../../../services/validation/common/validation-result";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material";

const SelectInput: React.FC<{
  label: string;
  children: React.ReactNode;
  size?: "small" | "medium";
  renderValue?: (value: string) => React.ReactElement;
  value: string;
  validationResult?: ValidationResult;
  onChange: (event: SelectChangeEvent) => void;
}> = ({ label, children, size = "medium", value, validationResult, onChange, renderValue }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id="select-helper-label">{label}</InputLabel>
      <Select
        labelId="select-helper-label"
        value={value}
        label={label}
        onChange={onChange}
        renderValue={renderValue}
        size={size}
        error={validationResult !== undefined && !validationResult.isValid}
      >
        {children}
      </Select>
      {validationResult !== undefined && validationResult.error !== null && (
        <FormHelperText sx={{ color: validationResult.isValid === true ? "default" : theme.palette.error.main }}>
          {t(validationResult.error)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectInput;
