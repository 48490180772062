import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../UI/MainNavigationBar";
import { TagsIcon } from "../../UI/icons";
import DatesFilter from "../DatesFilter";

const BudgetingTopActionsBar: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const tagsLinkClickHandler = () => {
    navigate(NavigationRoutes.Tags);
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={2}>
        <Button startIcon={<TagsIcon />} onClick={tagsLinkClickHandler}>
          {t("navigate-/tags")}
        </Button>
      </Stack>
      <DatesFilter dateTypeOnly />
    </Stack>
  );
};

export default BudgetingTopActionsBar;
