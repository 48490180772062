import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextInput from "../../misc/forms/TextInput";
import { useTranslation } from "react-i18next";
import useValidatedInputEffect from "../../../hooks/useValidatedInputEffect";
import ValidationResult, { isValidationResultArray } from "../../../services/validation/common/validation-result";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectWantsData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { getWants, planPurchaseWantItemById } from "../../../store/general/general_actions";
import Want from "../../../models/common/wants/want";
import {
  validatePlanPurchaseAmountDue,
  validatePlanPurchaseDateDue,
  validatePlanPurchaseDescription,
} from "../../../services/validation/general/want_validation";
import { DateTime } from "luxon";
import DateTimeInput from "../../misc/forms/DateTimeInput";
import { CashflowDirection, EntryType } from "../../../models/enums/entry/CashflowDirection";

const PlanPurchaseForm: React.FC<{
  want: Want;
  open: boolean;
  onClose: () => void;
}> = ({ want, open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const wantsData = useAppSelector((state) => selectWantsData(state));

  const [serverValidationErrors, setServerValidationErrors] = useState<ValidationResult[]>([]);

  useEffect(() => {
    if (isValidationResultArray(wantsData.rest.postError)) {
      setServerValidationErrors(wantsData.rest.postError);
    } else if (isValidationResultArray(wantsData.rest.patchError)) {
      setServerValidationErrors(wantsData.rest.patchError);
    }
  }, [wantsData.rest.patchError, wantsData.rest.postError]);

  useEffect(() => {
    if (wantsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getWants());
    }
  }, [wantsData.rest.getStatus, dispatch]);

  const removeServerError = (inputKey: string) => {
    setServerValidationErrors((prevState) =>
      prevState.filter((sv) => !(sv.inputKey !== undefined && sv.inputKey === inputKey))
    );
  };

  const getServerValidationResultOrNull = (inputKey: string) => {
    let serverValidation = serverValidationErrors.find((sv) => sv.inputKey === inputKey);
    return serverValidation !== undefined ? serverValidation : null;
  };

  const {
    value: description,
    validationResult: descriptionValidationResult,
    valueChangeHandler: descriptionChangeHandler,
  } = useValidatedInputEffect(
    `${want.title}${want.description != null ? " - " + want.description : ""}`,
    (newDescription) => validatePlanPurchaseDescription(newDescription),
    (_) => () => {},
    getServerValidationResultOrNull("description"),
    removeServerError
  );

  const {
    value: amountDue,
    validationResult: amountDueValidationResult,
    valueChangeHandler: amountDueChangeHandler,
  } = useValidatedInputEffect<number>(
    want.cost ?? 0,
    (newAmountDue) => validatePlanPurchaseAmountDue(newAmountDue),
    (_) => () => {},
    getServerValidationResultOrNull("amountDue"),
    removeServerError
  );

  const [dateDue, setDateDue] = useState<{ value: DateTime; validation: ValidationResult }>({
    value: DateTime.fromISO(DateTime.now().toString()).toLocal(),
    validation: getServerValidationResultOrNull("dateDue") ?? {
      ...validatePlanPurchaseDateDue(DateTime.fromISO(DateTime.now().toString())),
    },
  });

  const addExpenseClickHandler = () => {
    dispatch(
      planPurchaseWantItemById({
        id: want.id,
        entry: {
          description,
          amountDue,
          tags: want.tags,
          dateDue: dateDue.value.toJSDate(),
          cashflowDirection: CashflowDirection.EXPENSE,
          entryType: EntryType.PLANNED,
          creator: want.creator,
          currency: want.currency,
        },
      })
    );
  };

  const dateDueChangeHandler = (newDateDue: DateTime | null) => {
    if (newDateDue) {
      setDateDue({ value: newDateDue, validation: validatePlanPurchaseDateDue(newDateDue) });
      removeServerError("dateDue");
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogContent>
          <Box>
            <Stack spacing={2}>
              <Typography variant="h2">{t("title-plan-purchase")}</Typography>

              <TextInput
                label={t("input-label-description")}
                type="string"
                value={description}
                onChange={descriptionChangeHandler}
                validationResult={descriptionValidationResult}
              />

              <TextInput
                label={t("input-label-budgetEntryAmountDue")}
                type="number"
                value={amountDue}
                onChange={amountDueChangeHandler}
                validationResult={amountDueValidationResult}
              />

              <DateTimeInput
                datetime={dateDue.value}
                title={t("input-label-dateDue") as string}
                dateValidation={dateDue.validation}
                onDateTimeChange={dateDueChangeHandler}
              />

              <Button
                variant="contained"
                fullWidth
                disabled={wantsData.rest.planPurchasePostStatus === HttpFetchStatus.Loading}
                onClick={addExpenseClickHandler}
              >
                {wantsData.rest.planPurchasePostStatus === HttpFetchStatus.Loading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  t("button-add")
                )}
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default PlanPurchaseForm;
