import React from "react";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import {
  selectBudgetDateFilter,
  selectBudgetEntriesData,
  selectBudgetingEntries,
} from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { CashflowDirection, calculateValueForRepeat } from "../../../models/enums/entry/CashflowDirection";
import AmountDescriptionCard from "../../misc/AmountDescriptionCard";
import CustomSkeleton from "../../misc/CustomSkeleton";

const BudgetingOverviewCard: React.FC = () => {
  const { t } = useTranslation();

  const budgetingEntries = useAppSelector((state) => selectBudgetingEntries(state));
  const datesFilter = useAppSelector((state) => selectBudgetDateFilter(state));
  const {
    rest: { getStatus },
  } = useAppSelector((state) => selectBudgetEntriesData(state));

  if (getStatus === HttpFetchStatus.Idle || getStatus === HttpFetchStatus.Loading) {
    return <CustomSkeleton gridSize={{ xs: 12 }} height={12} />;
  }

  let netCredit = 0;
  if (budgetingEntries != null) {
    netCredit =
      budgetingEntries
        .filter(
          (entry) =>
            entry.cashflowDirection === CashflowDirection.INCOME &&
            entry.amountDue != null &&
            entry.dateArchived == null
        )
        .reduce(
          (acc, entry) =>
            acc + calculateValueForRepeat(entry.amountDue!, entry.repeat, datesFilter.type, entry.cashflowDirection),
          0
        ) -
      budgetingEntries
        .filter(
          (entry) =>
            entry.cashflowDirection === CashflowDirection.EXPENSE &&
            entry.amountDue != null &&
            entry.dateArchived == null
        )
        .reduce(
          (acc, entry) =>
            acc + calculateValueForRepeat(entry.amountDue!, entry.repeat, datesFilter.type, entry.cashflowDirection),
          0
        );
  }

  if (budgetingEntries == null) {
    return <Alert severity="error">{t("error-loading-budget-entries")}</Alert>;
  } else if (budgetingEntries.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <AmountDescriptionCard
        label={t("label-net-credit")}
        currency={t("currency-" + budgetingEntries[0].currency)}
        amount={netCredit.toFixed(2)}
        description={netCredit > 0 ? t("functioning-budget-message") : t("bad-budget-message")}
        amountColor={netCredit > 0 ? "green" : "error"}
      />
    </Grid>
  );
};

export default BudgetingOverviewCard;
