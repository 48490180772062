interface HttpError {
  code: string;
  message: string;
  value: string | null;
}

export const newHttpError = (code: string, message: string, value: string | null = null) => {
  return {
    code: code,
    message: message,
    value: value,
  } as HttpError;
};

export default HttpError;
