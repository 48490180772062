import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextInput from "../../misc/forms/TextInput";
import { useTranslation } from "react-i18next";
import useValidatedInputEffect from "../../../hooks/useValidatedInputEffect";
import ValidationResult, { isValidationResultArray } from "../../../services/validation/common/validation-result";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectTagsData, selectTasksData } from "../../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { createTask, deleteTaskById, getTags, getTasks, updateTaskById } from "../../../store/general/general_actions";
import Task from "../../../models/calendar/task";
import {
  validateTaskDeadline,
  validateTaskDescription,
  validateTaskTags,
  validateTaskTitle,
} from "../../../services/validation/general/task_validation";
import { dateToStringFormat } from "../../../utils/date";
import TextWithLabel from "../../misc/TextWithLabel";
import useValidatedMultipleSelectEffect from "../../../hooks/useValidatedMultipleSelectEffect";
import TagSelect from "../../misc/forms/TagSelect";
import TagGroup from "../../../models/enums/entry/TagGroup";
import { DateTime } from "luxon";
import DateTimeInput from "../../misc/forms/DateTimeInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SubTasks from "./SubTasks";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: theme.spacing(1.8),
    },
    "&::-webkit-scrollbar-track": {
      borderLeft: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[100],
    },
    "&::-webkit-scrollbar-thumb": {
      borderLeft: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const TaskForm: React.FC<{
  task: Partial<Task>;
  onClose: () => void;
}> = ({ task, onClose }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const tasksData = useAppSelector((state) => selectTasksData(state));
  const tagsData = useAppSelector((state) => selectTagsData(state));

  const [serverValidationErrors, setServerValidationErrors] = useState<ValidationResult[]>([]);
  const [isReopening, setIsReopening] = useState(false);
  const [isDeadlineSet, setIsDeadlineSet] = useState(task.deadline != null);

  useEffect(() => {
    if (isValidationResultArray(tasksData.rest.postError)) {
      setServerValidationErrors(tasksData.rest.postError);
    } else if (isValidationResultArray(tasksData.rest.patchError)) {
      setServerValidationErrors(tasksData.rest.patchError);
    }
  }, [tasksData.rest.patchError, tasksData.rest.postError]);

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [dispatch, tagsData.rest.getStatus]);

  useEffect(() => {
    if (tasksData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTasks());
    }
  }, [tasksData.rest.getStatus, dispatch]);

  const removeServerError = (inputKey: string) => {
    setServerValidationErrors((prevState) =>
      prevState.filter((sv) => !(sv.inputKey !== undefined && sv.inputKey === inputKey))
    );
  };

  const getServerValidationResultOrNull = (inputKey: string) => {
    let serverValidation = serverValidationErrors.find((sv) => sv.inputKey === inputKey);
    return serverValidation !== undefined ? serverValidation : null;
  };

  const {
    value: taskTitle,
    validationResult: taskTitleValidationResult,
    valueChangeHandler: taskTitleChangeHandler,
  } = useValidatedInputEffect<string>(
    task.title ?? "",
    (newTitle) => validateTaskTitle(newTitle),
    (_) => () => {},
    getServerValidationResultOrNull("title"),
    removeServerError
  );

  const {
    value: taskDescription,
    validationResult: taskDescriptionValidationResult,
    valueChangeHandler: taskDescriptionChangeHandler,
  } = useValidatedInputEffect<string>(
    task.description ?? "",
    (newDescription) => validateTaskDescription(newDescription),
    (_) => () => {},
    getServerValidationResultOrNull("description"),
    removeServerError
  );

  const {
    value: tags,
    validationResult: tagsValidationResult,
    valueChangeHandler: tagsChangeHandler,
  } = useValidatedMultipleSelectEffect(
    task?.tags != null ? task.tags.map((tag) => tag.id) : [],
    (tags) => validateTaskTags(tags),
    (_) => () => {},
    getServerValidationResultOrNull("tags"),
    removeServerError
  );

  const [deadline, setDeadline] = useState<{ value: DateTime; validation: ValidationResult }>({
    value:
      task?.deadline != null
        ? DateTime.fromJSDate(new Date(task.deadline))
        : DateTime.fromISO(DateTime.now().toString()).toLocal(),
    validation: getServerValidationResultOrNull("deadline") ?? {
      ...validateTaskDeadline(DateTime.fromISO(DateTime.now().toString())),
    },
  });

  const addTaskClickHandler = () => {
    dispatch(
      createTask({
        title: taskTitle,
        description: taskDescription.length === 0 ? null : taskDescription,
        tags: tagsData.data?.filter((t) => tags.some((tId) => tId === t.id)),
        deadline: isDeadlineSet ? deadline.value.toJSDate() : null,
      })
    );
  };

  const updateTaskClickHandler = () => {
    if (task.id != null) {
      dispatch(
        updateTaskById({
          task: {
            id: task.id,
            title: taskTitle,
            description: taskDescription,
            tags: tagsData.data?.filter((t) => tags.some((tId) => tId === t.id)),
            deadline: isDeadlineSet ? deadline.value.toJSDate() : null,
          },
        })
      );
    }
  };

  const deleteTaskClickHandler = () => {
    if (task.id != null) {
      dispatch(deleteTaskById({ deletedTaskId: task.id }));
    }
  };

  const closeFormClickHandler = () => {
    onClose();
  };

  const reopenTaskClickHandler = () => {
    if (task.id != null) {
      setIsReopening(true);
      dispatch(
        updateTaskById({
          task: {
            ...task,
            dateCompleted: null,
          },
          reopening: true,
        })
      );
    }
  };

  const toggleIsDeadlineSetHandler = () => {
    setIsDeadlineSet((prevState) => !prevState);
  };

  const deadlineChangeHandler = (newDeadline: DateTime | null) => {
    if (newDeadline) {
      setDeadline({ value: newDeadline, validation: validateTaskDeadline(newDeadline) });
      removeServerError("deadline");
    }
  };

  return (
    <React.Fragment>
      <Dialog open onClose={closeFormClickHandler} fullWidth maxWidth="sm">
        <DialogContent className={classes.scrollbar}>
          <Box>
            <Stack spacing={2}>
              <Typography variant="h2">{task.id == null ? t("title-add-task") : t("title-edit-task")}</Typography>

              <TextInput
                label={t("input-label-title")}
                type="string"
                value={taskTitle}
                onChange={taskTitleChangeHandler}
                validationResult={taskTitleValidationResult}
              />
              <TextInput
                label={t("input-label-description")}
                type="string"
                multiline
                value={taskDescription}
                onChange={taskDescriptionChangeHandler}
                validationResult={taskDescriptionValidationResult}
              />

              <TagSelect
                tagIds={tags}
                tagGroups={[TagGroup.Tasks]}
                tagsValidationResult={tagsValidationResult}
                onTagsChange={tagsChangeHandler}
              />
              {task.dateCreated != null && (
                <TextWithLabel text={dateToStringFormat(task.dateCreated)} label={t("input-label-task-date-created")} />
              )}

              {task.dateCompleted != null && (
                <Stack direction="row" justifyContent="space-between">
                  <TextWithLabel
                    text={dateToStringFormat(task.dateCompleted)}
                    label={t("input-label-task-date-completed")}
                  />
                  <Button onClick={reopenTaskClickHandler} size="small">
                    {tasksData.rest.patchStatus === HttpFetchStatus.Loading && isReopening ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      t("button-reopen-task")
                    )}
                  </Button>
                </Stack>
              )}
              <Stack spacing={1}>
                <FormControlLabel
                  control={<Switch checked={isDeadlineSet} onChange={toggleIsDeadlineSetHandler} />}
                  label={t("switch-task-deadline-set")}
                />
                {isDeadlineSet && (
                  <DateTimeInput
                    datetime={deadline.value}
                    title={t("input-label-task-deadline") as string}
                    dateValidation={deadline.validation}
                    onDateTimeChange={deadlineChangeHandler}
                  />
                )}
              </Stack>

              <SubTasks task={task} postStatus={tasksData.subTaskRest.postStatus} />

              <Button
                variant="contained"
                fullWidth
                disabled={
                  tasksData.rest.postStatus === HttpFetchStatus.Loading ||
                  tasksData.rest.patchStatus === HttpFetchStatus.Loading
                }
                onClick={task.id == null ? addTaskClickHandler : updateTaskClickHandler}
              >
                {(tasksData.rest.postStatus === HttpFetchStatus.Loading ||
                  tasksData.rest.patchStatus === HttpFetchStatus.Loading) &&
                !isReopening ? (
                  <CircularProgress size={24} color="secondary" />
                ) : task.id == null ? (
                  t("button-add")
                ) : (
                  t("button-save")
                )}
              </Button>
              {task.id != null && (
                <Button
                  variant="text"
                  fullWidth
                  size="small"
                  color="error"
                  onClick={deleteTaskClickHandler}
                  disabled={tasksData.rest.deleteStatus === HttpFetchStatus.Loading}
                >
                  {tasksData.rest.deleteStatus === HttpFetchStatus.Loading ? (
                    <CircularProgress size={24} color="error" />
                  ) : (
                    t("button-delete")
                  )}
                </Button>
              )}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default TaskForm;
