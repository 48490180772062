import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import KitchenRoundedIcon from "@mui/icons-material/KitchenRounded";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import SellIcon from "@mui/icons-material/Sell";
import Login from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import Email from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PaymentsIcon from "@mui/icons-material/Payments";
import StyleIcon from "@mui/icons-material/Style";
import Today from "@mui/icons-material/Today";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CalendarViewMonthRoundedIcon from "@mui/icons-material/CalendarViewMonthRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import Add from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import CloseDialog from "@mui/icons-material/Close";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import RemoveDoneRoundedIcon from "@mui/icons-material/RemoveDoneRounded";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";

export const HomeIcon = HomeRoundedIcon;
export const CalendarIcon = CalendarMonthRoundedIcon;
export const BudgetIcon = SavingsRoundedIcon;
export const PantryIcon = KitchenRoundedIcon;
export const GoalsIcon = WorkspacePremiumRoundedIcon;
export const SettingsIcon = SettingsRoundedIcon;

export const OpenIcon = ChevronRightRoundedIcon;
export const CloseIcon = ChevronLeftRoundedIcon;
export const CloseDialogIcon = CloseDialog;

export const SearchIcon = SearchRoundedIcon;
export const NotificationIcon = NotificationsNoneRoundedIcon;

export const UserIcon = AccountCircleIcon;
export const TagIcon = SellIcon;
export const UsernameIcon = BadgeIcon;
export const EmailIcon = Email;
export const PasswordIcon = LockIcon;
export const ShowFieldIcon = VisibilityIcon;
export const HideFieldIcon = VisibilityOffIcon;

export const LoginIcon = Login;
export const LogoutIcon = Logout;

export const TransactionsIcon = PaymentsIcon;
export const TagsIcon = StyleIcon;
export const TodayIcon = Today;
export const WeekIcon = CalendarViewWeekIcon;
export const MonthIcon = CalendarViewMonthRoundedIcon;
export const YearIcon = CalendarMonthRoundedIcon;

export const RightArrow = ArrowRightRoundedIcon;
export const LeftArrow = ArrowLeftRoundedIcon;
export const AddIcon = Add;
export const MinusIcon = RemoveRoundedIcon;
export const CompleteTaskIcon = CheckRoundedIcon;
export const OpenTasksIcon = FormatListBulletedIcon;
export const CompletedTasksIcon = ChecklistRoundedIcon;
export const OpenTaskIcon = RemoveDoneRoundedIcon;

export const FilterIcon = FilterListRoundedIcon;
export const SaveIcon = SaveRoundedIcon;
export const DeleteIcon = DeleteOutlineRoundedIcon;
export const EditIcon = EditRoundedIcon;
export const InfoIcon = InfoRoundedIcon;
export const AddToCardIcon = AddShoppingCartRoundedIcon;