import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { AddIcon } from "../UI/icons";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectTagsData } from "../../store/general/general_slice_selectors";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import { getTags } from "../../store/general/general_actions";
import TagCard from "./TagCard";
import { setEditedTag } from "../../store/general/general_slice";
import TagGroup from "../../models/enums/entry/TagGroup";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { makeStyles } from "tss-react/mui";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  groupFilters: {
    maxWidth: "100%",
    overflow: "auto",
    marginBottom: theme.spacing(2),
    "&::-webkit-scrollbar": {
      height: theme.spacing(1.8),
    },
    "&::-webkit-scrollbar-track": {
      borderTop: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[100],
    },
    "&::-webkit-scrollbar-thumb": {
      borderTop: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

export const TAGS_GROUPS_QUERY_STRING = "groups";
export const TAGS_GROUPS_QUERY_SPLIT_STRING = "-";

const TagsPage: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [filterParams, setFilterParams] = useSearchParams();
  const paramGroups = filterParams.get(TAGS_GROUPS_QUERY_STRING);

  const dispatch = useAppDispatch();

  const tagsData = useAppSelector((state) => selectTagsData(state));

  const [filteredGroups, setFilteredGroups] = useState<TagGroup[]>(
    paramGroups != null
      ? paramGroups
          .split(TAGS_GROUPS_QUERY_SPLIT_STRING)
          .filter((g) => g.length > 0)
          .map((g) => g as TagGroup)
      : []
  );

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [dispatch, tagsData.rest.getStatus]);

  useEffect(() => {
    if (
      tagsData.rest.postStatus === HttpFetchStatus.Success ||
      tagsData.rest.deleteStatus === HttpFetchStatus.Success ||
      tagsData.rest.patchStatus === HttpFetchStatus.Success
    ) {
      dispatch(setEditedTag(null));
    }
  }, [tagsData.rest.deleteStatus, tagsData.rest.patchStatus, tagsData.rest.postStatus, dispatch]);

  const addTagClickHandler = () => {
    dispatch(setEditedTag({ tagGroups: filteredGroups }));
  };

  const groupChipClickHandler = (group: TagGroup) => {
    setFilteredGroups((prevState) => {
      const groups = prevState.some((g) => g === group) ? prevState.filter((g) => g !== group) : [...prevState, group];
      setFilterParams(`${TAGS_GROUPS_QUERY_STRING}=${groups.join(TAGS_GROUPS_QUERY_SPLIT_STRING)}`);
      return groups;
    });
  };

  return (
    <React.Fragment>
      <Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h1">{t("title-tags")}</Typography>
          <Button startIcon={<AddIcon />} onClick={addTagClickHandler}>
            {t("button-add-new")}
          </Button>
        </Stack>

        <Box className={classes.groupFilters}>
          <Stack direction="row" spacing={2} alignItems="center">
            {Object.values(TagGroup).map((group) => (
              <Chip
                key={group}
                label={t("tag-group-" + group)}
                onClick={() => groupChipClickHandler(group)}
                sx={(theme) => ({
                  backgroundColor: filteredGroups.some((g) => g === group) ? theme.palette.primary.main : undefined,
                  color: filteredGroups.some((g) => g === group) ? theme.palette.secondary.main : undefined,
                })}
              />
            ))}
          </Stack>
        </Box>
        <Grid container spacing={2}>
          {(tagsData.data ?? []).length === 0 && (
            <Grid item xs={12}>
              <Alert
                severity="info"
                sx={{ alignItems: "center" }}
                action={
                  <Button variant="outlined" sx={{ m: 1 }} startIcon={<AddIcon />} onClick={addTagClickHandler}>
                    {t("button-add-new")}
                  </Button>
                }
              >
                {t("info-no-tags")}
              </Alert>
            </Grid>
          )}
          {tagsData.data
            ?.filter(
              (tag) => filteredGroups.length === 0 || filteredGroups.some((g) => tag.tagGroups.some((tg) => tg === g))
            )
            .map((tag) => (
              <Grid key={tag.id} item lg={6} xs={12} xl={4}>
                <TagCard tag={tag} />
              </Grid>
            ))}
        </Grid>
      </Stack>
    </React.Fragment>
  );
};

export default TagsPage;
