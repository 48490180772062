export const parseEnumFromString = <T extends Record<string, string | number>>(
  enumObj: T,
  value: string | null
): T[keyof T] => {
  return Math.max(
    Object.keys(enumObj)
      .filter((key) => isNaN(Number(key)))
      .map((key) => key.toString().toLowerCase())
      .findIndex((key) => key === value),
    0
  ) as unknown as T[keyof T];
};

export const parseStringFromEnum = <T extends Record<string, string | number>>(
  enumObj: T,
  enumValue: T[keyof T]
): string => {
  return enumObj[enumValue].toString().toLowerCase();
};
