import { CashflowDirection, EntryType, RepeatEntry } from "../enums/entry/CashflowDirection";
import CurrencyChoice from "../enums/entry/CurrencyChoice";
import Tag from "../general/tag";

interface BudgetEntry {
  id: string;
  amountDue: number | null;
  amountPaid: number | null;
  cashflowDirection: CashflowDirection;
  creator: string;
  currency: CurrencyChoice;
  dateCreated: Date;
  datePaid: Date | null;
  dateDue: Date | null;
  entryType: EntryType;
  notify: number | null;
  budgetingEntry: string | null;
  repeat: RepeatEntry;
  description: string;
  tags: Tag[];
  dateArchived: Date | null;
}

export default BudgetEntry;

export const convertBudgetEntryApiResponse = (entries: BudgetEntry[]): BudgetEntry[] => {
  // converter required as Decimal values sent as strings to keep decimal accuracy
  return entries.map((e) => ({
    ...e,
    amountDue: e.amountDue != null ? parseFloat(e.amountDue.toString()) : null,
    amountPaid: e.amountPaid != null ? parseFloat(e.amountPaid.toString()) : null,
    isTemporary: false,
  }));
};

