import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import getValueIntensityColor from "../../../utils/colorHeatMap";

const TagBarChart: React.FC<{ color: string; label: string; value: number; maxValue: number }> = ({
  color,
  label,
  value,
  maxValue,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const roundedValue = Math.max(0, value);
  const roundedMaxValue = Math.max(0, maxValue);

  return (
    <React.Fragment>
      <Card sx={(theme) => ({ minWidth: theme.spacing(12) })}>
        <CardContent>
          <Stack alignItems="center" spacing={3}>
            <Box
              sx={(theme) => ({
                position: "relative",
                height: theme.spacing(16),
                width: theme.spacing(4),
                backgroundColor: theme.palette.grey[300],
                mb: 2,
                borderTopLeftRadius: theme.spacing(2),
                borderTopRightRadius: theme.spacing(2),
              })}
            >
              <Box
                sx={(theme) => ({
                  position: "absolute",
                  transition: "height 1s ease-in-out",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  borderTopLeftRadius: theme.spacing(2),
                  borderTopRightRadius: theme.spacing(2),
                  backgroundColor: getValueIntensityColor(roundedValue, roundedMaxValue),
                  height: isLoaded
                    ? `${
                        (roundedValue === 0
                          ? 0
                          : roundedMaxValue === 0
                          ? 1
                          : Math.min(roundedMaxValue, roundedValue) / roundedMaxValue) * parseInt(theme.spacing(16), 10)
                      }px`
                    : 0,
                })}
              />
              <Box
                sx={(theme) => ({
                  position: "absolute",
                  width: theme.spacing(4),
                  height: theme.spacing(4),
                  bottom: `-${theme.spacing(2)}`,
                  left: 0,
                  borderRadius: theme.spacing(2),
                  backgroundColor: color,
                  border: "2px solid",
                  borderColor: theme.palette.background.default,
                })}
              />
            </Box>
            <Box textAlign="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h2">{value.toFixed(0)}</Typography>
                <Typography variant="body2"> / {maxValue.toFixed(0)}</Typography>
              </Stack>
              <Typography variant="caption">{label}</Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default TagBarChart;
