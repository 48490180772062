import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import Task from "../../../models/calendar/task";
import { CompleteTaskIcon, DeleteIcon, InfoIcon, OpenTaskIcon, SaveIcon } from "../../UI/icons";
import TextInput from "../../misc/forms/TextInput";
import { useAppDispatch } from "../../../store";
import { deleteTaskById, updateSubTaskByTaskId } from "../../../store/general/general_actions";
import HttpFetchStatus from "../../../models/common/api/http-fetch-status";
import { makeStyles } from "tss-react/mui";
import TextWithLabel from "../../misc/TextWithLabel";
import { dateToStringFormat } from "../../../utils/date";

const useStyles = makeStyles()((theme) => ({
  subTask: {
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
  },
  hovering: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const SubTasks: React.FC<{ task: Partial<Task>; postStatus: HttpFetchStatus }> = ({ task, postStatus }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const dispatch = useAppDispatch();

  const [subTaskTitle, setSubTaskTitle] = useState("");
  const [subTaskHoveredId, setSubTaskHoveredId] = useState<string | null>(null);

  useEffect(() => {
    if (postStatus === HttpFetchStatus.Success) {
      setSubTaskTitle("");
    }
  }, [postStatus]);

  const createSubTaskClickHandler = () => {
    if (task.id != null) {
      dispatch(
        updateSubTaskByTaskId({
          taskId: task.id,
          subTask: {
            id: uuidv4(),
            title: subTaskTitle,
          },
        })
      );
    }
  };

  const subTaskTitleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubTaskTitle(event.target.value);
  };

  const toggleSubTaskDateCompletedHandler = (subTask: Task) => {
    if (task.id != null) {
      dispatch(
        updateSubTaskByTaskId({
          taskId: task.id,
          subTask: {
            ...subTask,
            dateCompleted: subTask.dateCompleted == null ? new Date() : null,
          },
        })
      );
    }
  };

  const deleteSubTaskClickHandler = (subTaskId: string) => {
    dispatch(deleteTaskById({ deletedTaskId: subTaskId, parentTaskId: task.id }));
  };

  const subTaskHoverStartHandler = (id: string) => {
    setSubTaskHoveredId(id);
  };

  const subTaskHoverEndHandler = () => {
    setSubTaskHoveredId(null);
  };

  return (
    <Stack>
      <InputLabel shrink>{t("input-label-task-subtasks")}</InputLabel>
      {task.id == null ? (
        <Typography>{t("info-task-must-be-saved-for-subtasks")}</Typography>
      ) : (
        <React.Fragment>
          {(task.subTasks ?? []).map((subTask) => (
            <Stack key={subTask.id} alignItems="center" direction="row" spacing={1}>
              <IconButton
                size="small"
                color={subTask.dateCompleted != null ? "default" : "success"}
                onClick={() => toggleSubTaskDateCompletedHandler(subTask)}
              >
                {subTask.dateCompleted != null ? <OpenTaskIcon /> : <CompleteTaskIcon />}
              </IconButton>

              <Stack
                className={cx(classes.subTask, { [classes.hovering]: subTaskHoveredId === subTask.id })}
                direction="row"
                onMouseEnter={() => subTaskHoverStartHandler(subTask.id)}
                onMouseLeave={subTaskHoverEndHandler}
                onTouchStart={() => subTaskHoverStartHandler(subTask.id)}
              >
                <Typography
                  variant="body2"
                  sx={{ textDecoration: subTask.dateCompleted != null ? "line-through" : undefined }}
                >
                  {subTask.title}
                </Typography>
                {subTaskHoveredId === subTask.id && (
                  <Stack direction="row" alignItems="center">
                    <IconButton size="small" onClick={() => deleteSubTaskClickHandler(subTask.id)}>
                      <DeleteIcon />
                    </IconButton>
                    <Tooltip
                      placement="right"
                      title={
                        <React.Fragment>
                          <TextWithLabel
                            text={dateToStringFormat(subTask.dateCreated)}
                            label={t("input-label-task-date-created")}
                          />
                          {subTask.dateCompleted != null && (
                            <TextWithLabel
                              text={dateToStringFormat(subTask.dateCompleted)}
                              label={t("input-label-task-date-completed")}
                            />
                          )}
                        </React.Fragment>
                      }
                    >
                      <Box sx={{ cursor: "pointer", textAlign: "center", display: "flex", p: 0.5 }}>
                        <InfoIcon color="action" />
                      </Box>
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
            </Stack>
          ))}
          <Box mt={1}>
            <TextInput
              label={t("input-label-task-add-subtask")}
              size="small"
              type="string"
              value={subTaskTitle}
              onChange={subTaskTitleChangeHandler}
              endIcon={<SaveIcon />}
              onEndIconClick={createSubTaskClickHandler}
            />
          </Box>
        </React.Fragment>
      )}
    </Stack>
  );
};

export default SubTasks;
