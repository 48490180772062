import React from "react";
import { makeStyles } from "tss-react/mui";
import Stack from "@mui/material/Stack";
import { Theme, useMediaQuery } from "@mui/material";
import FormDialogs from "./FormDialogs";

const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    height: "100%",
    width: "100%",
    maxHeight: "100%",
    overflow: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: theme.spacing(1.8),
    },
    "&::-webkit-scrollbar-track": {
      borderLeft: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[100],
    },
    "&::-webkit-scrollbar-thumb": {
      borderLeft: `${theme.spacing(1)} solid rgba(0, 0, 0, 0)`,
      backgroundClip: "padding-box",
      backgroundColor: theme.palette.grey[300],
    },
  },
  noScrollbar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const CentralContentBlock: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { classes, cx } = useStyles();
  const matchesMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <React.Fragment>
      <Stack
        className={cx(classes.contentContainer, {
          [classes.scrollbar]: matchesMdUp,
          [classes.noScrollbar]: !matchesMdUp,
        })}
        sx={(theme) => ({
          paddingTop: theme.spacing(matchesMdUp ? 4 : 2),
        })}
      >
        {children}
      </Stack>
      <FormDialogs />
    </React.Fragment>
  );
};

export default CentralContentBlock;
