import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Tag, { TagColor } from "../../models/general/tag";
import useValidatedInputEffect from "../../hooks/useValidatedInputEffect";
import {
  validateTagDescription,
  validateTagName,
  validateTagTagGroups,
} from "../../services/validation/general/tag_validations";
import ValidationResult, { isValidationResultArray } from "../../services/validation/common/validation-result";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectTagsData } from "../../store/general/general_slice_selectors";
import { useTranslation } from "react-i18next";
import TextInput from "../misc/forms/TextInput";
import useValidatedSelectEffect from "../../hooks/useValidatedSelectEffect";
import SelectInput from "../misc/forms/SelectInput";
import HttpFetchStatus from "../../models/common/api/http-fetch-status";
import { createTag, deleteTagById, getTags, updateTagById } from "../../store/general/general_actions";
import ConfirmAbortDialog from "../misc/ConfirmAbortDialog";
import { CloseDialogIcon } from "../UI/icons";
import { getEnumNameByValue } from "../../utils/enumHelpers";
import MultipleSelectInput from "../misc/forms/MultipleSelectForm";
import useValidatedMultipleSelectEffect from "../../hooks/useValidatedMultipleSelectEffect";
import TagGroup from "../../models/enums/entry/TagGroup";

const TagForm: React.FC<{ tag?: Partial<Tag>; onClose: () => void }> = ({ tag, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const tagsData = useAppSelector((state) => selectTagsData(state));

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [serverValidationErrors, setServerValidationErrors] = useState<ValidationResult[]>([]);

  useEffect(() => {
    if (tagsData.rest.getStatus === HttpFetchStatus.Idle) {
      dispatch(getTags());
    }
  }, [dispatch, tagsData.rest.getStatus]);

  useEffect(() => {
    if (isValidationResultArray(tagsData.rest.postError)) {
      setServerValidationErrors(tagsData.rest.postError);
    } else if (isValidationResultArray(tagsData.rest.patchError)) {
      setServerValidationErrors(tagsData.rest.patchError);
    }
  }, [tagsData.rest.patchError, tagsData.rest.postError]);

  const removeServerError = (inputKey: string) => {
    setServerValidationErrors((prevState) =>
      prevState.filter((sv) => !(sv.inputKey !== undefined && sv.inputKey === inputKey))
    );
  };

  const getServerValidationResultOrNull = (inputKey: string) => {
    let serverValidation = serverValidationErrors.find((sv) => sv.inputKey === inputKey);
    return serverValidation !== undefined ? serverValidation : null;
  };

  const {
    value: tagName,
    validationResult: tagNameValidationResult,
    valueChangeHandler: tagNameChangeHandler,
  } = useValidatedInputEffect(
    tag?.name ?? "",
    (newName) => validateTagName(newName),
    (_) => () => {},
    getServerValidationResultOrNull("name"),
    removeServerError
  );

  const {
    value: tagDescription,
    validationResult: tagDescriptionValidationResult,
    valueChangeHandler: tagDescriptionChangeHandler,
  } = useValidatedInputEffect(
    tag?.description ?? "",
    (newDescription) => validateTagDescription(newDescription),
    (_) => () => {},
    getServerValidationResultOrNull("description"),
    removeServerError
  );

  const {
    value: tagColor,
    validationResult: tagColorValidationResult,
    valueChangeHandler: tagColorChangeHandler,
  } = useValidatedSelectEffect(
    tag?.color ?? TagColor.SKY_BLUE,
    (newColor) => ({ isValid: true, error: t("form-help-text-tag-color") }),
    (_) => () => {},
    getServerValidationResultOrNull("color"),
    removeServerError
  );

  const {
    value: tagGroups,
    validationResult: tagGroupsValidationResult,
    valueChangeHandler: tagGroupsChangeHandler,
  } = useValidatedMultipleSelectEffect(
    tag?.tagGroups != null ? tag.tagGroups : [],
    (tagGroups) => validateTagTagGroups(tagGroups),
    (_) => () => {},
    getServerValidationResultOrNull("tagGroups"),
    removeServerError
  );

  const addTagClickHandler = () => {
    dispatch(
      createTag({
        name: tagName,
        description: tagDescription,
        color: tagColor as TagColor,
        tagGroups,
      })
    );
  };

  const updateTagClickHandler = () => {
    if (tag?.id != null) {
      dispatch(
        updateTagById({
          id: tag.id,
          name: tagName,
          description: tagDescription,
          color: tagColor as TagColor,
          tagGroups,
        })
      );
    }
  };

  const deleteTagClickHandler = () => {
    setIsConfirmDeleteOpen(true);
  };

  const dispatchDeleteTag = () => {
    if (tag?.id != null) {
      dispatch(deleteTagById(tag.id));
    }
    closeConfirmDeleteDialog();
  };

  const closeConfirmDeleteDialog = () => {
    setIsConfirmDeleteOpen(false);
  };

  const closeFormClickHandler = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog open fullWidth onClose={onClose}>
        <DialogContent>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h2">{tag?.id == null ? t("title-add-tag") : t("title-edit-tag")}</Typography>
              <IconButton color="error" onClick={closeFormClickHandler}>
                <CloseDialogIcon />
              </IconButton>
            </Stack>
            <TextInput
              label={t("input-label-tag-name")}
              type="string"
              value={tagName}
              onChange={tagNameChangeHandler}
              validationResult={tagNameValidationResult}
            />
            <TextInput
              label={t("input-label-tag-description")}
              type="string"
              value={tagDescription}
              onChange={tagDescriptionChangeHandler}
              validationResult={tagDescriptionValidationResult}
            />
            <SelectInput
              label={t("input-label-tag-color")}
              value={tagColor}
              onChange={tagColorChangeHandler}
              validationResult={tagColorValidationResult}
              renderValue={(value: string) => (
                <span>
                  <span
                    style={{
                      width: theme.spacing(2),
                      height: theme.spacing(2),
                      display: "inline-block",
                      borderRadius: "50%",
                      backgroundColor: tagColor,
                      marginRight: theme.spacing(1),
                    }}
                  />
                  {t("tag-color-" + getEnumNameByValue(TagColor, value as TagColor))}
                </span>
              )}
            >
              {Object.values(TagColor).map((tc) => (
                <MenuItem key={tc} value={tc}>
                  <span
                    style={{
                      width: theme.spacing(2),
                      height: theme.spacing(2),
                      borderRadius: "50%",
                      backgroundColor: tc,
                      marginRight: theme.spacing(2),
                    }}
                  />
                  {t("tag-color-" + getEnumNameByValue(TagColor, tc as TagColor))}
                </MenuItem>
              ))}
            </SelectInput>
            <MultipleSelectInput
              label={t("input-label-tag-groups")}
              values={tagGroups.map((tg) => ({ id: tg, title: t("tag-group-" + tg) }))}
              onChange={tagGroupsChangeHandler}
              validationResult={tagGroupsValidationResult}
            >
              {Object.values(TagGroup).map((tg) => (
                <MenuItem key={tg} value={tg}>
                  {t("tag-group-" + tg)}
                </MenuItem>
              ))}
            </MultipleSelectInput>
            <Button
              variant="contained"
              fullWidth
              onClick={tag?.id == null ? addTagClickHandler : updateTagClickHandler}
            >
              {tag?.id == null ? t("button-add") : t("button-save")}
            </Button>
            {tag?.id != null && (
              <Button variant="text" fullWidth size="small" color="error" onClick={deleteTagClickHandler}>
                {t("button-delete")}
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      <ConfirmAbortDialog
        isOpen={isConfirmDeleteOpen}
        title={t("title-confirm-delete-tag")}
        description={t("info-confirm-delete-tag")}
        abortButtonText={t("button-cancel")}
        confirmButtonText={t("button-confirm")}
        onConfirm={dispatchDeleteTag}
        onAbort={closeConfirmDeleteDialog}
      />
    </React.Fragment>
  );
};

export default TagForm;
