import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const CustomSnackbar: React.FC<{
  onClose: () => void;
  open: boolean;
  title: string;
  severity?: "success" | "info" | "error" | "warning";
}> = ({ onClose, open, title, severity = "success" }) => {
  return (
    <Snackbar onClose={onClose} open={open} autoHideDuration={5000}>
      <Alert severity={severity}>{title}</Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
