import React from "react";
import BudgetEntry from "../../../models/budget/budget-entry";
import { CashflowDirection, RepeatEntry } from "../../../models/enums/entry/CashflowDirection";
import { LineChart } from "@mui/x-charts";
import { DateTime, DateTimeUnit } from "luxon";
import { dateToStringFormat, sortByDate } from "../../../utils/date";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { TagColor } from "../../../models/general/tag";

interface ChartEntry {
  amountPaid: number | null;
  amountDue: number | null;
  date: Date;
  totalSumPaid: number | null;
  totalSumDue: number | null;
}

const computeSumPaidForPeriod = (entries: BudgetEntry[], startPeriod: DateTime, endPeriod: DateTime): number => {
  return entries
    .filter((entry) => {
      const entryDate = entry.datePaid ? DateTime.fromJSDate(new Date(entry.datePaid)) : null;
      return entryDate && entryDate >= startPeriod && entryDate <= endPeriod;
    })
    .reduce(
      (acc, entry) =>
        acc +
        (entry.cashflowDirection === CashflowDirection.EXPENSE ? entry.amountPaid ?? 0 : -(entry.amountPaid ?? 0)),
      0
    );
};

const computeSumDueForPeriod = (entries: BudgetEntry[], startPeriod: DateTime, endPeriod: DateTime): number => {
  return entries
    .filter((entry) => {
      const entryDate = entry.dateDue ? DateTime.fromJSDate(new Date(entry.dateDue)) : null;
      return entryDate && entryDate >= startPeriod && entryDate <= endPeriod;
    })
    .reduce(
      (acc, entry) =>
        acc + (entry.cashflowDirection === CashflowDirection.EXPENSE ? entry.amountDue ?? 0 : -(entry.amountDue ?? 0)),
      0
    );
};

const processData = (entries: BudgetEntry[], repeat: RepeatEntry): ChartEntry[] => {
  const seriesData: ChartEntry[] = [];
  const pointsDataPaid: ChartEntry[] = [];
  const pointsDataDue: ChartEntry[] = [];

  if (entries.length === 0) {
    return [];
  }

  const startDate = DateTime.fromJSDate(new Date(entries[0].dateDue ?? entries[0].datePaid ?? new Date()));
  const endDate = DateTime.fromJSDate(
    new Date(entries[entries.length - 1].dateDue ?? entries[entries.length - 1].datePaid ?? new Date())
  );

  let timePeriod: DateTimeUnit = "year";
  switch (repeat) {
    case RepeatEntry.DAILY:
      timePeriod = "day";
      break;
    case RepeatEntry.FORTNIGHTLY:
    case RepeatEntry.WEEKLY:
      timePeriod = "week";
      break;
    case RepeatEntry.MONTHLY:
      timePeriod = "month";
      break;
    case RepeatEntry.YEARLY:
    case RepeatEntry.NEVER:
      timePeriod = "year";
      break;
  }

  let date = startDate.startOf(timePeriod);
  while (date <= endDate) {
    const periodEnd = date.endOf(timePeriod);
    const sumPaid = computeSumPaidForPeriod(entries, date, periodEnd);
    const sumDue = computeSumDueForPeriod(entries, date, periodEnd);
    seriesData.push({
      date: new Date(periodEnd.toJSDate()),
      totalSumPaid: sumPaid,
      totalSumDue: sumDue,
      amountDue: null,
      amountPaid: null,
    });

    date = date.plus({ [timePeriod]: 1 });
  }

  entries
    .filter((e) => e.datePaid != null && e.amountPaid != null)
    .sort((a, b) => sortByDate(a.datePaid!, b.datePaid!))
    .forEach((entry) => {
      if (entry.datePaid && entry.amountPaid != null) {
        pointsDataPaid.push({
          date: new Date(entry.datePaid),
          totalSumPaid: null,
          totalSumDue: null,
          amountPaid: entry.cashflowDirection === CashflowDirection.EXPENSE ? entry.amountPaid : -entry.amountPaid,
          amountDue: null,
        });
      }
    });

  entries
    .filter((e) => e.dateDue != null && e.amountDue != null)
    .sort((a, b) => sortByDate(a.dateDue!, b.dateDue!))
    .forEach((entry) => {
      if (entry.dateDue && entry.amountDue != null) {
        pointsDataDue.push({
          date: new Date(entry.dateDue),
          totalSumPaid: null,
          totalSumDue: null,
          amountPaid: null,
          amountDue: entry.cashflowDirection === CashflowDirection.EXPENSE ? entry.amountDue : -entry.amountDue,
        });
      }
    });

  return [...seriesData, ...pointsDataPaid, ...pointsDataDue];
};

const CustomLineChartCard: React.FC<{
  entries: BudgetEntry[];
  repeat: RepeatEntry;
  expectedValue: number | null;
  label: string;
}> = ({ entries, repeat, expectedValue, label }) => {
  const { t } = useTranslation();

  const plotData = processData(entries, repeat);
  const seriesData = [
    {
      dataKey: "totalSumPaid",
      label: t("label-total-spent") as string,
      color: TagColor.NAVY_BLUE,
      connectNulls: true,
      showMark: false,
    },
    {
      dataKey: "totalSumDue",
      label: t("label-total-planned") as string,
      color: TagColor.PEACH,
      connectNulls: true,
      showMark: false,
    },
    {
      dataKey: "amountDue",
      label: t("label-budgeted-entry") as string,
      color: TagColor.SAND,
      connectNulls: true,
      showMark: false,
    },
    {
      dataKey: "amountPaid",
      label: t("label-spent-entry") as string,
      color: TagColor.SKY_BLUE,
      connectNulls: true,
      showMark: false,
    },
  ];
  if (expectedValue != null) {
    seriesData.unshift({
      dataKey: "expectedValue",
      label: t("label-total-budgeted") as string,
      color: TagColor.LIGHT_GRAY,
      connectNulls: true,
      showMark: false,
    });
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">{label}</Typography>
        <LineChart
          xAxis={[
            {
              dataKey: "date",
              label: t("label-date") as string,
              valueFormatter: (value) => dateToStringFormat(DateTime.fromMillis(value), true),
              hideTooltip: true,
              tickInterval: (value, index) => {
                return (
                  DateTime.fromJSDate(new Date(value)).toMillis() ===
                  DateTime.fromJSDate(new Date(value)).endOf("month").toMillis()
                );
              },
            },
          ]}
          series={seriesData}
          dataset={plotData.map((e) => ({ ...e, expectedValue }))}
          tooltip={{ slots: {} }}
          height={300}
          margin={{ top: 8 }}
          slotProps={{
            legend: { position: { vertical: "middle", horizontal: "right" }, direction: "column" },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default CustomLineChartCard;
